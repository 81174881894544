import React from "react";
import styles from "./styles.css";

export default ({ title, description }) => (
	<div className={`${styles.wrapper} uk-width-1-1 uk-container`}>
		<div className={`${styles.container} uk-margin-large-bottom uk-overflow-auto`}>
			<h2>{title}</h2>
			<hr />
			{description.map((line, index) => (
				<p key={`InvalidSelection_${index}`}>{line}</p>
			))}
		</div>
	</div>
);
