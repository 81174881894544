const copyToClipboard = (targetElement) => {
  const { body } = document;
  let range;
  let sel;
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    sel.removeAllRanges();
    try {
      range.selectNodeContents(targetElement);
      sel.addRange(range);
    } catch (e) {
      range.selectNode(targetElement);
      sel.addRange(range);
    }
  } else if (body.createTextRange) {
    range = body.createTextRange();
    range.moveToElementText(targetElement);
    range.select();
  }

  document.execCommand('copy');
};

export default (activeView) => {
  switch (activeView) {
    case 'table':
      copyToClipboard(document.getElementById('tbl_comparison'));
      break;
    default:
      break;
  }
};
