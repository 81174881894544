import React, { useState } from "react";
import styles from "./styles.css";
import { default as LoadingSpinner } from "../LoadingSpinner/index.jsx";
import {useTranslation} from "react-i18next";

export const isAllChildrensSelected = (childrens, selectedSubjects) => {
	const isAllSubjectSelected = childrens.every((child) => {
		if (!selectedSubjects.includes(child)) {
			return false;
		}
		return true;
	});

	return isAllSubjectSelected;
};

export default ({
	description,
	buttons,
	countryList,
	groupList,
	selectedLocations,
	onLocationSelect,
	onAllGroupsSelect,
	onAllCountriesSelect,
	isSimplifiedView,
	show,
}) => {
	const { t, i18n } = useTranslation();
	if (isSimplifiedView) {
		return (
			<div className="info-menu-content country-info-menu app-menu">
				<div className="uk-container icon-container"></div>
				<div className="app-menu-inner">
					<p>{description}</p>
					<ul className="country-info-menu-list">
						{countryList.map((country, index) => (
							<li key={`${country.fullName.split(" ").join("")}_${index}`}>
								<a className={styles.locationItem} href={`/locations/${country.iso.toLowerCase()}.html`}>
									<img className="menu-flag" src={`/img/roundflags/${country.iso.toLowerCase()}.svg`}
										 alt={country.fullName}/>
									{country.fullName}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
	const listOfAllCountries = countryList.map((country) => country.iso);
	const listOfAllGroups = groupList.map((group) => group.uid);

	const [isAllCountriesSelected, selectAllCountries] = useState(
		isAllChildrensSelected(listOfAllCountries, selectedLocations)
	);
	const [isAllGroupsSelected, selectAllGroups] = useState(isAllChildrensSelected(listOfAllGroups, selectedLocations));

	return (
		<div className={`app-menu country-menu ${styles.locationPickerWrapper} ${!show ? "uk-hidden" : ""}`}>
			<div className="uk-container icon-container"></div>
			{countryList.length === 0 ? (
				<LoadingSpinner />
			) : (
				<>
					<p>{description}</p>
					<div className="app-menu-inner">
						<div className="app-menu-showclear country-showclear">
							{buttons.map((button, index) => (
								<a
									key={`${button.label.split(" ").join("")}_${index}`}
									onClick={(e) => {
										e.preventDefault();
										button.onClick();
										if (button.class === "clear-comparison") {
											selectAllCountries(false);
											selectAllGroups(false);
										}
									}}
									href="#"
									className={`uk-button uk-button-default ${button.class} ${styles.menuControlButton}`}
								>
									{button.label}
								</a>
							))}
						</div>
						<div className="uk-grid-divider uk-grid">
							<div className="uk-width-3-5@m uk-first-column">
								<ul className="app-menu-list country-menu-countries">
									<li
										className={
											isAllChildrensSelected(listOfAllCountries, selectedLocations)
												? styles.selected
												: ""
										}
										onClick={(e) => {
											e.preventDefault();
											onAllCountriesSelect(
												isAllChildrensSelected(listOfAllCountries, selectedLocations)
											);
											selectAllCountries(!isAllCountriesSelected);
										}}
									>
										<a href="#">{t("All Countries").toUpperCase()}</a>
									</li>
									{countryList.map((country, index) => (
										<li
											onClick={(e) => {
												e.preventDefault();
												let locationsToSet;
												if (selectedLocations.includes(country.iso)) {
													locationsToSet = selectedLocations.filter(
														(location) => location !== country.iso
													);
												} else {
													selectedLocations.push(country.iso);
													locationsToSet = selectedLocations;
												}
												onLocationSelect(locationsToSet.join("-"));
											}}
											key={`${country.fullName.split(" ").join("")}_${index}`}
										>
											<a
												onClick={(e) => e.preventDefault()}
												className={
													selectedLocations.includes(country.iso)
														? styles.selected
														: styles.notSelected
												}
												href="#"
											>
												{country.fullName}
											</a>
										</li>
									))}
								</ul>
							</div>
							<div className={`uk-width-2-5@m ${styles.groupsContainer}`}>
								<ul className="app-menu-list country-menu-groups">
									<li
										className={
											isAllChildrensSelected(listOfAllGroups, selectedLocations)
												? styles.selected
												: ""
										}
										onClick={(e) => {
											e.preventDefault();
											onAllGroupsSelect(isAllChildrensSelected(listOfAllGroups, selectedLocations));
											selectAllGroups(!isAllGroupsSelected);
										}}
									>
										<a href="#">{t("All Groups").toUpperCase()}</a>
									</li>
									{groupList.map((group, index) => (
										<li
											onClick={(e) => {
												e.preventDefault();
												let locationsToSet;
												if (selectedLocations.includes(group.uid)) {
													locationsToSet = selectedLocations.filter(
														(location) => location !== group.uid
													);
												} else {
													selectedLocations.push(group.uid);
													locationsToSet = selectedLocations;
												}
												onLocationSelect(locationsToSet.join("-"));
											}}
											key={`${group.fullName.split(" ").join("")}_${index}`}
										>
											<a
												onClick={(e) => e.preventDefault()}
												className={
													selectedLocations.includes(group.uid)
														? styles.selected
														: styles.notSelected
												}
												href="#"
											>
												{group.fullName}
											</a>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};
