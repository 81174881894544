import getSelectedLocationName from '../getSelectedLocationName';
import getSelectedSubjectName from '../getSelectedSubjectName';
import roundToOne from '../roundToOne';
import getSpecificValue from '../getSpecificValue';
import getAvgScoreForGroup from '../getAvgScoreForGroup';
import calculateTrendNumbers from '../calculateTrendNumbers';
import getLevelForMeasure from '../getLevelForMeasure';
import isCitvoiceMeasure from '../isCitvoiceMeasure';
import getSubjectColor from '../getSubjectColor';
import isACountry from '../isACountry';
import possibleException from '../possibleException';
import { defaultVariables } from '../../referenceData/index';

const isException = (val2014, val2015, val2016, endValue2) => val2014 === endValue2 || val2015 === endValue2 || val2016 === endValue2;

const graphDataIterator = (name, values, colour, subject, fullContext) => {
  const dict = {};
  dict.label = name;
  dict.subject = subject;
  dict.backgroundColor = `rgba(${colour}, ${fullContext ? '0.2' : '0.5'})`;// eslint-disable-line i18next/no-literal-string
  dict.borderColor = `rgba(${colour}, 1)`;// eslint-disable-line i18next/no-literal-string
  dict.borderWidth = 1;
  dict.hitRadius = 9;
  dict.hoverBackgroundColor = `rgba(${colour}, 1)`;// eslint-disable-line i18next/no-literal-string
  dict.pointHoverRadius = 12;
  dict.pointRadius = fullContext ? 4 : 9;

  const val = {};
  val.x = values.AAT_Range_1_Rounded;
  val.y = values.AAT_Range_2_Rounded === 'NaN'
    ? values.AAT_Range_1_Rounded
    : values.AAT_Range_2_Rounded;

  dict.data = [val];

  return dict;
};

export default ({
  state,
  location,
  subject,
  selectedYearsRange1,
  selectedYearsRange2,
  subjectCustomColor = false,
  fullContext,
}) => {
  const name = getSelectedLocationName(state, location);
  const itemName = `${getSelectedSubjectName(state, subject)}: ${name}`;// eslint-disable-line i18next/no-literal-string
  let startValue1;
  let endValue1;
  let startValue2;
  let endValue2;
  let results = {};

  if (isACountry(location)) {
    startValue1 = roundToOne(
      getSpecificValue(state, location, subject, selectedYearsRange1[0]),
    );
    endValue1 = roundToOne(
      getSpecificValue(
        state,
        location,
        subject,
        selectedYearsRange1[selectedYearsRange1.length - 1],
      ),
    );
    startValue2 = roundToOne(
      getSpecificValue(state, location, subject, selectedYearsRange2[0]),
    );
    endValue2 = roundToOne(
      getSpecificValue(
        state,
        location,
        subject,
        selectedYearsRange2[selectedYearsRange2.length - 1],
      ),
    );
  } else {
    startValue1 = roundToOne(
      getAvgScoreForGroup(state, subject, selectedYearsRange1[0], location),
    );
    endValue1 = roundToOne(
      getAvgScoreForGroup(
        state,
        subject,
        selectedYearsRange1[selectedYearsRange1.length - 1],
        location,
      ),
    );
    startValue2 = roundToOne(
      getAvgScoreForGroup(state, subject, selectedYearsRange2[0], location),
    );
    endValue2 = roundToOne(
      getAvgScoreForGroup(
        state,
        subject,
        selectedYearsRange2[selectedYearsRange2.length - 1],
        location,
      ),
    );
  }

  let exception = false;

  if (possibleException(endValue2)) {
    if (isACountry(location)) {
      // Get latest_year-3 - 2016 values and check for classification exception
      exception = isException(
        roundToOne(
          getSpecificValue(
            state,
            location,
            subject,
            defaultVariables.latest_year - 3,
          ),
        ),
        roundToOne(
          getSpecificValue(
            state,
            location,
            subject,
            defaultVariables.latest_year - 2,
          ),
        ),
        roundToOne(
          getSpecificValue(
            state,
            location,
            subject,
            defaultVariables.latest_year - 1,
          ),
        ),
        endValue2,
      );
    } else {
      exception = isException(
        roundToOne(
          getAvgScoreForGroup(
            state,
            subject,
            defaultVariables.latest_year - 3,
            location,
          ),
        ),
        roundToOne(
          getAvgScoreForGroup(
            state,
            subject,
            defaultVariables.latest_year - 2,
            location,
          ),
        ),
        roundToOne(
          getAvgScoreForGroup(
            state,
            subject,
            defaultVariables.latest_year - 1,
            location,
          ),
        ),
        endValue2,
      );
    }
  }

  results = calculateTrendNumbers(
    startValue1,
    endValue1,
    startValue2,
    endValue2,
    selectedYearsRange1,
    selectedYearsRange2,
    exception,
  );

  return graphDataIterator(
    itemName,
    results,
    !subjectCustomColor
      ? getSubjectColor({ state, subject, toRgb: true })
      : subjectCustomColor,
    subject,
    fullContext
  );
};
