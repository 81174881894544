import React from "react";
import styles from "./styles.css";

export default ({ message }) => {
	return (
		<div
			className={`uk-notification uk-notification-bottom-right ${styles.messageBoxWrapper}`}
			style={{ display: "block" }}
		>
			<div
				className={`uk-notification-message ${
					message.isSuccessful ? "uk-notification-message-success" : styles.errorMessage
				}`}
				style={{ opacity: 1, marginBottom: "0px" }}
			>
				<a href="#" className="uk-notification-close uk-icon uk-close">
					<svg width={14} height={14} viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
						<line fill="none" stroke="#000" strokeWidth="1.1" x1={1} y1={1} x2={13} y2={13} />
						<line fill="none" stroke="#000" strokeWidth="1.1" x1={13} y1={1} x2={1} y2={13} />
					</svg>
				</a>
				<div>
					<div id="progress_message" className="small_loader">
						{message.message}
					</div>
					<div className=" uk-active uk-margin-top">
						<div className="uk-progress-bar" style={{ width: "0%" }} />
						<span />
					</div>
				</div>
			</div>
		</div>
	);
};
