import React from "react";
import styles from "./styles.css";

export default ({ dropdowns }) => (
	<div className="idp-footer__highlight advanced-settings">
		<div className="uk-width-1-2@s">
			{dropdowns.map((dropdown) => (
				<label>
					{dropdown.text}
					<br />
					Confidence Level
					<select className={styles.dropdown} onChange={(e) => dropdown.onChange(e.target.value)}>
						{dropdown.options.map((option) => (
							<option value={option}>{`${option}%`}</option>
						))}
					</select>
				</label>
			))}
		</div>
	</div>
);
