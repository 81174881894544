import React from "react";
import styles from "./styles.css";
import loadingSpinner from "../../../img/anim/loader.gif";

export default ({ coverLayout = false }) => {
	return (
		<div className={`${styles.spinnerWrapper} ${coverLayout ? styles.spinnerWrapperCover : ""}`}>
			<img className={styles.spinner} src={loadingSpinner} />
		</div>
	);
};
