import {defaultVariables, urls} from '../../referenceData';
import {fetchWithRetry} from "../index";

let loadingPromise;
let output;

export default async () => {
  if (loadingPromise) {
    await loadingPromise;
  }

  if (output) {
    return output;
  } else {

    let resolveLoadingPromise;
    loadingPromise = new Promise(function (resolve, reject) {
      resolveLoadingPromise = resolve;
    });
    try {
      const level1 =  fetchWithRetry(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&to_level=1&_v=${process.env.VERSION}`,// eslint-disable-line i18next/no-literal-string
      );

      const level2 =  fetchWithRetry(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=2&to_level=2&_v=${process.env.VERSION}`,// eslint-disable-line i18next/no-literal-string
      );

      const level3 =  fetchWithRetry(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=3&to_level=3&_v=${process.env.VERSION}`,// eslint-disable-line i18next/no-literal-string
      );

      const level4 =  fetchWithRetry(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=4&to_level=4&_v=${process.env.VERSION}`,// eslint-disable-line i18next/no-literal-string
      );

      const level5 =  fetchWithRetry(
        `${urls.apiUrl()}data/?format=json&year1=${defaultVariables.earliest_year}&year2=${defaultVariables.latest_year}&from_level=5&to_level=5&_v=${process.env.VERSION}`,// eslint-disable-line i18next/no-literal-string
      );

      const allData = await Promise.all([level1, level2, level3, level4, level5]);
      const flattened = (await Promise.all(allData.map( async (data) => {
        return (await data.json()).data;
      }))).flat();

      output = flattened;

      return flattened;
    } catch (err) {
      console.log(err);
    }
  }
};
