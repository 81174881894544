import React, { useState } from "react";
import { default as DriversOfChangeHeader } from "../DriversOfChangeHeader/index.jsx";
import { default as DriversOfChangeDataTable } from "../DriversOfChangeDataTable/index.jsx";
import { default as LoadingSpinner } from "../LoadingSpinner/index.jsx";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {getUrlQueryParams, setUrlQueryParam} from "../../actions";




export default ({ title, headerPanel, fullpage, drivers, opposers, selectedSubjectLevel }) => {

	if ((!drivers && !opposers) || selectedSubjectLevel > 3) {
		return ``;
	}

	const { t } = useTranslation();

	const numLevels = Math.max(drivers?.tables?.length || 0, opposers?.tables?.length || 0);

	const urlParams = getUrlQueryParams();
	const urlExpandedLevels = urlParams.expandedLevels;
	let defaultLevelsExpanded;
	if (urlExpandedLevels) {
		defaultLevelsExpanded = urlExpandedLevels.split("").map(e => e === "1");
	} else {
		defaultLevelsExpanded = [true];
		defaultLevelsExpanded.push(...Array(numLevels-1).fill(false));
	}

	const [levelExpanded, setLevelExpanded] = useState(defaultLevelsExpanded);

	const expandLevel = (level) => {
		const newLevelExpanded = [...levelExpanded];
		newLevelExpanded[level] = !newLevelExpanded[level];
		setLevelExpanded(newLevelExpanded);
		setUrlQueryParam({name: "expandedLevels", value: newLevelExpanded.map(e => e ? 1 : 0).join("")});
	}

	const isLevelExpanded = (level) => {
		for (let i = 0; i <= level; i++) {
			if (!levelExpanded[i]) {
				return false;
			}
		}
		return true;
	}

	const TableSection = ({table, index, title, drivers}) => {
		return (
			<div
				className={`${styles.subTablesWrapper} ${drivers && !opposers ? styles.doc_grid_100 : drivers ? styles.doc_grid_l : styles.doc_grid_r} ${
					(index === 0 || isLevelExpanded(index)) ? styles.active : ""
				}`}
				style={{'--order': index*2+1}}
			>
				<p className={styles.columnTitle}>{title}</p>
				<DriversOfChangeDataTable table={table} />
			</div>
		);
	}

	const expanders = [];
	for (let i = 1; i < numLevels; i++) {
		if (isLevelExpanded(i-1)) {
			expanders.push(
				<div
					onClick={() => expandLevel(i)}
					className={`${styles.doc_grid_expand} ${styles.advancedHeader}`}
					style={{'--order': i * 2}}
					key={`expander_${i}`}
				>
					{t("Show more detail", {level: drivers.tables[i].header[0]})}
					{isLevelExpanded(i) ? (
						<span className={styles.expandIcon} uk-icon="icon: minus"/>
					) : (
						<span className={styles.expandIcon} uk-icon="icon: plus"/>
					)}
				</div>
			);
		}
	}

	return (
		<div className={`uk-container uk-width-1-1 ${styles.container}`}>
			<div className={`${styles.doc_grid}`}>
				<div className={styles.doc_grid_100}>
					{fullpage ? <h1 className={styles.chartTitle}>{title}</h1> :
						<p className={styles.chartTitle}>{title}</p>}
				</div>
				<div className={`${styles.doc_grid_100} ${styles.headerPanelWrapper}`}>
					<DriversOfChangeHeader headerPanel={headerPanel} />
				</div>
				{expanders}
				{ drivers.tables?.length ? drivers.tables.map((table, index) => (
					<TableSection table={table} title={drivers.title} index={index} drivers={true} key={`driverstable_${index}`} />
				)) : null }
				{ opposers.tables?.length ? opposers.tables.map((table, index) => (
					<TableSection table={table} title={opposers.title} index={index} drivers={false} key={`opposerstable_${index}`} />
				)) : null }
			</div>
		</div>
	);
};
