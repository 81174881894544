// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".spinnerWrapper--3juyX {\n    height: 300px;\n}\n", ""]);
// Exports
exports.locals = {
	"spinnerWrapper": "spinnerWrapper--3juyX"
};
module.exports = exports;
