// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".screenshotFrame--3YaMU {\n    position: absolute;\n    top: 999999px;\n\n    width: 1440px;\n\n    z-index: -1;\n}[dir] .screenshotFrame--3YaMU {\n\n    background: white;\n}[dir=ltr] .screenshotFrame--3YaMU {\n    right: 999999px;\n}[dir=rtl] .screenshotFrame--3YaMU {\n    left: 999999px;\n}\n", ""]);
// Exports
exports.locals = {
	"screenshotFrame": "screenshotFrame--3YaMU"
};
module.exports = exports;
