import html2canvas from 'html2canvas';
import resetDisplay from '../resetDisplay';

const initiateDownload = (uri, fileName) => {
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = fileName;

    // Firefox requires the link to be in the body
    document.body.appendChild(link);

    // Simulate click
    link.click();

    // Remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};

export default ({ onFinish }) => {
  const targetObj = document.getElementById('screenshotPdfFrame');

  html2canvas(targetObj)
    .then((canvas) => {
      initiateDownload(canvas.toDataURL(), '2020-iiag.png');
    })
    .then(() => onFinish())
    .catch(() => {
      notifyError(
        "<div id='progress_message' class='uk-active'><p><br />There was a problem. Your view could not be taken.</p></div>",
        3000,
      );
    });
};
