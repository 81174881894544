import { default as getSelectedYears } from '../getSelectedYears';
import { default as getSelectedSubjects } from '../getSelectedSubjects';
import { default as getAvgScoreForGroup } from '../getAvgScoreForGroup';
import { default as roundToOne } from '../roundToOne';

export default (state, groupCode, subject) => {
  const { allMeasures } = state;

  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedSubjects = getSelectedSubjects(state);
  const averages = [];

  selectedYears.forEach((obj) => {
    const pos = {};
    let sc = roundToOne(getAvgScoreForGroup(state, subject, obj, groupCode));
    sc = isNaN(sc) ? -1 : sc;

    // Exception for ARTProv - no reporting of values until 2010
    if (selectedSubjects[0] == 'ARTProv' && obj >= 2010) {
      pos[obj] = sc;
      averages.push(pos);
    } else if (sc != -1 && selectedSubjects[0] != 'ARTProv') {
      pos[obj] = sc;
      averages.push(pos);
    } else {
      pos[obj] = null;
      averages.push(pos);
    }
  });

  return averages;
};
