import getSelectedSubjectName from '../getSelectedSubjectName';
import getSubjectColor from '../getSubjectColor';
import getAvgScoreForGroup from '../getAvgScoreForGroup';
import { regionCodes } from '../../referenceData';

export default (state, subjects, location, years) => subjects.map((subject) => {
  let data = [];
  const subjectColor = getSubjectColor({ state, subject });

  if (regionCodes.includes(location)) {
    data = years.map((year) => getAvgScoreForGroup(state, subject, year, location));
  } else {
    data = years.map((year) => Number(state?.indexedYml[year][subject][location]?.v).toFixed(1));
  }
  data = data.map((v) => (v > -1 ? v : null));

  return {
    label: getSelectedSubjectName(state, subject),
    backgroundColor: `${subjectColor}44`,
    hoverBackgroundColor: subjectColor,
    borderColor: 'rgb(140,140,140)',
    borderWidth: 2,
    hitRadius: 10,
    subjectColor,
    pointRadius: (pointData) => (pointData.dataIndex !== data.length - 1 ? 0 : 8),
    pointHoverRadius: 8,
    pointBorderWidth: 1,
    pointBorderColor: 'rgb(140,140,140)',
    pointBackgroundColor: subjectColor,
    data,
    tooltip: {
      order: 111,
    },
  };
});
