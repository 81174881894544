import React from "react";
import styles from "./styles.css";

export default ({ children, title }) => {
	return (
		<div className="uk-container uk-margin-top uk-margin-bottom">
			<h3 className={styles.screenshotPdfContentTitle}>{title}</h3>
			{children}
		</div>
	);
};
