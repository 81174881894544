import { SingleTrend } from '../../../../components';
import {
  calculateTallyForSubjectAndYear,
  calculateTrendNumbers,
  extractValuePairs,
  formatAATNumber,
  formatATNumber,
  getGroupAveragesForSubject,
  getGroupCodeFromName,
  getItemsWithParent,
  getLevelForMeasure,
  getLevelName,
  getRank,
  getSpecificValue,
  getStyleLevelForMeasure,
  getSubjectColor,
  isACountry,
  isCitvoiceMeasure,
  numCountriesWithRank,
  possibleException,
  prettifyNumber,
  rankPercentInt,
  roundToOne,
  upDownZero,
  getSelectedLocationName,
  getSelectedSubjectName,
} from '../../../../actions';
import { defaultVariables } from '../../../../referenceData';
import { useAppContext } from '../../../../context/AppState.jsx';
import i18next from "i18next";
import i18n from "../../../../i18n";

const isException = (val2014, val2015, val2016, endValue2) => val2014 === endValue2 || val2015 === endValue2 || val2016 === endValue2;

export const createSingleTrend = (
  country,
  subject,
  index,
  subview,
  returnComponent = true,
) => {
  const [state] = useAppContext();
  if (!index) {
    index = 0;
  }

  const subjectName = getSelectedSubjectName(state, subject);
  const trendData = {};

  let locationName;
  let startValue1;
  let endValue1;
  let startValue2;
  let endValue2;
  const AATResults = {};

  if (isACountry(country)) {
    locationName = getSelectedLocationName(state, country);

    startValue1 = roundToOne(
      getSpecificValue(state, country, subject, defaultVariables.earliest_year),
    );
    endValue1 = roundToOne(
      getSpecificValue(state, country, subject, defaultVariables.latest_year),
    );
    startValue2 = roundToOne(
      getSpecificValue(state, country, subject, defaultVariables.mid_year),
    );
    endValue2 = roundToOne(
      getSpecificValue(state, country, subject, defaultVariables.latest_year),
    );

    var exception = false;


  } else {
    let val;
    let val2;
    locationName = getSelectedLocationName(state, country);

    val = extractValuePairs(
      getGroupAveragesForSubject(state, country, subject, [
        defaultVariables.earliest_year,
        defaultVariables.latest_year,
      ]),
    );
    val2 = extractValuePairs(
      getGroupAveragesForSubject(state, country, subject, [
        defaultVariables.latest_year - 4,
        defaultVariables.latest_year,
      ]),
    );

    startValue1 = roundToOne(val[0].value);
    endValue1 = roundToOne(val[1].value);
    startValue2 = roundToOne(val2[0].value);
    endValue2 = roundToOne(val2[1].value);

    var exception = false;


    trendData.score = endValue1;
  }

  const results = calculateTrendNumbers(
    startValue1,
    endValue1,
    startValue2,
    endValue2,
    defaultVariables.default_year_array,
    defaultVariables.default_secondary_year_array,
    exception,
  );

  AATResults[country] = results.classifText;

  trendData.change_0 = formatAATNumber(results.AAT_Range_1_Rounded);
  trendData.change_1 = formatAATNumber(results.AAT_Range_2_Rounded);
  trendData.index = country;
  trendData.classifText = results.classifText;
  trendData.subject_color = getSubjectColor({ state, subject });
  trendData.subject = subjectName;
  trendData.location = locationName;

  const notApplicable = 'n/a';

  const arrowIcon = upDownZero(results.AAT_Range_1_Rounded);

  if (subview === 'score' || subview === 'absoluteTrends') {
    // Show AT info
    trendData.hide_since = 'uk-hidden';
    trendData.ch_at = formatATNumber(results.AT_Range_1_Rounded);

    trendData['No<br />Change'] = 'No Change'; // eslint-disable-line i18next/no-literal-string
    trendData['n/a'] = `&nbsp;&nbsp;${notApplicable}&nbsp;&nbsp;`; // eslint-disable-line i18next/no-literal-string
    trendData.ch_updown = arrowIcon;
  } else {
    // Show AAT info
    trendData.hide_since_AT = 'uk-hidden';
    trendData.ch_aat = results.classifText;
    trendData['No<br />Change'] = 'No&nbsp;Change'; // eslint-disable-line i18next/no-literal-string
    trendData['n/a'] = `&nbsp;&nbsp;${notApplicable}&nbsp;&nbsp;`; // eslint-disable-line i18next/no-literal-string
    trendData.ch_updown = arrowIcon;

    if (arrowIcon === 'zero') {
      trendData.hide_info_icon = 'uk-hidden';
    }
  }
  if (returnComponent) {
    return SingleTrend({ trendData });
  }

  return trendData;
};

export const createSingleLocationMultiSubjectOverview = ({
  state,
  selectedLocations,
  selectedSubjects,
  subview,
  isTakingScreenshot,
}) => {
  const overviewStateAT = subview === 'absoluteTrends';
  const locationData = {};
  const measureTreeOrder = state.measureTreeOrder;

  let headlineTransKey;
  if (isACountry(selectedLocations[0])) {
    headlineTransKey = selectedLocations[0];
    locationData.first_row_width = 'uk-width-1-3';
    locationData.hide = '';
    locationData.hideFlag = '';
    locationData.hideRank = '';
  } else {
    headlineTransKey = selectedLocations[0];
    locationData.first_row_width = 'uk-width-1-2';
    locationData.hideFlag = 'uk-hidden';
    locationData.hideRank = 'uk-hidden';
  }

  // @TODO GLOBALSTRINGS translations.since_year = earliest_year;
  locationData.subjects = selectedSubjects.sort((a, b) => measureTreeOrder.indexOf(a) - measureTreeOrder.indexOf(b));
  locationData.iso = selectedLocations[0];
  locationData.headline = getSelectedLocationName(state, headlineTransKey);
  locationData.overviewStateAT = overviewStateAT;
  locationData.isTakingScreenshot = isTakingScreenshot;
  // Fill in basic content
  return locationData;
};

export const createSingleLocationSingleSubjectOverviewData = ({
  state,
  selectedLocations,
  selectedSubjects,
  subview,
  isTakingScreenshot,
}) => {
  const level = getLevelForMeasure(state, selectedSubjects[0]);
  let rank;
  let locationName;

  let startValue1;
  let endValue1;
  let startValue2;
  let endValue2;
  let headlineTransKey;
  const showAbsoluteTrends = subview === 'absoluteTrends';

  const locationData = {};
  locationData.isCountry = isACountry(selectedLocations[0]);

  let results = {};
  const AATResults = {};

  // Get the right template content

  const country = isACountry(selectedLocations[0]);

  let startYear = defaultVariables.earliest_year;

  // If this is a country
  if (country) {
    var score_rounded = roundToOne(
      getSpecificValue(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        defaultVariables.latest_year,
      ),
    );

    // If no data for this country in this year, find first year with data
    if (
      getSpecificValue(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        startYear,
      ) < 0
    ) {
      for (let i = 1; i < defaultVariables.default_year_array.length - 1; i++) {
        if (
          getSpecificValue(
            state,
            selectedLocations[0],
            selectedSubjects[0],
            defaultVariables.default_year_array[i],
          ) >= 0
        ) {
          startYear = defaultVariables.default_year_array[i];
          // @TODO GLOBALSTRINGS translations.since_year_0 = startYear.toString();
          // @TODO GLOBALSTRINGS dynamic_strings.since_year_0 = startYear.toString();
          break;
        }
      }
    }

    startValue1 = roundToOne(
      getSpecificValue(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        defaultVariables.default_year_array[0],
      ),
    );
    endValue1 = roundToOne(
      getSpecificValue(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        defaultVariables.default_year_array[
          defaultVariables.default_year_array.length - 1
        ],
      ),
    );
    startValue2 = roundToOne(
      getSpecificValue(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        defaultVariables.default_secondary_year_array[0],
      ),
    );
    endValue2 = roundToOne(
      getSpecificValue(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        defaultVariables.default_secondary_year_array[
          defaultVariables.default_secondary_year_array.length - 1
        ],
      ),
    );

    let exception = false;

    results = calculateTrendNumbers(
      startValue1,
      endValue1,
      startValue2,
      endValue2,
      defaultVariables.default_year_array,
      defaultVariables.default_secondary_year_array,
      exception,
    );

    // Store classification for information overlay
    AATResults[selectedLocations[0]] = results.classifText;

    rank = getRank(
      state,
      selectedSubjects[0],
      defaultVariables.latest_year,
      selectedLocations[0],
      getGroupCodeFromName(state, 'Africa'),
    );
    locationName = getSelectedLocationName(state, selectedLocations[0]);
    headlineTransKey = `cnm${selectedLocations[0]}`; // eslint-disable-line i18next/no-literal-string

    locationData.first_row_width = 'uk-width-1-3';
    locationData.hide = '';
    locationData.hideFlag = isTakingScreenshot ? 'uk-hidden' : '';
    locationData.iso = selectedLocations[0];
  } else {
    // Group
    let val;
    let val2;

    val = extractValuePairs(
      getGroupAveragesForSubject(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        [defaultVariables.earliest_year, defaultVariables.latest_year],
      ),
    );
    val2 = extractValuePairs(
      getGroupAveragesForSubject(
        state,
        selectedLocations[0],
        selectedSubjects[0],
        [defaultVariables.latest_year - 4, defaultVariables.latest_year],
      ),
    );

    const startValue1 = roundToOne(val[0].value);
    const endValue1 = roundToOne(val[1].value);
    const startValue2 = roundToOne(val2[0].value);
    const endValue2 = roundToOne(val2[1].value);

    let exception = false;
    
    results = calculateTrendNumbers(
      startValue1,
      endValue1,
      startValue2,
      endValue2,
      defaultVariables.default_year_array,
      defaultVariables.default_secondary_year_array,
      exception,
    );

    // Store classification for information overlay
    AATResults[selectedLocations[0]] = results.classifText;

    locationName = getSelectedLocationName(state, selectedLocations[0]);

    headlineTransKey = selectedLocations[0];

    score_rounded = roundToOne(val[1].value);

    locationData.first_row_width = 'uk-width-1-2';
    locationData.hide = 'uk-hidden';
    locationData.hideFlag = 'uk-hidden';
    locationData.iso = selectedLocations[0];
  }

  // Build most of the content
  const subject = selectedSubjects[0];
  const subject_name = getSelectedSubjectName(state, selectedSubjects[0]);
  const scoremarkup = ''; // createSingleScore(state);

  locationData.headline = locationName;
  locationData.score_markup = scoremarkup;
  locationData.headlineTransKey = headlineTransKey;
  locationData.subject = subject_name;
  locationData.total = calculateTallyForSubjectAndYear(
    state,
    selectedSubjects[0],
    defaultVariables.latest_year,
  );
  locationData.score = isNaN(score_rounded)
    ? 'n/a'
    : prettifyNumber(score_rounded);
  locationData.score_int = isNaN(score_rounded) ? 0 : parseInt(score_rounded);
  locationData.subject_type = isCitvoiceMeasure(state, selectedSubjects[0])
    ? 'citvoice'
    : 'regular';
  locationData.subject_level = `level-${getStyleLevelForMeasure(// eslint-disable-line i18next/no-literal-string
    state,
    selectedSubjects[0],
  )}`;
  locationData.since_year = (defaultVariables.latest_year - 9).toString();
  locationData.ranking = rank;
  locationData.countries_with_rank = numCountriesWithRank(
    selectedSubjects[0],
    defaultVariables.latest_year,
  );
  locationData.change_0 = formatAATNumber(results.AAT_Range_1_Rounded);
  locationData.change_1 = formatAATNumber(results.AAT_Range_2_Rounded);
  locationData.since_year_0 = defaultVariables.default_year_array[0];
  locationData.since_year_1 = defaultVariables.default_secondary_year_array[0];
  locationData.subject_s_id = selectedSubjects[0];
  locationData.subject_color = getSubjectColor({ state, subject });
  locationData.subject_rank_percent_int = rankPercentInt(rank, locationData.countries_with_rank);
  locationData.hide_icon = level <= 1 || (!isCitvoiceMeasure(state, selectedSubjects[0]) && level <= 2)
    ? ''
    : 'uk-hidden';
  locationData.index = selectedLocations[0];

  if (showAbsoluteTrends || subview === 'locationPage') {
    locationData.hide_since_AT = false;
    locationData.hide_since = true;
    locationData.overall_change_at = formatATNumber(results.AT_Range_1_Rounded);
    locationData.ch_updown = upDownZero(results.AT_Range_1_Rounded);
  } else {
    locationData.hide_since = false;
    locationData.hide_since_AT = true;
    locationData.overall_change_aat = results.classifText;
    locationData.ch_updown = upDownZero(results.AAT_Range_1_Rounded);
  }

  // Complete the bottom row
  // If levels 0, 1 or 2
  locationData.level = level;
  locationData.show_children = false;
  if (level <= 3) {
    // Get children
    locationData.show_children = true;
    locationData.children = getItemsWithParent(state, selectedSubjects[0]);
  }
  locationData.sub_name = getLevelName(locationData.level + 1);
  locationData.showSubcategoryInfoIcon = typeof subview === 'undefined';
  locationData.linkToSource = `/locations/${selectedLocations[0].toLowerCase()}.html?meas=GOVERNANCE&loc=${ // eslint-disable-line i18next/no-literal-string
    selectedLocations[0]
  }`;
  locationData.locationPage = subview === 'locationPage';
  locationData.isTakingScreenshot = isTakingScreenshot;

  return locationData;
};

export const createMultiLocationSingleSubjectOverview = ({
  state,
  selectedLocations,
  selectedSubjects,
  subview,
  isTakingScreenshot,
}) => {
  const subject = selectedSubjects[0];

  const subjectData = {
    level: getLevelForMeasure(state, subject),
    subject,
    locations: selectedLocations,
    locationCount: selectedLocations.length,
    child_width:
      selectedLocations.length == 3
        ? 'uk-child-width-1-3@m uk-child-width-1-2@s'
        : 'uk-child-width-1-2@s',
    isTakingScreenshot,
  };
  return subjectData;
};
