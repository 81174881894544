import React from "react";

export default ({ sortBySettings, sortDirectionSettings }) => {
	return (
		<div className="idp-footer__highlight advanced-settings">
			<div className="uk-width-1-2@s">
				<div>
					<label className={`reduced-size-label clickable_pointer`}>{sortBySettings.title}</label>
					<div>
						{sortBySettings.options.map((option) => {
							return (
								<label className={`reduced-size-label clickable_pointer`}>
									<input
										checked={option.checked}
										type="radio"
										onClick={() => {
											if (option.checked) {
												return;
											}
											option.onClick();
										}}
									/>
									{option.label}
								</label>
							);
						})}
					</div>
				</div>
				<div>
					<label className={`reduced-size-label clickable_pointer`}>{sortDirectionSettings.title}</label>
					<div>
						{sortDirectionSettings.options.map((option) => (
							<label className={`reduced-size-label clickable_pointer`}>
								<input
									checked={option.checked}
									type="radio"
									onClick={() => {
										if (option.checked) {
											return;
										}
										option.onClick();
									}}
								/>
								{option.label}
							</label>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
