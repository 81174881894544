export default (name, value, days) => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;// eslint-disable-line i18next/no-literal-string
  } else {
    expires = '';
  }

  document.cookie = `${name}=${value}${expires}; path=/`;// eslint-disable-line i18next/no-literal-string
};
