import React from "react";
import { OverviewSingleLocationSingleSubject } from "../../../../components";
import { createSingleLocationSingleSubjectOverviewData } from "../../../Data/partials/Overview/utils.js";
import { LoadingSpinner } from "../../../../components";
import { Information } from "../../../../components/Modals";
import { useModalContext } from "../../../../context/modalContext.jsx";

export default ({ state }) => {
	if (!state?.indexedYml) {
		return <LoadingSpinner />;
	}

	const locationData = {
		...createSingleLocationSingleSubjectOverviewData({
			state,
			selectedLocations: [state.selectedLocation.toUpperCase()],
			selectedSubjects: [state.selectedSubject],
			subview: "locationPage",
		}),
	};

	return <OverviewSingleLocationSingleSubject locationData={{ ...locationData }} />;
};
