import React from "react";

export default ({ crumbs }) => (
	<div className="breadcrumb ">
		<div className="uk-container">
			<ul>
				{crumbs.map((crumb, i) => (
					<li key={i}>
						{crumb.href ? (
						<a href={crumb.href}>{crumb.text}</a>
							) : (
						<span>{crumb.text}</span>
						)}
					</li>
				))}
			</ul>
		</div>
	</div>
);
