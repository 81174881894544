import React from "react";
import styles from "./styles.css";
import subjectCss from "../../../css/global/subjectTypography.css";
import {useTranslation} from "react-i18next";
import {scoreChangeFormat} from "../../actions";

export default ({ headerPanel }) => {
	const {t, i18n} = useTranslation();

	return (
		<div className="uk-grid">
			<div className={`uk-width-1-6@m uk-width-1-3@s ${styles.titleWrapper}`}>
				<div className={styles.locationName}>{headerPanel.title.location}</div>
				<div className={`${subjectCss[`textStyling${headerPanel.title.subjectLevel}`]}`}>{headerPanel.title.subject}</div>
			</div>
			<div className={`uk-width-5-6@m uk-width-2-3@s ${styles.summaryWrapper}`}>
				<span className={styles.summaryIcon}>
					<img src={`/img/icons/trend_aat_${headerPanel.summary.icon}.svg`} />
				</span>
				<span className={styles.summaryLabel}>
					{headerPanel.summary.isUnchanged ? (
						<>
						{t('Score {{direction}} at {{score}} between {{start_year}} and {{end_year}}', headerPanel.summary.label)}
						</>
					) : (
						<>
						{/* eslint-disable-next-line i18next/no-literal-string */}
						{t(`Score {{direction}} by`, headerPanel.summary.label)}
						<span className={headerPanel.summary.label.change < 0 ? styles.negativeChange : headerPanel.summary.label.change > 0 ? styles.positiveChange : '' }>
							&nbsp;{scoreChangeFormat(headerPanel.summary.label.change)}&nbsp;
						</span>
						{t('points to {{score}} between {{start_year}} and {{end_year}}', headerPanel.summary.label)}
						</>
					)
					}

				</span>
			</div>
		</div>
	);
};
