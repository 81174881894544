// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".genericSubmenuWrapper--1zD_D {\n\n    position: absolute;\n}\n\n", ""]);
// Exports
exports.locals = {
	"genericSubmenuWrapper": "genericSubmenuWrapper--1zD_D"
};
module.exports = exports;
