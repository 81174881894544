import React from "react";
import styles from "./styles.css";

export default ({ logo, isEmbedPage, navigationLinks, copyright, seoLinks }) => (
	<footer className={isEmbedPage ? styles.footer : ""}>
		<div id="pre_footer" className="">
			<div className="uk-container pre-footer-inner">
				<img src="/dist/src/img/data.svg" alt="Data" />
			</div>
		</div>
		<div
			className={`${
				isEmbedPage
					? `${styles.footerWrapperEmbed} uk-container uk-margin-top uk-margin-bottom`
					: "uk-container"
			} py-4 d-md-flex d-block flex-column flex-sm-row justify-content-between`}
		>
			<div className="colophon">
				{copyright}
			</div>
			<div className="copyright-holder text-left text-md-right py-1">
				{seoLinks?.map((nav, index) => (
					<span key={`${nav.link.text.split(" ").join("")}_${index}`}>
						{nav.prepandText ? <span>{nav.prepandText}</span> : null}
						<a className="" href={nav.link.href} target="_self">
							{nav.link.text}
						</a>
						{seoLinks.length > 1 && index !== seoLinks.length - 1 ? (
							<span className={styles.separator}>|</span>
						) : null}
					</span>
				))}
				<br />
				{navigationLinks.map((nav, index) => (
					<span key={`${nav.link.text.split(" ").join("")}_${index}`}>
						{nav.prepandText ? <span>{nav.prepandText}</span> : null}
						<a className="" href={nav.link.href} target="_self">
							{nav.link.text}
						</a>
						{navigationLinks.length > 1 && index !== navigationLinks.length - 1 ? (
							<span className={styles.separator}>|</span>
						) : null}
					</span>
				))}
			</div>
		</div>
	</footer>
);
