import React from "react";
import { ScreenshotAndPdfFrame, ScreenshotHeader, ScreenshotFooter, ScreenshotContent } from "../../../../components";
import { AppRouter } from "../";
import { defaultVariables } from "../../../../referenceData";
import { getSubjectTitle, getLocationTitle, getUrlQueryParams } from "../../../../actions";
import { useAppContext } from "../../../../context/AppState.jsx";

export default () => {
	const [state] = useAppContext();
	if (!state.indexedYml) {
		return null;
	}

	const urlParams = getUrlQueryParams();
	const takingss = JSON.parse(urlParams?.takingss || false);

	if (!takingss) {
		return null;
	}
	const view = urlParams.view;
	let titleYear = false;
	switch (view) {
		case 'graph':
			// for graph, we only do this on the bar chart
			if (urlParams?.option === 'bar' && urlParams?.subview == 'absoluteTrend') {
				titleYear = `${JSON.parse(urlParams?.range1from) ?? defaultVariables.earliest_year}-${JSON.parse(urlParams?.range1to) ?? defaultVariables.latest_year}`;
			} else if (urlParams?.option === 'bar') {
				titleYear = JSON.parse(urlParams?.range1to) ?? defaultVariables.latest_year;
			}
			break;
		case 'map':
			if (urlParams.subview === 'score') {
				titleYear = JSON.parse(urlParams?.range1to) ?? defaultVariables.latest_year;
			} else {
				titleYear = defaultVariables.latest_year
			}
			break;
		case 'overview':
			titleYear = defaultVariables.latest_year
			break;
	}

	const title = `${i18n.t("{{subject}} for {{location}}", {
		subject: getSubjectTitle(state),
		location: getLocationTitle(state)
	  })}${titleYear ? ` (${titleYear})` : ``}`;

	return (
		<ScreenshotAndPdfFrame>
			<ScreenshotHeader />
			<ScreenshotContent title={title}>
				<AppRouter isTakingScreenshot={true} />
			</ScreenshotContent>
			<ScreenshotFooter currentYear={new Date().getFullYear()} />
		</ScreenshotAndPdfFrame>
	);
};
