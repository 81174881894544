// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .footer--3OtSO {\n    background-color: white;\n}\n\n[dir] .footerWrapperEmbed--2ZFyO {\n    background-color: white;\n    border-top: 1px solid #d8d8d8;\n}\n\n[dir=ltr] .separator--EPB69 {\n    margin-left: 5px;\n    margin-right: 5px;\n}\n\n[dir=rtl] .separator--EPB69 {\n    margin-right: 5px;\n    margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"footer": "footer--3OtSO",
	"footerWrapperEmbed": "footerWrapperEmbed--2ZFyO",
	"separator": "separator--EPB69"
};
module.exports = exports;
