import React, { useMemo } from "react";
import { Chartjs } from "../../../../components";
import { LoadingSpinner } from "../../../../components";
import { getChartConfig, getChartTitle } from "./utils";
import i18n from "../../../../i18n";

export default ({ state }) => {
	if (!state?.indexedYml) {
		return <LoadingSpinner />;
	}

	const subject = state.selectedSubject;
	const selectedLocation = state.selectedLocation;

	const formattedRangeTextTitle = useMemo(
		() => getChartTitle(state, subject, selectedLocation),
		[state.setUrlQueryParam, state.selectedSubject, i18n.language]
	);

	const rangeConfig = useMemo(
		() => getChartConfig(state, formattedRangeTextTitle),
		[state.setUrlQueryParam, state.selectedSubject, i18n.language]
	);

	const chartSize = useMemo(
		() => ({
			height: "600px",
		}),
		[state.setUrlQueryParam]
	);

	return <Chartjs chartConfig={rangeConfig} chartId="rangeChart" chartSize={chartSize} />;
};
