import React, { useState } from "react";
import { colourMap, drawMap } from "./utils";
import { defaultVariables } from "../../referenceData";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {scoreChangeFormat, scoreFormat} from "../../actions";

const createScorePopup = (sidePopup, t) => {
	const hideIcon = () => (sidePopup.citvoice && sidePopup.level > 2) || (!sidePopup.citvoice && sidePopup.level > 2);
	return (
	<>
		<div
			className={`percentage-circle ${sidePopup.subjectType} ${sidePopup.subjectLevel}`}
			style={{ "--color": `rgb(${sidePopup.subjectColor})`, "--score": Math.max(0,sidePopup.score) }}
		>
			<svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<circle className="circle-back" r="45%" cx="50%" cy="50%" fill="transparent" pathLength="100"></circle>
				<circle className="circle-front" r="45%" cx="50%" cy="50%" fill="transparent" pathLength="100"></circle>
			</svg>

			{sidePopup.citvoice && sidePopup.level > 2 ? (
					<span className={styles.innerCircle}></span>
			) : (
				<span>
					<span className={` ${hideIcon() ? "uk-hidden" : ""} `}>
						<img className="measure-icon-image" src={`/img/icons/${sidePopup.icon}.svg`} />
					</span>
				</span>
			)}

		</div>
		<span className="overview_score uk-width-1-1 ">{scoreFormat(sidePopup.score)}</span>
		<h4 className=" uk-width-1-1 overview-body-copy">{t("Out of")} 100.0</h4>
	</>
);
}

const createTrendPopup = (sidePopup, t) => {
	return (
		<div className="overview_small_score block uk-margin-medium-right uk-width-1-1 single-trend-icon">
		<span className="trend-icon-small uk-hidden" style={{ color: "#4C5C0F" }}>
			<img src="/img/icons/trend_up.svg" />
		</span>
			<span className="trend-icon-aat-small undefined">
			<img src={`/img/icons/trend_aat_${sidePopup.icon}.svg`} />
		</span>
			<div className="overview-bold-title  single-trend-change uk-width-1-1 undefined ">
				{sidePopup.classification ? sidePopup.classification : "n/a"}
			</div>
			<span className="primary-button__classification-info-multi-location undefined undefined information_icon uk-icon-information">
			<i className="uk-icon" uk-icon="icon:info; ratio:1" />
		</span>
			<span className="overview-small-text uk-width-1-1 top_margin_5  undefined single-trend-detail">
			{scoreChangeFormat(sidePopup.aatRange1, true)} <span>{t("AAT since {{year}}", {year: defaultVariables.earliest_year})}</span>
			<br />
				{scoreChangeFormat(sidePopup.aatRange2, true)} <span>{t("AAT since {{year}}", {year: defaultVariables.mid_year})}</span>
		</span>
		</div>
	);
}

export default ({
	state,
	mapTitle,
	sidePopup,
	urlParams,
	selectedLocations,
	selectedSubjects,
	mapGeoJson,
	onMapClick,
	chartId,
	isTakingScreenshot,
}) => {
	const [map, setNewMap] = useState();
	const {t } = useTranslation();
	return (
		<div className={`front uk-width-1-1 uk-align-center uk-panel-box-custom uk-panel-box normal_pointer uk-container ${styles.mapContainer}`}>
			{!isTakingScreenshot ? <h1 className="uk-text-center map-title">{mapTitle}</h1> : null}
			<div className={`uk-grid ${styles.mapWrapper}`}>
				<div className="uk-width-1-1@l trends-div">
					<div className="uk-grid">
						{selectedLocations.length === 1 ? (
							<div className="trends-single-trend">
								<div className="uk-width-1-1 single-trend-description subject-name">
									{sidePopup.title}
								</div>
								{urlParams.subview === "score"
									? createScorePopup(sidePopup, t)
									: createTrendPopup(sidePopup, t)}
							</div>
						) : null}

						<div
							className="uk-width-1-1 trends_map uk-margin-medium-top"
							id={chartId}
							ref={() => {
								const mapContainer = document.getElementById(chartId).firstChild;

								if (!mapContainer) {
									setNewMap(
										drawMap({
											htmlName: chartId,
											mapScale: 310,
											whiteBackground: true,
											mapGeoJson,
											state,
											onMapClick,
											selectedSubject: selectedSubjects[0],
										})
									);
								}
								colourMap({
									state,
									map,
									urlParams: urlParams,
									selectedLocations: selectedLocations,
									selectedSubjects: selectedSubjects,
								});
							}}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};
