import React from "react";
import { OverviewSubjectSummary } from "../../pages/Data/partials";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({ locationData }) => {
	const locationUrl = `${location.origin}/locations/${locationData.iso.toLowerCase()}.html`;// eslint-disable-line i18next/no-literal-string
	const {t} = useTranslation();
	return (
		<div className={`${styles.wrapper} overview-main uk-text-center overview-white overview-multi-measure`}>
			<div className="overview-country">
				<div className={`about-link ${locationData.hideFlag} ${styles.aboutLink}`}>
					<div onClick={() => window.open(locationUrl, "_self")}>{t("About {{country}}", {country: locationData.headline})}</div>
				</div>
				<div className="overview_headline uk-margin-top uk-margin-large-bottom">
					<h1 className="overview-title">
						<span placeholder-mif-translation-key="{headlineTransKey}">{locationData.headline}</span>
						<img
							src={`img/flags/${locationData.iso.toLowerCase()}.svg`}
							className={`flag-image flag ${locationData.hideFlag} ${styles.flagIcon} measure-icon-image`}
						/>
					</h1>
				</div>
			</div>

			<div
				className="uk-margin-top uk-margin-bottom uk-grid-divider overview-multi-measure-measure-wrapper"
				id="pnl_sub_overview"
				uk-grid="{}"
				uk-height-match=".subject-name"
			>
				{locationData.subjects.map((e) => (
					<OverviewSubjectSummary key={`${locationData.iso}-${e}`}
						location={locationData.iso}
						subject={e}
						numSubjects={locationData.subjects.length}
						overviewStateAT={locationData.overviewStateAT}
					/>
				))}
			</div>
		</div>
	);
};
