// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".legendWrapper--1je0F {\n    font-family: museo-sans;\n}[dir=ltr] .legendWrapper--1je0F {\n    padding-left: 20px;\n}[dir=rtl] .legendWrapper--1je0F {\n    padding-right: 20px;\n}\n\n.indicator--YSBpu {\n    width: 20px;\n    height: 20px;\n}\n\n[dir=ltr] .indicator--YSBpu {\n    margin-right: 5px;\n}\n\n[dir=rtl] .indicator--YSBpu {\n    margin-left: 5px;\n}\n\n[dir] .negativeChange--1T1D4 {\n    background-color: red;\n}\n\n[dir] .positiveChange--1iIcj {\n    background-color: green;\n}\n", ""]);
// Exports
exports.locals = {
	"legendWrapper": "legendWrapper--1je0F",
	"indicator": "indicator--YSBpu",
	"negativeChange": "negativeChange--1T1D4",
	"positiveChange": "positiveChange--1iIcj"
};
module.exports = exports;
