import { classificationDescriptions } from '../../referenceData';
import i18n from "../../i18n";

export default (
  actualScoreChangeRange1,
  actualScoreChangeRange2,
  score2012,
  onlyReturnIndex,
  isException,
) => {
  let classifText;
  let classifIndex;
  const notApplicable = 'n/a';
  if (
    actualScoreChangeRange1 == notApplicable
    || actualScoreChangeRange2 == notApplicable
  ) {
    classifIndex = 0; // "n/a";
  } else if (
    actualScoreChangeRange1 == actualScoreChangeRange2
    || (actualScoreChangeRange1 < 0
      && score2012 == 0
      && actualScoreChangeRange2 == 0)
    || (actualScoreChangeRange1 > 0
      && score2012 == 100
      && actualScoreChangeRange2 == 0)
  ) classifIndex = 1; // "No Change";
  else if (actualScoreChangeRange1 > 0 && actualScoreChangeRange2 > 0) {
    if (actualScoreChangeRange2 > actualScoreChangeRange1) {
      classifIndex = 7; // "Increasing Improvement";
    } else {
      classifIndex = 6; // "Slowing Improvement";
    }
  } else if (
    (actualScoreChangeRange1 >= 0 && actualScoreChangeRange2 < 0)
    || (actualScoreChangeRange1 > 0 && actualScoreChangeRange2 == 0)
  ) {
    classifIndex = 5; // "Warning Signs";
  } else if (
    (actualScoreChangeRange1 <= 0 && actualScoreChangeRange2 > 0)
    || (actualScoreChangeRange1 < 0 && actualScoreChangeRange2 == 0)
  ) {
    classifIndex = 4; // "Bouncing Back";
  } else if (actualScoreChangeRange1 < 0 && actualScoreChangeRange2 < 0) {
    if (actualScoreChangeRange2 > actualScoreChangeRange1) {
      classifIndex = 3; // "Slowing Deterioration";
    } else {
      classifIndex = 2; // "Increasing Deterioration";
    }
  } else {
    classifIndex = 0; // "n/a";
  }

  if (!onlyReturnIndex) return i18n.t(classificationDescriptions[classifIndex]);

  return classifIndex;
};
