const sortCountriesInGroup = ({ group, sortDirection }) => {
  const sortedCountriesInGroup = [...group].sort((a, b) => a.firstColumn.value.localeCompare(b.firstColumn.value));

  return sortDirection === 'asc'
    ? sortedCountriesInGroup.reverse()
    : sortedCountriesInGroup;
};

const sortTableByLocation = ({ tableRows, sortDirection }) => {
  const arrayOfCountries = [...tableRows].filter(
    (location) => location.firstColumn.type === 'country',
  );
  const arrayOfGroups = [...tableRows].filter(
    (location) => location.firstColumn.type === 'group',
  );

  const sortedArrayOfCountries = [...arrayOfCountries].sort((a, b) => a.firstColumn.value.localeCompare(b.firstColumn.value));
  const sortedArrayOfGroups = [...arrayOfGroups].sort((a, b) => a.firstColumn.value.localeCompare(b.firstColumn.value));

  sortedArrayOfGroups.forEach((group) => {
    if (group.childrens) {
      group.childrens = sortCountriesInGroup({
        group: group.childrens,
        sortDirection,
      });
    }
  });

  return sortDirection === 'des'
    ? [...sortedArrayOfCountries, ...sortedArrayOfGroups]
    : [...sortedArrayOfCountries.reverse(), ...sortedArrayOfGroups.reverse()];
};

const sortClassificationColumn = ({ tableRows, columnId }) => {
  const sortOrder = [
    'Increasing Improvement',
    'Slowing Improvement',
    'Warning Signs',
    'Bouncing Back',
    'Slowing Deterioration',
    'Increasing Deterioration',
    'No Change',
    'n/a',
  ];

  const sortedTable = [...tableRows].sort(
    (a, b) => sortOrder.indexOf(
      a.annualFields.find((record) => record.column === columnId).value,
    )
      - sortOrder.indexOf(
        b.annualFields.find((record) => record.column === columnId).value,
      ),
  );

  sortedTable.forEach((row) => {
    if (row.childrens) {
      row.childrens = sortClassificationColumn({
        tableRows: row.childrens,
        columnId,
      });
    }
  });

  return sortedTable;
};

const sortRecordColumn = ({
  tableRows, columnId, sortDirection, subview,
}) => {
  const NARecords = [...tableRows].filter(
    (row) => row.records.find((record) => record.column === columnId).value === 'n/a',
  );
  const noNARecords = [...tableRows].filter(
    (row) => row.records.find((record) => record.column === columnId).value !== 'n/a',
  );

  const sortedNARecords = [...NARecords].sort((a, b) => {
    if (a.firstColumn.type === b.firstColumn.type) {
      return 0;
    } else if (a.firstColumn.type === 'group') {
        return -1;
    } else if (b.firstColumn.type === 'group') {
        return 1;
    }
    return 0;
  })

  let sortedTable = [...noNARecords].sort((a, b) => {
      const aVal = a.records.find((record) => record.column === columnId).value;
      const bVal = b.records.find((record) => record.column === columnId).value;
      return aVal - bVal;
  });

  if (subview === 'rank') {
    sortedTable.reverse();
  }

  sortedTable = [...sortedNARecords, ...sortedTable];

  sortedTable.forEach((row) => {
    if (row.childrens) {
      row.childrens = sortRecordColumn({
        tableRows: row.childrens,
        columnId,
        sortDirection,
        subview,
      });
    }
  });

  return sortDirection === 'asc' ? sortedTable : sortedTable.reverse();
};

const sortAnnualFields = ({ tableRows, columnId, sortDirection }) => {
  const NARecords = [...tableRows].filter(
    (row) => row.annualFields.find((record) => record.column === columnId).value
      === 'n/a',
  );
  const noNARecords = [...tableRows].filter(
    (row) => row.annualFields.find((record) => record.column === columnId).value
      !== 'n/a',
  );

  let sortedTable = [...noNARecords].sort(
    (a, b) => a.annualFields.find((record) => record.column === columnId).value
      - b.annualFields.find((record) => record.column === columnId).value,
  );

  sortedTable = [...NARecords, ...sortedTable];

  sortedTable.forEach((row) => {
    if (row.childrens) {
      row.childrens = sortAnnualFields({
        tableRows: row.childrens,
        columnId,
        sortDirection,
      });
    }
  });

  return sortDirection === 'des' ? sortedTable.reverse() : sortedTable;
};

const sortTableByMeasureOrder = ({
  tableRows,
  sortDirection,
  measureTreeOrder,
}) => {
  const sortedTable = [...tableRows].sort(
    (a, b) => measureTreeOrder.indexOf(a.firstColumn.props.abb)
      - measureTreeOrder.indexOf(b.firstColumn.props.abb),
  );

  sortedTable.forEach((row) => {
    if (row.childrens) {
      row.childrens = sortTableByMeasureOrder({
        tableRows: row.childrens,
        sortDirection,
        measureTreeOrder,
      });
    }
  });

  return sortDirection === 'des' ? sortedTable : sortedTable.reverse();
};

export const sortTableDescending = ({
  columnId,
  tableRows,
  columnType,
  sortDirection,
  measureTreeOrder,
  subview,
}) => {
  if (columnType === 'record') {
    const sortedTable = sortRecordColumn({
      tableRows,
      columnId,
      sortDirection,
      subview,
    });

    return sortedTable;
  }
  if (columnType === 'firstColumn') {
    if (columnId === 'Location') {
      return sortTableByLocation({ tableRows, sortDirection });
    }
    return sortTableByMeasureOrder({
      tableRows,
      sortDirection,
      measureTreeOrder,
    });
  }
  if (columnType === 'annual') {
    if (columnId === 'classification') {
      const sortedTable = sortClassificationColumn({ tableRows, columnId });

      return sortDirection === 'asc' ? sortedTable.reverse() : sortedTable;
    }
    const sortedTable = sortAnnualFields({
      tableRows,
      columnId,
      sortDirection,
    });

    return sortedTable;
  }
};

export const columnType = (columnId) => {
  if (columnId.match(/[0-9]{4}/)) {
    return 'record';
  }
  if (columnId == 'Location' || columnId == 'Measure') {
    return 'firstColumn';
  }
  return 'annual';
};

export const getRowsToExpand = (tableRows) => {
  const rowsToExpand = [];
  tableRows.forEach((row) => {
    if (row.childrens.length > 0) {
      const hasChildrenThatNeedsToBeShown = row.childrens.some(
        (children) => children.firstColumn.props.showInContext,
      );
      if (hasChildrenThatNeedsToBeShown) {
        rowsToExpand.push(row.firstColumn.props.index);
      }
      rowsToExpand.push(...getRowsToExpand(row.childrens));
    }
  });

  return rowsToExpand;
};

export const getVisibleRows = (tableRows) => {
  const rowsToShow = [];
  tableRows.forEach((row) => {
    if (row.childrens.length > 0) {
      const hasChildrenThatNeedsToBeShown = row.childrens.some(
        (children) => children.firstColumn.props.showInContext,
      );

      const descendentsToShow = getVisibleRows(row.childrens);
      rowsToShow.push(...descendentsToShow);

      if (descendentsToShow.length || hasChildrenThatNeedsToBeShown) {
        rowsToShow.push(
          ...row.childrens.map((children) => children.firstColumn.props.index),
        );
      }
    }
  });

  return rowsToShow;
};
