import React, { useEffect } from "react";
import { SiteWideNotice, CookieConsentNotice, Navbar } from "../../reusablePartials";
import {
	Overview,
	RadarChart,
	RangeChart,
	Level1OverviewChart,
	DataTable,
	SubjectBarGraph,
	ModalRadarInformation,
	DriversOfChanges,
} from "./partials";
import { getUrlQueryParams } from "../../actions";
import { useAppPipeline } from "../../hooks";
import styles from "./styles.css";
import { useAppContext } from "../../context/AppState.jsx";
import { useModalContext } from "../../context/modalContext.jsx";
import {useTranslation} from "react-i18next";

const getSelectedLocation = () => {
	const addressWithoutFileExtenstion = window.location.pathname.split(".")[0];
	const splitedUrl = addressWithoutFileExtenstion.split("/");
	return splitedUrl[splitedUrl.length - 1];
};

export default () => {
	const [state, dispatch] = useAppContext();
	const [showModal] = useModalContext();
	const urlParams = getUrlQueryParams();
	useAppPipeline({ urlParams });


	const selectedLocation = getSelectedLocation();

	useEffect(() => {
		dispatch({
			type: "selectedLocation",
			payload: selectedLocation.toUpperCase(),
		});
		dispatch({
			type: "selectedSubject",
			payload: "GOVERNANCE",
		});
	}, [state.setUrlQueryParam]);

	const { t, i18n } = useTranslation();

	return (
		<>
			<div className="uk-grid uk-grid-small uk-grid-match">
				<div className={`uk-width-1-1`}>
					<div className={styles.backgroundWhite}>
						<Overview state={state} />
					</div>
				</div>

				<div className={`uk-width-1-2@m`}>
					<div className={styles.backgroundWhite}>
						<Level1OverviewChart />
					</div>
				</div>
				<div className={`uk-width-1-2@m`}>
					<div className={styles.backgroundWhite}>
						<span
							onClick={() => showModal(<ModalRadarInformation />)}
							className={`uk-icon-information uk-icon ${styles.infoIcon}`}
							uk-icon="info"
						/>
						<RadarChart />
					</div>
				</div>
				<div className={`uk-width-2-5@m`}>
					<div className={styles.backgroundWhite}>
						<RangeChart state={state} />
					</div>
				</div>
				<div className={`uk-width-3-5@m`}>
					<div className={styles.backgroundWhite}>
						<DataTable state={state} />
					</div>
				</div>
				<div className={`uk-width-1-1@m`}>
					<div className={styles.backgroundWhite}>
						<SubjectBarGraph state={state} />
					</div>
				</div>
				<div className={`uk-width-1-1@m`}>
					<div className={styles.backgroundWhite}>
						<DriversOfChanges state={state} dispatch={dispatch} />
					</div>
				</div>
			</div>

			<SiteWideNotice />
			<CookieConsentNotice />
		</>
	);
};
