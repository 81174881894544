import {
	calculateTrendNumbers,
	extractValuePairs,
	formatAATNumber,
	formatATNumber,
	getGroupAveragesForSubject,
	getGroupCodeFromName,
	getLevelForMeasure,
	getRank,
	getSpecificValue,
	getStyleLevelForMeasure,
	getSubjectColor,
	isACountry,
	isCitvoiceMeasure,
	numCountriesWithRank,
	possibleException,
	prettifyNumber,
	rankPercentInt,
	roundToOne,
	upDownZero,
	getSelectedSubjectName,
} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import { useAppContext } from "../../../../context/AppState.jsx";
import { OverviewSubjectSummary } from "../../../../components";
import React from "react";

const isException = (val2014, val2015, val2016, endValue2) =>
	val2014 === endValue2 || val2015 === endValue2 || val2016 === endValue2;

export default ({ subject, location, overviewStateAT, numSubjects }) => {
	const [state, dispatch] = useAppContext();
	let AATResults = {};

	let width = "uk-width-1-2@s";

	if (numSubjects >= 5) {
		width = "uk-width-1-3@m uk-width-1-2@s";
	}
	const subjectData = {};

	if (isACountry(location)) {
		let headlineTransKey = `cnm${location}`;
		// @TODO GLOBALSTRINGS translations.headline = lookupTranslationByUid(`cnm${location}`, true);
		// @TODO GLOBALSTRINGS dynamic_strings.headline = lookupWordByUid(state,`cnm${location}`, true);

		const score = getSpecificValue(state, location, subject, defaultVariables.latest_year);
		const level = getLevelForMeasure(state, subject);

		// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = earliest_year;
		// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = earliest_year;

		let startYear = defaultVariables.latest_year - 9;
		// If no data for this country in this year, find first year with data
		if (getSpecificValue(state, location, subject, startYear) < 0) {
			for (let i = 1; i < defaultVariables.default_year_array.length - 1; i++) {
				if (getSpecificValue(state, location, subject, defaultVariables.default_year_array[i]) >= 0) {
					startYear = defaultVariables.default_year_array[i];
					// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = startYear.toString();
					// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = startYear.toString();
					break;
				}
			}
		}

		let scoreRounded = roundToOne(score);
		let subjectName = getSelectedSubjectName(state, subject);

		// @TODO GLOBALSTRINGS translations[`subject_${index}`] = lookupTranslationByUid(`m${subject}`, true);
		// @TODO GLOBALSTRINGS dynamic_strings[`subject_${index}`] = lookupWordByUid(state,`m${subject}`, true);

		let startValue1 = roundToOne(
			getSpecificValue(state, location, subject, defaultVariables.default_year_array[0])
		);
		let endValue1 = roundToOne(
			getSpecificValue(
				state,
				location,
				subject,
				defaultVariables.default_year_array[defaultVariables.default_year_array.length - 1]
			)
		);
		let startValue2 = roundToOne(
			getSpecificValue(state, location, subject, defaultVariables.default_secondary_year_array[0])
		);
		let endValue2 = roundToOne(
			getSpecificValue(
				state,
				location,
				subject,
				defaultVariables.default_secondary_year_array[defaultVariables.default_secondary_year_array.length - 1]
			)
		);

		let exception = false;

		if (possibleException(endValue2)) {
			// Get defaultVariables.latest_year-3 - defaultVariables.latest_year-1 values and check for classification exception
			exception = isException(
				roundToOne(getSpecificValue(state, location, subject, defaultVariables.latest_year - 3)),
				roundToOne(getSpecificValue(state, location, subject, defaultVariables.latest_year - 2)),
				roundToOne(getSpecificValue(state, location, subject, defaultVariables.latest_year - 1)),
				endValue2
			);
		}

		let results = calculateTrendNumbers(
			startValue1,
			endValue1,
			startValue2,
			endValue2,
			defaultVariables.default_year_array,
			defaultVariables.default_secondary_year_array,
			exception
		);

		// Store classification for information overlay
		AATResults[subject] = results.classifText;

		let rank = getRank(
			state,
			subject,
			defaultVariables.latest_year,
			location,
			getGroupCodeFromName(state, "Africa")
		);

		// scoreChange = formatScoreChange(scoreChange);

		const subjectLevel = getStyleLevelForMeasure(state, subject);

		subjectData.subject = subjectName;
		subjectData.index = subject;
		subjectData.subject_color = getSubjectColor({ state, subject });
		subjectData.subject_sid = subject;
		subjectData.score = isNaN(scoreRounded) ? "n/a" : prettifyNumber(scoreRounded);
		subjectData.score_int = isNaN(scoreRounded) ? 0 : parseInt(scoreRounded, 10);
		subjectData.hide_icon = level <= 1 || (!isCitvoiceMeasure(state, subject) && level <= 2) ? "" : "uk-hidden";
		subjectData.rank = rank;
		subjectData.countries_with_rank = numCountriesWithRank(subject, defaultVariables.latest_year);
		subjectData.rank_int = rankPercentInt(rank, subjectData.countries_with_rank);
		subjectData.headlineTransKey = headlineTransKey;
		subjectData.subject_transKey = `m${subject}`;
		subjectData.subject_type = isCitvoiceMeasure(state, subject) ? "citvoice" : "regular";
		subjectData.subject_level = `level-${subjectLevel}`;
		subjectData.change_0 = formatAATNumber(results.AAT_Range_1_Rounded);
		subjectData.ch_updown = upDownZero(results.AAT_Range_1_Rounded);
		subjectData.change_1 = formatAATNumber(results.AAT_Range_2_Rounded);
		subjectData.since_year_0 = defaultVariables.default_year_array[0];
		subjectData.since_year_1 = defaultVariables.default_secondary_year_array[0];
		subjectData.width = width;
		subjectData.level = subjectLevel;

		if (overviewStateAT) {
			// Show AT info
			subjectData.hide_since = true;
			subjectData.change_at = formatATNumber(results.AT_Range_1_Rounded);
		} else {
			// Show AAT info
			subjectData.hide_since_AT = true;
			subjectData.change_aat = results.classifText;
		}

		// subjectFragments.push(SubjectSummary({ subjectData }));
	} else {
		// Group
		let headlineTransKey = location;
		const rank = false;
		// @TODO GLOBALSTRINGS translations.headline = lookupTranslationByUid(location, true);
		// @TODO GLOBALSTRINGS dynamic_strings.headline = lookupWordByUid(state,location, true);

		// var val = extractValuePairs(getGroupAveragesForSubject(location, subject));
		const level = getLevelForMeasure(subject);

		let val;
		let val2;

		val = extractValuePairs(
			getGroupAveragesForSubject(state, location, subject, [
				defaultVariables.earliest_year,
				defaultVariables.latest_year,
			])
		);
		val2 = extractValuePairs(
			getGroupAveragesForSubject(state, location, subject, [
				defaultVariables.latest_year - 4,
				defaultVariables.latest_year,
			])
		);

		let startValue1 = roundToOne(val[0].value);
		let endValue1 = roundToOne(val[1].value);
		let startValue2 = roundToOne(val2[0].value);
		let endValue2 = roundToOne(val2[1].value);

		let exception = false;

		if (possibleException(endValue2)) {
			// Get defaultVariables.latest_year-3 - defaultVariables.latest_year-1 values and check for classification exception
			const tempVal1 = extractValuePairs(
				getGroupAveragesForSubject(state, location, subject, [
					defaultVariables.latest_year - 3,
					defaultVariables.latest_year - 2,
				])
			);
			const val2014 = roundToOne(tempVal1[0].value);
			const val2015 = roundToOne(tempVal1[1].value);
			const tempVal2 = extractValuePairs(
				getGroupAveragesForSubject(state, location, subject, [
					defaultVariables.latest_year - 1,
					defaultVariables.latest_year,
				])
			);
			const val2016 = roundToOne(tempVal2[0].value);

			exception = isException(val2014, val2015, val2016, endValue2);
		}

		let results = calculateTrendNumbers(
			startValue1,
			endValue1,
			startValue2,
			endValue2,
			defaultVariables.default_year_array,
			defaultVariables.default_secondary_year_array,
			exception
		);

		// Store classification for information overlay
		AATResults[subject] = results.classifText;

		// scoreChange = roundToOne(val[1].value - val[0].value);
		let scoreRounded = roundToOne(val[1].value);
		let subjectName = getSelectedSubjectName(state, subject);

		// @TODO GLOBALSTRINGS translations[`subject_${index}`] = lookupTranslationByUid(`m${subject}`, true);
		// @TODO GLOBALSTRINGS dynamic_strings[`subject_${index}`] = lookupWordByUid(state,`m${subject}`, true);

		// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = earliest_year;
		// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = earliest_year;

		let startYear = defaultVariables.latest_year - 9;
		// If no data for this country in this year, find first year with data
		if (getSpecificValue(state, location, subject, startYear) < 0) {
			for (let i = 1; i < defaultVariables.default_year_array.length - 1; i++) {
				if (getSpecificValue(state, location, subject, defaultVariables.default_year_array[i]) >= 0) {
					startYear = defaultVariables.default_year_array[i];
					// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = startYear.toString();
					// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = startYear.toString();
					break;
				}
			}
		}

		const subjectLevel = getStyleLevelForMeasure(state, subject);

		subjectData.subject = subjectName;
		subjectData.index = subject;
		subjectData.score = isNaN(scoreRounded) ? "n/a" : prettifyNumber(scoreRounded);
		subjectData.subject_color = getSubjectColor({ state, subject });
		subjectData.subject_sid = subject;
		subjectData.score_int = parseInt(scoreRounded, 10);
		subjectData.hide_icon = level <= 1 || (!isCitvoiceMeasure(state, subject) && level <= 2) ? "" : "uk-hidden";
		subjectData.rank = rank;
		subjectData.countries_with_rank = numCountriesWithRank(subject, defaultVariables.latest_year);
		subjectData.rank_int = rankPercentInt(rank, subjectData.countries_with_rank);
		subjectData.headlineTransKey = headlineTransKey;
		subjectData.subject_transKey = `m${subject}`;
		subjectData.subject_type = isCitvoiceMeasure(state, subject) ? "citvoice" : "regular";
		subjectData.subject_level = `level-${subjectLevel}`;
		subjectData.level = subjectLevel;
		subjectData.change_0 = formatAATNumber(results.AAT_Range_1_Rounded);
		subjectData.ch_updown = upDownZero(results.AAT_Range_1_Rounded);
		subjectData.change_1 = formatAATNumber(results.AAT_Range_2_Rounded);
		subjectData.since_year_0 = defaultVariables.default_year_array[0];
		subjectData.since_year_1 = defaultVariables.default_secondary_year_array[0];
		subjectData.width = width;

		if (overviewStateAT) {
			// Show AT info
			subjectData.hide_since = true;
			subjectData.change_at = formatATNumber(results.AT_Range_1_Rounded);
		} else {
			// Show AAT info
			subjectData.hide_since_AT = true;
			subjectData.change_aat = results.classifText;
		}

		// subjectFragments.push(SubjectSummary({ subjectData }));
	}

	return <OverviewSubjectSummary subjectData={subjectData} />;
};
