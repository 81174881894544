import React, { useMemo } from "react";
import { Chartjs } from "../../../../components";
import { LoadingSpinner } from "../../../../components";
import { getChartTitle, getChartConfig } from "./utils";

import { useAppContext } from "../../../../context/AppState.jsx";
import i18n from "../../../../i18n";

export default () => {
	const [state] = useAppContext();

	if (!state?.indexedYml) {
		return <LoadingSpinner />;
	}

	const locationTitleText = useMemo(() => getChartTitle(state), [state.indexedYml, i18n.language]);

	const config = useMemo(() => getChartConfig(state, locationTitleText), [state.indexedYml, i18n.language]);

	const chartSize = useMemo(
		() => ({
			height: "640px",
			width: "100%",
		}),
		[state.indexedYml]
	);

	return <Chartjs chartConfig={config} chartId="radarChart" chartSize={chartSize} />;
};
