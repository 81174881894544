import { ChartjsTooltip } from '../../../../components';
import {
  getSelectedLocationName,
  getSelectedLocations,
  getSelectedSubjectName,
  getSelectedSubjects,
  getUrlQueryParams,
  getSubjectColor,
  getAvgScoreForGroup,
  getGroupAveragesForSubjectForGraph,
  getGroupCodeFromName,
  getSubjectTitle,
  getLocationTitle,
  isCitvoiceMeasure,
  getSpecificValue,
  roundToOne,
  isACountry,
  extractValuePairs, getSelectedYears,
} from '../../../../actions';
import { chartDataIterator } from '../RangeChart/utils';
import {regionCodes, defaultVariables, baseFont} from '../../../../referenceData';
import i18n from "../../../../i18n";

export const isMultipleSubjectSelected = (subjects) => subjects.length > 1;

const getGroupAveragesForSubjectForYearArray = (
  groupCode,
  subject,
  years,
  state,
) => {
  // Put relevant data in Processing subset
  const averages = [];
  years.forEach((year) => {
    const pos = {};
    let sc = roundToOne(getAvgScoreForGroup(state, subject, year, groupCode));
    sc = isNaN(sc) ? -1 : sc;
    pos[year] = sc;
    averages.push(pos);
  });

  return averages;
};

const getAvarageValuesForSelectedYears = (data, selectedYears) => {
  const averageValues = [];
  data.values.forEach((value) => (selectedYears.includes(value.x) ? averageValues.push(value.y) : null));
  return averageValues;
};

const getAfricanAvarageStats = (state, subject, year) => {
  const avgData = chartDataIterator(
    state,
    getGroupAveragesForSubjectForGraph(
      state,
      getGroupCodeFromName(state, 'Africa'),
      subject,
    ),
    i18n.t("African average"),
    'dashed',
    '#8e8e8e',
    false,
    false,
    10,
    1,
  );

  return getAvarageValuesForSelectedYears(avgData, year);
};

const getDataForMultipleLocations = (
  state,
  subject,
  locations,
  selectedYears,
) => {
  const data = locations.map((location) => {
    const fullLocationName = getSelectedLocationName(state, location);
    const fullSubjectName = getSelectedSubjectName(state, subject);
    const subjectColor = isCitvoiceMeasure(state, subject)
      ? '#A19FA3'
      : getSubjectColor({ state, subject });

    let scoreChangeRange1;

    if (!isACountry(location)) {
      const valTrends = extractValuePairs(
        getGroupAveragesForSubjectForYearArray(
          location,
          subject,
          selectedYears,
          state,
        ),
      );
      const first = valTrends.shift().value;
      const last = valTrends.pop().value;
      scoreChangeRange1 = first !== -1 && last !== -1
        ? roundToOne(last - first)
        : 'n/a';
    } else {
      const startValue1 = roundToOne(
        getSpecificValue(state, location, subject, selectedYears[0]),
      );
      const endValue1 = roundToOne(
        getSpecificValue(
          state,
          location,
          subject,
          selectedYears[selectedYears.length - 1],
        ),
      );

      scoreChangeRange1 = endValue1 !== -1 && startValue1 !== -1
        ? roundToOne(endValue1 - startValue1)
        : 'n/a';
    }

    if (regionCodes.includes(location)) {
      return {
        key: fullLocationName,
        value: scoreChangeRange1,
        props: {
          color: subjectColor,
          location: {
            fullName: fullLocationName,
            abr: location,
          },
          subject: {
            fullName: fullSubjectName,
            abr: subject,
          },
        },
      };
    }
    return {
      key: fullLocationName,
      value: scoreChangeRange1,
      props: {
        color: subjectColor,
        location: {
          fullName: fullLocationName,
          abr: location,
        },
        subject: {
          fullName: fullSubjectName,
          abr: subject,
        },
      },
    };
  });

  return data;
};

const getDataForMultipleSubjects = (
  state,
  subjects,
  location,
  selectedYears,
) => {
  const data = subjects.map((subject) => {
    const fullSubjectName = getSelectedSubjectName(state, subject);
    const fullLocationname = getSelectedLocationName(state, location);

    let scoreChangeRange1;

    if (!isACountry(location)) {
      const valTrends = extractValuePairs(
        getGroupAveragesForSubjectForYearArray(
          location,
          subject,
          selectedYears,
          state,
        ),
      );
      const first = valTrends.shift().value;
      const last = valTrends.pop().value;
      scoreChangeRange1 = first !== -1 && last !== -1
        ? roundToOne(last - first)
        : 'n/a';
    } else {
      const startValue1 = roundToOne(
        getSpecificValue(state, location, subject, selectedYears[0]),
      );
      const endValue1 = roundToOne(
        getSpecificValue(
          state,
          location,
          subject,
          selectedYears[selectedYears.length - 1],
        ),
      );

      scoreChangeRange1 = endValue1 !== -1 && startValue1 !== -1
        ? roundToOne(endValue1 - startValue1)
        : 'n/a';
    }

    if (regionCodes.includes(location)) {
      return {
        key: fullSubjectName,
        value: scoreChangeRange1,
        props: {
          color: isCitvoiceMeasure(state, subject)
            ? '#A19FA3'
            : getSubjectColor({ state, subject }),
          location: {
            fullName: fullLocationname,
            abr: location,
          },
          subject: {
            fullName: fullSubjectName,
            abr: subject,
          },
        },
      };
    }
    return {
      key: fullSubjectName,
      value: scoreChangeRange1,
      props: {
        color: isCitvoiceMeasure(state, subject)
          ? '#A19FA3'
          : getSubjectColor({ state, subject }),
        location: {
          fullName: fullLocationname,
          abr: location,
        },
        subject: {
          fullName: fullSubjectName,
          abr: subject,
        },
      },
    };
  });
  return data;
};

const getDatasetsForChart = (state, subjects, locations, selectedYears) => {
  if (isMultipleSubjectSelected(subjects)) {
    return getDataForMultipleSubjects(
      state,
      subjects,
      locations[0],
      selectedYears,
    );
  }
  return getDataForMultipleLocations(
    state,
    subjects[0],
    locations,
    selectedYears,
  );
};

const getDataForTooltip = (state, subjects, locations) => {
  const body = {};
  const footer = [];

  if (isMultipleSubjectSelected(subjects)) {
    const location = getSelectedLocationName(state, locations[0]);
    subjects.forEach((subject) => {
      footer.push(
        i18n.t("African average for {{country}}", { country: getSelectedSubjectName(state, subject)}),
      );
      body[getSelectedSubjectName(state, subject)] = {
        abr: subject,
        label: `${getSelectedSubjectName(state, subject)}: ${location}`, // eslint-disable-line i18next/no-literal-string
      };
    });
  } else {
    footer.push(i18n.t('African average'));
    locations.forEach((location) => {
      body[getSelectedLocationName(state, location)] = {
        abr: subjects[0],
        label: `${getSelectedSubjectName(// eslint-disable-line i18next/no-literal-string
          state,
          subjects[0],
        )}: ${getSelectedLocationName(state, location)}`,
      };
    });
  }

  return {
    body,
    footer,
  };
};

export const getChartTitle = (state, urlParams) => {
  const years = getSelectedYears('range1from', 'range1to');
  const chartTitle = `${i18n.t("{{subject}} for {{location}}", {// eslint-disable-line i18next/no-literal-string
    subject: getSubjectTitle(state),
    location: getLocationTitle(state)
  })} (${years[0]}-${years[years.length - 1]})`;

  return chartTitle;
};

const sortData = (chartData, urlParams, measureTreeOrder) => {
  const { sortBy, sortDir, meas } = urlParams;
  let sortedData;
  const sortDesc = typeof sortDir === 'undefined' || sortDir === 'des';

  if (typeof sortBy === 'undefined' || sortBy === 'score') {
    sortedData = [...chartData].sort(
      (a, b) => {
        if (b.value === 'n/a') {
          return -1;
        } else if (a.value === 'n/a') {
          return 1;
        } else {
          return sortDesc ? Number(b.value) - Number(a.value)
            : Number(a.value) - Number(b.value);
        }
      },
    );
    // we return here; this version has to keep the n/a values at the end regardless of sort order
    // so the sort func handles dir, rather than reversing post sort
    return sortedData;
  }

  if (
    (typeof sortBy !== 'undefined' && sortBy === 'index')
    || (sortBy === 'name' && isMultipleSubjectSelected(meas.split('-')))
  ) {
    sortedData = [...chartData]
      .sort(
        (a, b) => measureTreeOrder.indexOf(a.props.subject.abr)
          - measureTreeOrder.indexOf(b.props.subject.abr),
      )
      .reverse();
  }

  if (typeof sortBy !== 'undefined' && sortBy === 'name') {
    sortedData = [...chartData].sort((a, b) => a.key.localeCompare(b.key));
  }


  return sortDesc
    ? sortedData.reverse()
    : sortedData;
};

const createBarChartDatasets = (
  state,
  isMultipleSubjectsSelected,
  data,
  year,
) => {
  if (isMultipleSubjectsSelected) {
    return {
      labels: data.map((subject) => subject.key),
      datasets: [
        {
          label: `${data[0].props.location.fullName} ${Number(
            year[0],
          )}-${Number(year[year.length - 1])}`,
          backgroundColor: data.map((subject) => subject.props.color),
          hoverBackgroundColor: data.map((subject) => subject.props.color),
          data: data.map((subject) => {
            if (subject.value === '-1.0') {
              return NaN;
            }
            return subject.value;
          }),
          maxBarThickness: 150,
          tooltip: {
            order: 1,
          },
        },
      ],
    };
  }
  return {
    labels: data.map((location) => location.key),
    datasets: [
      {
        label: data[0].props.subject.fullName,
        backgroundColor: `${data[0].props.color}`,
        hoverBackgroundColor: `${data[0].props.color}`,
        data: data.map((location) => {
          if (location.value === '-1.0') {
            return NaN;
          }
          return location.value;
        }),
        maxBarThickness: 150,
        selectedYear: `${year[0]}-${year[year.length - 1]}`,
        tooltip: {
          order: 1,
        },
      },
    ],
  };
};

export const getChartConfig = (state) => {
  const urlParams = getUrlQueryParams();
  const subjects = getSelectedSubjects(state);
  const locations = getSelectedLocations(state);
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const tooltipData = getDataForTooltip(state, subjects, locations);
  const { measureTreeOrder } = state;

  const data = getDatasetsForChart(state, subjects, locations, selectedYears);

  const sortedData = sortData(data, urlParams, measureTreeOrder);

  const fixedNAData = sortedData.map((el) => {
    el.value = el.value == 'n/a' ?  null : el.value;
    return el;
  });

  const chartData = createBarChartDatasets(
    state,
    isMultipleSubjectSelected(subjects),
    fixedNAData,
    selectedYears,
  );

  const indexAxis = window.innerWidth < 768 ? 'y' : 'x';
  const otherAxis = indexAxis === 'y' ? 'x' : 'y';

  return {
    type: 'bar',
    data: chartData,
    options: {
      interaction: {
        intersect: false,
        mode: window.innerWidth > 768 ? 'index' : indexAxis,
        includeInvisible: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 10,
          right: 10,
          bottom: 35,
          top: 30,
        },
      },
      indexAxis,
      scales: {
        [indexAxis]: {
          title: {
            display: locations.length >= 40 || subjects.length >= 40,
            text: subjects.length === 1 ? i18n.t('Locations').toUpperCase() : i18n.t('Measures').toUpperCase(),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 90,
            callback(val) {
              if (this.ticks.length >= 40) {
                return;
              }
              return this.getLabelForValue(val);
            },
            display: true,
            autoSkip: false,
            barThickness: 30,
            font: {
              size: 12,
              family: baseFont(),
              weight: 500,
            },
            color: 'black',
          },
        },
        [otherAxis]: {
          title: {
            display: true,
            text: i18n.t('Change').toUpperCase(),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          suggestedMin: -1,
          suggestedMax: 1,
          ticks: {
            stepSize: 1,
            maxTicksLimit: 7,
            callback: (val) => (val<=0?"":"+") + Number(val).toFixed(1),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          grid: {
            color: (context) => { return context.tick.value == 0 ? '#000000' : '#C3C3C3' },
            lineWidth: (context) => { return context.tick.value == 0 ? 1 : 1 },
          }
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              const legend = chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                if (meta.index === 1) {
                  style.borderDash = [3, 3];
                  style.pointStyle = 'line';
                }
                if (
                  (isMultipleSubjectSelected(subjects)
                    && meta.type === 'line')
                  || meta.type === 'bar'
                ) {
                  return null;
                }

                return {
                  text:
                    meta.type === 'bar'
                      ? i18n.t('Range Of Scores')
                      : datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden:
                    isMultipleSubjectSelected(subjects) && meta.type === 'line'
                      ? false
                      : !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0,
                  datasetIndex: meta.index,
                };
              }, this);

              return legend.filter((item) => item !== null);
            },
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => ChartjsTooltip({
            state,
            context,
            tooltipData,
            hideComparison: true,
            activeDatasets: [],
            chartName: 'absoluteTrendBarChart',
            isMultiSubjectView: isMultipleSubjectSelected(subjects),
            alwaysSign: true,
          }),
        },
      },
    },
  };
};
