import React from "react";
import { TableLegend } from "../../../../components";
import i18n from "../../../../i18n";
export default () => {
	const legend = [
		{
			class: "positiveChange",
			label: i18n.t("Improvement is significant at 90% level"),
		},
		{
			class: "negativeChange",
			label: i18n.t("Deterioration is significant at 90% level"),
		},
	];

	return <TableLegend legend={legend} />;
};
