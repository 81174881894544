import React from "react";
import Layout from "./Layout.jsx";
import { AppProvider } from "../../context/AppState.jsx";
import useAppState from "../../hooks/useAppState.jsx";
import { ModalProvider } from "../../context/modalContext.jsx";

export default () => {
	const [state, dispatch] = useAppState();
	return (
		<AppProvider value={[state, dispatch]}>
			<ModalProvider>
				<Layout />
			</ModalProvider>
		</AppProvider>
	);
};
