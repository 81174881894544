import { urls } from '../../referenceData';

export default async () => {
  try {
    const { strings: res } = await fetch(
      `${urls.apiUrl()}most/?format=json`,// eslint-disable-line i18next/no-literal-string
    ).then((response) => response.json());

    return res;
  } catch (err) {
    console.log(err);
  }
};
