import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({ data }) => {
	const showIcon = () => data.level <= 1 || (!data.citvoice && data.level <= 2);
	const {t} = useTranslation();
	return (
		<div
			className={`uk-panel uk-panel-box-secondary uk-text-center uk-text-middle overview-single-childitem level-${data.style_level}`}
		>
			<div>
				<h4 className="subject-name">{data.subject_name}</h4>
				<div
					className={`percentage-circle-small ${data.citvoice ? "citvoice" : "regular"} level-${
						data.style_level
					}`}
					style={{ "--color": data.subject_color, "--score": Math.max(0, data.score_rounded) }}
				>
					<svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<circle
							className="circle-back"
							r="45%"
							cx="50%"
							cy="50%"
							fill="transparent"
							pathLength="100"
						></circle>
						<circle
							className="circle-front"
							r="45%"
							cx="50%"
							cy="50%"
							fill="transparent"
							pathLength="100"
						></circle>
					</svg>
					{data.citvoice && data.level + 1 > 2 ? (
						<span className={styles.innerCircle}></span>
					) : (
						<span className="">
							<span className={`${showIcon() ? "" : "uk-hidden"}`}>
								<img
									id="second_row_icon_1"
									src={`/img/icons/${data.subject}.svg`}
									className="measure-icon-image"
								/>
							</span>
						</span>
					)}
				</div>
				<span className="overview_small_score uk-width-1-1">{data.change}</span>
				<h4 className=" uk-width-1-1 overview-body-copy" template-mif-string-id="22">
					{t("Out of")} 100.0
				</h4>
			</div>
		</div>
	);
};
