import React from 'react';
import {
  getAppSelectedLanguage,
  getSelectedLocations,
  getSelectedSubjects,
  getUrlQueryParams,
} from '../../../../actions';
import { OverviewLimitRachedPlaceholder } from '..';
import {
  createMultiLocationSingleSubjectOverview,
  createSingleLocationMultiSubjectOverview,
  createSingleLocationSingleSubjectOverviewData,
} from './utils';
import {
  OverviewMultiLocationSingleSubject,
  OverviewSingleLocationMultiSubject,
  OverviewSingleLocationSingleSubject,
} from '../../../../components';
import {useTranslation} from "react-i18next";

const isTooManySelections = (selectedLocations, selectedSubjects) => (selectedLocations.length > 1 && selectedSubjects.length > 1)
  || selectedSubjects.length > 12
  || selectedLocations.length > 8;

export default ({ state, isTakingScreenshot }) => {
  const selectedLocations = getSelectedLocations(state);
  const selectedSubjects = getSelectedSubjects(state);
  const { subview } = getUrlQueryParams();
  const { t, i18n } = useTranslation();

  if (isTooManySelections(selectedLocations, selectedSubjects)) {
    return <OverviewLimitRachedPlaceholder />;
  }

  if (selectedLocations.length === 1 && selectedSubjects.length === 1) {
    return (
      <OverviewSingleLocationSingleSubject
        locationData={createSingleLocationSingleSubjectOverviewData({
          state,
          selectedLocations,
          selectedSubjects,
          subview,
          isTakingScreenshot,
        })}
      />
    );
  }
  if (selectedLocations.length === 1) {
    return (
      <OverviewSingleLocationMultiSubject
        locationData={createSingleLocationMultiSubjectOverview({
          state,
          selectedLocations,
          selectedSubjects,
          subview,
          isTakingScreenshot,
        })}
      />
    );
  }
  if (selectedSubjects.length === 1) {
    return (
      <OverviewMultiLocationSingleSubject
        subjectData={createMultiLocationSingleSubjectOverview({
          state,
          selectedLocations,
          selectedSubjects,
          subview,
          isTakingScreenshot,
        })}
      />
    );
  }
  return <OverviewLimitRachedPlaceholder />;
};
