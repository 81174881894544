// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir=ltr] .infoIcon--s0Hcc {\n    margin-left: 5px;\n}[dir=rtl] .infoIcon--s0Hcc {\n    margin-right: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"infoIcon": "infoIcon--s0Hcc"
};
module.exports = exports;
