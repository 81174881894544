const IdpEvent = function () {
  const self = this;

  self.queue = {};
  self.everyQueue = {};
  self.fired = [];

  return {
    fire(event) {

      if (self.queue[event] !== undefined) {
        while (self.queue[event].length) {
          queue.shift()();
        }
      }

      if (self.everyQueue[event] !== undefined) {
        // loop over everyQueue[event] and call each function
        for (let i = 0; i < self.everyQueue[event].length; i++) {
            self.everyQueue[event][i]();
        }
      }

    },

    on(event, callback) {

      if (typeof self.queue[event] === 'undefined') {
        self.queue[event] = [];
      }

      self.queue[event].push(callback);
    },


    every(event, callback) {

      if (typeof self.everyQueue[event] === 'undefined') {
        self.everyQueue[event] = [];
      }

      self.everyQueue[event].push(callback);
    },
  };
};

export const idpevent = new IdpEvent();
