import { ChartjsTooltip } from '../../../../components';
import {
  wordWrap,
  getAverageValue,
  getSelectedSubjectName,
  getSelectedLocationName,
  getSubjectColor,
} from '../../../../actions';
import {baseFont, defaultVariables} from '../../../../referenceData';
import i18n from '../../../../i18n';

const getDataForTooltip = (selectedLocations, selectedSubject) => {
  const body = {};
  const footer = ['African average'];
  selectedLocations.forEach((location) => {
    body[location] = {
      abr: selectedSubject,
      label: i18n.t("{{subject}} for {{location}}:", { subject: selectedSubject, location: location}),
    };
  });

  return {
    body,
    footer,
  };
};

export const getChartTitle = (state) => getSelectedSubjectName(state, state.selectedSubject);

export const getChartConfig = (state, chartTitle) => {
  const lastYear = defaultVariables.latest_year;
  const measureValues = Object.values(state.measureData[lastYear]).map((e) => Number(e.v).toFixed(1));
  const averageValues = Array(measureValues.length).fill(
    getAverageValue(state.indexedYml[lastYear][state.selectedSubject]),
  );
  const labelOffsetValue = window.innerWidth > 768 ? -8 : 0;
  const wrap = window.innerWidth < 400 ? 20 : 30;
  const fontSize = window.innerWidth < 400 ? 9 : 12;

  const barChartLabels = Object.values(state.measureData[lastYear]).map((e) => getSelectedLocationName(state, e.iso));
  const wrappedBarDataLabels = wordWrap(barChartLabels, wrap);
  const barTextTitle = window.innerWidth < 480
    ? [chartTitle, "- " + i18n.t("Score/Rank {{year}}", {year: lastYear })]
    : [`${chartTitle} - ${i18n.t("Score/Rank {{year}}", {year: lastYear })}`]; // eslint-disable-line i18next/no-literal-string

  const tooltipData = getDataForTooltip(
    wrappedBarDataLabels,
    subject_detailed_info,
  );

  const barData = {
    labels: wrappedBarDataLabels,
    datasets: [
      {
        label: chartTitle,
        backgroundColor: getSubjectColor({
          state,
          subject: state.selectedSubject,
        }),
        borderWidth: 0,
        borderColor: 'transparent',
        color: getSubjectColor({
          state,
          subject: state.selectedSubject,
        }),
        lightColor: `${getSubjectColor({
          state,
          subject: state.selectedSubject,
        })}33`,
        data: measureValues,
        tooltip: {
          order: 1,
        },
      },
      {
        label: i18n.t('African average'),
        type: 'line',
        borderColor: '#9C9C9C',
        borderDash: [3, 3],
        pointRadius: 0,
        borderWidth: 1,
        data: averageValues,
        pointStyle: 'none',
        tooltip: {
          order: 11,
          divider: true,
        },
      },
    ],
  };

  const indexAxis = window.innerWidth < 960 ? 'y' : 'x';
  const otherAxis = indexAxis === 'y' ? 'x' : 'y';

  return {
    type: 'bar',
    data: barData,
    options: {
      clicked: {},
      indexAxis,
      responsive: true,
      maintainAspectRatio: window.innerWidth >= 960,
      interaction: {
        mode: 'index',
        intersect: false,
        axis: indexAxis,
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 30,
        },
      },
      scales: {
        [indexAxis]: {
          ticks: {
            autoSkip: false,
            // maxRotation: 90,
            // minRotation: 90,
            labelOffset: labelOffsetValue,
            barThickness: 30,
            font: {
              size: fontSize,
              lineHeight: 0.9,
            }
          },
        },
        [otherAxis]: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (e) => e.toFixed(1),
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: barTextTitle,
          font: {
            size: 16,
            family: baseFont(),
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 30,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              // this method is a copy + edit of the one from core chartjs.
              // chartjs updates may need it copy/pasted/rededited.
              // reference https://github.com/chartjs/Chart.js/blob/v3.3.2/src/plugins/plugin.legend.js#L651
              // just added the overrides on if / if/else to allow variation per dataset
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              return chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                if (meta.index === 1) {
                  style.borderDash = [3, 3];
                  style.pointStyle = 'line';
                }

                return {
                  text: datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0, // TODO: v4, default to style.borderRadius
                  // Below is extra data used for toggling the datasets
                  datasetIndex: meta.index,
                };
              }, this);
            },
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ChartjsTooltip({
              state,
              context,
              tooltipData,
              hideComparison: true,
              activeDatasets: [],
            });
          },
        },
      },
      onClick: (e, evtpoints, chart) => {
        const points = chart.getElementsAtEventForMode(
          e,
          'nearest',
          { intersect: true },
          true,
        );

        if (points.length > 0) {
          const point = points[0];
          chart.config.data.datasets.map((dataset, datasetIndex) => {
            // dataset needs a lightColor + color
            if (!dataset.lightColor || !dataset.color) {
              return;
            }

            // empty color array...
            const colors = new Array(dataset.data.length);

            // and if we are not already active...
            if (chart.config.options.clicked[datasetIndex] !== point.index) {
              colors.fill(dataset.lightColor);
              colors[point.index] = dataset.color;
              chart.config.options.clicked[datasetIndex] = point.index;
            } else {
              // deactivate if active
              colors.fill(dataset.color);
              delete chart.config.options.clicked[datasetIndex];
            }
            dataset.backgroundColor = colors;
          });
        } else {
          // or if we've clicked elsewhere, reset - similar to else clause above
          Object.keys(chart.config.options.clicked).map((e) => {
            if (
              !chart.config.data.datasets[e].lightColor
              || !chart.config.data.datasets[e].color
            ) {
              return;
            }
            const colors = new Array(
              chart.config.data.datasets[e].data.length,
            ).fill(chart.config.data.datasets[e].color);
            chart.config.data.datasets[e].backgroundColor = colors;
          });
          chart.config.options.clicked = {};
        }

        chart.update();
      },
    },
  };
};
