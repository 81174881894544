import React from "react";
import i18n from "../../../../i18n";
import {TableFooterLegend} from "../../../../components/index.js";
import {setUrlQueryParam} from "../../../../actions";
import {Trans} from "react-i18next";
export default ({dispatch, filters}) => {

	const legend = {
		items: [
			{
				class: "lowest",
				label: i18n.t("Lowest Scores"),
				checked: filters.showLowest,
				onclick: (e) => {
					// dispatch({
					// 	type: "setDataTableFilters",
					// 	payload: setUrlQueryParam({name: "showLowest", value: !filters.showLowest}),
					// });
				},
			},
			{
				class: "highest",
				label: i18n.t("Highest Scores"),
				checked: filters.showHighest,
				onclick: (e) => {
					// dispatch({
					// 	type: "setDataTableFilters",
					// 	payload: setUrlQueryParam({name: "showHighest", value: !filters.showHighest}),
					// });
				},
			}
		]
	}

	if (filters.subview === 'score' && filters.showAAT === 2) {
			legend.items.push(
				{
					class: "estimated",
					label: i18n.t("Estimated"),
					checked: filters.showEstimated,
					onclick: (e) => {
						// dispatch({
						// 	type: "setDataTableFilters",
						// 	payload: setUrlQueryParam({ name: "showEstimated", value: !filters.showEstimated }),
						// });
					},
					info: {
						title: i18n.t("Estimated"),
						description: <Trans>This represents data points which are missing at source and have been estimated by the Foundation using one of the estimation methods outlined in the <a href="/methodology.html">IIAG methodology</a>.</Trans>,
					},
				},
				{
					class: "trimmed",
					label: i18n.t("Trimmed"),
					checked: filters.showTrimmed,
					onclick: (e) => {
						// dispatch({
						// 	type: "setDataTableFilters",
						// 	payload: setUrlQueryParam({ name: "showTrimmed", value: !filters.showTrimmed }),
						// });
					},
					info: {
						title: i18n.t("Trimmed"),
						description: <Trans>This represents data points which have been treated for outliers so as to not skew the overall distribution of the data as outlined in the <a href="/methodology.html">IIAG methodology</a>.</Trans>,
					},
				},
				{
					class: "trimmed-estimated",
					label: i18n.t("Trimmed and estimated"),
					checked: filters.showTrimmedEstimated,
					onclick: (e) => {
						// dispatch({
						// 	type: "setDataTableFilters",
						// 	payload: setUrlQueryParam({ name: "showTrimmedEstimated", value: !filters.showTrimmedEstimated }),
						// });
					},
					info: {
						title: i18n.t("Trimmed and estimated"),
						description: <Trans>This represents data points which have been estimated using the information in available raw data points from source that have first been trimmed (treated for outliers). Trimming and estimation methods used by the Foundation are  outlined in the <a href="/methodology.html">IIAG methodology</a>.</Trans>,
					},
				}
			);
	}

	return <TableFooterLegend legend={legend} />;
};
