import { ScreenshotAndPdfFrame } from '../../components';
import getUrlQueryParams from '../getUrlQueryParams';
import getSubjectTitle from '../getSubjectTitle';
import getLocationTitle from '../getLocationTitle';
import i18n from "../../i18n";

export default (activeView, subview) => {
  // notifyError(
  //   "<div id='progress_message' class='uk-active'><h2>Adjusting the display and taking a screenshot.</h2><p>Please wait a few moments.</p></div>",
  //   0,
  // );

  const currentYear = new Date().getFullYear();
  const { range1to } = getUrlQueryParams();
  let titleText = i18n.t("{{subject}} for {{location}}", {
    subject: getSubjectTitle(),
    location: getLocationTitle()
  });
  let dataPresenter = '';
  let shouldMakeAClone = true;

  switch (activeView) {
    case 'graph':
      if (subview === 'score') {
        dataPresenter = document.getElementById('chart');
        shouldMakeAClone = false;
        break;
      }
      dataPresenter = document.getElementById('pnl_Graph');
      break;
    case 'overview':
      dataPresenter = document.getElementById('pnl_Overview');
      shouldMakeAClone = false;
      titleText += ` (${global.latest_year})`;// eslint-disable-line i18next/no-literal-string
      break;
    case 'table':
      dataPresenter = document.getElementById('pnl_Table');

      shouldMakeAClone = false;
      break;
    case 'bar':
      dataPresenter = document.getElementById('pnl_Bar');
      break;
    case 'map':
      dataPresenter = document.getElementById('pnl_Trends');
      titleText += range1to ? ` (${range1to})` : ` (${global.latest_year})`;// eslint-disable-line i18next/no-literal-string
      shouldMakeAClone = false;
      break;
    default:
    // notifyError(
    //   "<div id='progress_message' class='uk-active'><p><br />There was a problem. Your view could not be taken.</p></div>",
    //   3000
    // );
  }

  global.$('.measure-icon-image').addClass('uk-hidden');

  global.$('.uk-icon-information').addClass('uk-hidden');
  global.$('#chart_title').addClass('uk-hidden');
  global.$('#table_main_heading').addClass('uk-hidden');
  global.$('#headerTrends').addClass('uk-hidden');

  ScreenshotAndPdfFrame({
    titleText,
    currentYear,
    dataPresenter,
    shouldMakeAClone,
  });
};
