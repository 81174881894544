import { defaultVariables } from '../../referenceData';

const getStandardFooterAndBody = ({
  tooltip,
  tooltipData,
  hideComparison,
  activeDatasets,
  context,
}) => {
  const footer = [];
  const body = [];

  tooltip.dataPoints.forEach((stat) => {
    if (tooltipData.footer.includes(stat.dataset.label)) {
      if (!hideComparison || (hideComparison && !stat.dataset.isException)) {
        return footer.push(stat);
      }
      if (hideComparison && stat.dataset.label.includes(stat.label)) {
        return footer.push(stat);
      }
    }
    if (
      activeDatasets.length === 0
      || activeDatasets.includes(stat.dataset.label)
    ) {
      if (hideComparison) {
        const label = `${context.tooltip.body[0].lines[0].split(':')[0]}`;
        const value = `${context.tooltip.body[0].lines[0].split(':')[1]}`;
        const raw = context.tooltip.dataPoints[0].raw == null ? 'n/a' : context.tooltip.dataPoints[0].raw;
        if (
          !body.some((item) => item.label === label && item.value === value)
        ) {
          return body.push(
            ...context.tooltip.title.map((title) => ({
              abr: tooltipData.body[title].abr,
              label,
              value,
              raw,
            })),
          );
        }

        return;
      }
      if (Array.isArray(stat.dataset.label)) {
        return body.push(
          ...stat.dataset.label.map((subject, index) => ({
            abr: tooltipData.body[subject].abr,
            label: `${tooltipData.body[subject].label}`,
            value: `${Number(stat.dataset.data[index]).toFixed(1)}`,
            raw: stat.raw,
          })),
        );
      }

      return body.push({
        abr: tooltipData.body[stat.dataset.label].abr,
        label: `${tooltipData.body[stat.dataset.label].label}`,
        value: `${Number(stat.formattedValue).toFixed(1)}`,
        raw: stat.raw,
      });
    }
  });
  return {
    footer,
    body,
    title: tooltip.title,
  };
};

const getDataPageBarChart = ({
  tooltip,
  tooltipData,
  hideComparison,
  activeDatasets,
  context,
  isMultiSubjectView,
}) => {
  const footer = [];
  const body = [];
  let title = '';

  if (isMultiSubjectView) {
    tooltip.dataPoints.forEach((stat) => {
      title = stat.dataset.label;
      body.push({
        abr: tooltipData.body[stat.label].abr,
        label: stat.label,
        raw: tooltip.dataPoints[0].raw,
        value:
          tooltip.dataPoints[0].raw === '-1.0'
            ? 'n/a'
            : tooltip.dataPoints[0].raw,
      });
    });

    return {
      footer,
      body,
      title,
    };
  }
  tooltip.dataPoints.forEach((stat) => {
    if (tooltipData.footer.includes(stat.dataset.label)) {
      if (!hideComparison || (hideComparison && !stat.dataset.isException)) {
        return footer.push(stat);
      }
    }
    if (
      activeDatasets.length === 0
      || activeDatasets.includes(stat.dataset.label)
    ) {
      if (hideComparison) {
        const label = `${context.tooltip.body[0].lines[0].split(':')[0]}`;
        const value = `${context.tooltip.body[0].lines[0].split(':')[1]}`;
        const raw = `${context.tooltip.dataPoints[0].raw == null ? 'n/a' : context.tooltip.dataPoints[0].raw}`;
        title = `${tooltip.title}${
          Number(stat.dataset.selectedYear) !== defaultVariables.latest_year
            ? ` (${stat.dataset.selectedYear})`// eslint-disable-line i18next/no-literal-string
            : ''
        }`;

        if (
          !body.some((item) => item.label === label && item.value === value)
        ) {
          return body.push(
            ...context.tooltip.title.map((title) => ({
              abr: tooltipData.body[title].abr,
              label,
              value,
              raw,
            })),
          );
        }
      }
    }
  });
  return {
    footer,
    body,
    title,
  };
};

const getAbsoluteTrendBarChart = ({
  tooltip,
  tooltipData,
  hideComparison,
  activeDatasets,
  context,
  isMultiSubjectView,
}) => {
  const footer = [];
  const body = [];
  let title = '';

  if (isMultiSubjectView) {
    tooltip.dataPoints.forEach((stat) => {
      title = stat.dataset.label;
      body.push({
        abr: tooltipData.body[stat.label].abr,
        label: stat.label,
        raw: (tooltip.dataPoints[0].raw === null) ? 'n/a' : tooltip.dataPoints[0].raw,
        value:
          tooltip.dataPoints[0].raw === '-1.0'
            ? 'n/a'
            : tooltip.dataPoints[0].raw,
      });
    });

    return {
      footer,
      body,
      title,
    };
  }
  tooltip.dataPoints.forEach((stat) => {
    if (tooltipData.footer.includes(stat.dataset.label)) {
      if (!hideComparison || (hideComparison && !stat.dataset.isException)) {
        return footer.push(stat);
      }
    }
    if (
      activeDatasets.length === 0
      || activeDatasets.includes(stat.dataset.label)
    ) {
      if (hideComparison) {
        const label = `${context.tooltip.body[0].lines[0].split(':')[0]}`;
        const value = `${context.tooltip.body[0].lines[0].split(':')[1]}`;
        const raw = `${context.tooltip.dataPoints[0].raw == null ? 'n/a' : context.tooltip.dataPoints[0].raw}`;
        title = `${tooltip.title}${
          Number(stat.dataset.selectedYear) !== defaultVariables.latest_year
            ? ` (${stat.dataset.selectedYear})`// eslint-disable-line i18next/no-literal-string
            : ''
        }`;

        if (
          !body.some((item) => item.label === label && item.value === value)
        ) {
          return body.push(
            ...context.tooltip.title.map((title) => ({
              abr: tooltipData.body[title].abr,
              label,
              value,
              raw
            })),
          );
        }
      }
    }
  });
  return {
    footer,
    body,
    title,
  };
};

export default ({
  chartName,
  tooltip,
  tooltipData,
  hideComparison,
  activeDatasets,
  context,
  isMultiSubjectView,
}) => {
  switch (chartName) {
    case 'dataPageBarChart': {
      return getDataPageBarChart({
        tooltip,
        tooltipData,
        hideComparison,
        activeDatasets,
        context,
        isMultiSubjectView,
      });
    }
    case 'absoluteTrendBarChart': {
      return getAbsoluteTrendBarChart({
        tooltip,
        tooltipData,
        hideComparison,
        activeDatasets,
        context,
        isMultiSubjectView,
      });
    }
    default: {
      return getStandardFooterAndBody({
        tooltip,
        tooltipData,
        hideComparison,
        activeDatasets,
        context,
        isMultiSubjectView,
      });
    }
  }
};
