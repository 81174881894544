const langMap = {
  'en' : 1,
  'fr' : 2,
  'ar' : 3,
  'pt' : 4,
};
export default () => {
  const selectedLanguage = window.localStorage && window.localStorage.getItem('i18nextLng');

  if (!selectedLanguage) {
    return langMap['en'];
  }

  return langMap[selectedLanguage];
};
