import i18n from "../../i18n";

export default (level) => {
  switch (level) {
    case 5:
      return i18n.t('Sub-Sub-Indicator');
    case 4:
      return i18n.t('Sub-Indicator');
    case 3:
      return i18n.t('Indicator');
    case 2:
      return i18n.t('Sub-Category');
    case 1:
      return i18n.t('Category');
    default:
      return i18n.t('Measure');
  }
};
