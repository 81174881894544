import getUrlQueryParams from '../getUrlQueryParams';
import compactSubjectsForUrl from '../compactSubjectForUrl';
import setUrlQueryParam from '../setUrlQueryParam';
import removeRedundantUrlParams from '../removeRedundantUrlParams';
import removeUrlQueryParam from '../removeUrlQueryParam';

const allowedParams = {
  overview: {
    absoluteTrends: [],
    annualAvgTrend: [],
    default: 'absoluteTrends',
  },
  graph: {
    score: ['range1from', 'range1to', 'filter'],
    annualAvgTrend: ['range1from', 'range1to', 'range2from', 'range2to'],
    default: 'score',
  },
  bar: {
    score: ['range1to'],
    trend: ['range1to'],
    default: 'score',
  },
  table: {
    score: [
      'range1from',
      'range1to',
      'range2from',
      'range2to',
      'showAAT',
      'showLowest',
      'showHighest',
      'showHighlights',
      'sortBy',
      'asc',
    ],
    rank: [
      'range1from',
      'range1to',
      'showLowest',
      'showHighest',
      'showHighlights',
      'sortBy',
      'asc',
    ],
    default: 'score',
  },
  map: {
    score: ['range1to'],
    trend: ['highlightedTrend'],
    default: 'score',
  },
};

export default ({ selectedSubjects, selectedLocations }) => {
  const urlParams = getUrlQueryParams();

  if (selectedSubjects.length > 0) {
    const urlSubjects = compactSubjectsForUrl(selectedSubjects);
    setUrlQueryParam({ name: 'meas', value: urlSubjects.join('-') });
  } else {
    removeUrlQueryParam({ name: 'meas' });
  }

  if (selectedLocations.length > 0) {
    setUrlQueryParam({ name: 'loc', value: selectedLocations.join('-') });
  } else {
    removeUrlQueryParam({ name: 'loc' });
  }

  if (typeof urlParams.view !== 'undefined') {
    setUrlQueryParam({ name: 'view', value: urlParams.view });
    const activeSubview = urlParams.subview || allowedParams[urlParams.view].default;
    removeRedundantUrlParams({
      allowedParams: allowedParams[urlParams.view][activeSubview],
    });
  }

  window.history.pushState({}, window.location.href, window.location.href);

  global.gtmPageView();
};
