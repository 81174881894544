// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal--3Yi5G {\n    position: absolute;\n    top: 370px;\n    width: 445px;\n    z-index: 9;\n}[dir=ltr] .modal--3Yi5G {\n    right: 31px;\n}[dir=rtl] .modal--3Yi5G {\n    left: 31px;\n}\n\n.modalContainer--ZpCnD {\n    position: relative;\n}\n\n[dir] .modalContainer--ZpCnD {\n    padding: 20px;\n}\n\n.modalHeadline--sxSY7 {\n    font-size: 26px;\n}\n\n[dir] .modalCountryName--F5OWL {\n    margin-bottom: 16px !important;\n}\n\n[dir] .modalPercentageCircle--358FF {\n    margin: 15px auto;\n}\n\n.flagIcon--keW6N {\n    width: 40px;\n}\n\n[dir=ltr] .flagIcon--keW6N {\n    margin-left: 5px;\n}\n\n[dir=rtl] .flagIcon--keW6N {\n    margin-right: 5px;\n}\n\n.modalFlagIcon--29hPh {\n    vertical-align: top;\n    min-width: 30px;\n    max-width: 30px;\n}\n\n.closeButtonWrapper--3LQB7 {\n    position: absolute;\n}\n\n[dir=ltr] .closeButtonWrapper--3LQB7 {\n    right: 0;\n}\n\n[dir=rtl] .closeButtonWrapper--3LQB7 {\n    left: 0;\n}\n\n", ""]);
// Exports
exports.locals = {
	"modal": "modal--3Yi5G",
	"modalContainer": "modalContainer--ZpCnD",
	"modalHeadline": "modalHeadline--sxSY7",
	"modalCountryName": "modalCountryName--F5OWL",
	"modalPercentageCircle": "modalPercentageCircle--358FF",
	"flagIcon": "flagIcon--keW6N",
	"modalFlagIcon": "modalFlagIcon--29hPh",
	"closeButtonWrapper": "closeButtonWrapper--3LQB7"
};
module.exports = exports;
