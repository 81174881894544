// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tabChildrenFrame--1fwm8 {\n    /*top: 47px;*/\n    position: relative;\n}\n", ""]);
// Exports
exports.locals = {
	"tabChildrenFrame": "tabChildrenFrame--1fwm8"
};
module.exports = exports;
