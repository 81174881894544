import React, { useMemo } from "react";
import {
	prettifyNumber,
	getSubjectColor,
	getSelectedSubjectName,
	getSelectedLocationName,
	roundToOne,
	getTopLevelParent,
	getLevelForMeasure,
	getStyleLevelForMeasure, scoreFormat, scoreChangeFormat,
	isCitvoiceMeasure
} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import {LoadingSpinner, InfoPagesDataTable, CorrelationNotice} from "../../../../components";
import i18n from "../../../../i18n";
import Statistics from "statistics.js";
import measure from "d3-geo/src/path/measure";


const calculateCorrelation = (year, measure1, measure2, state) => {
	const data = state.indexedYml[year];
	const measure1Data = Object.values(data[measure1]);
	const measure2Data = Object.values(data[measure2]);

	if (!measure1Data || !measure2Data) {
		return;
	}

	const statsData = measure1Data.map((el, i) => {
		if (!measure2Data[i]) {
			return;
		}
		return { x: el.v, y: measure2Data[i].v };
	}).filter(e=>e);

	const stats = new Statistics(statsData, {x: 'metric', y: 'metric'});
	const correlation = stats.linearRegression('x', 'y');

	return correlation.correlationCoefficient;

}

const subjectHasIcon = (mid, state) => {
	var parent = getTopLevelParent(state, mid);
	if (parent.s_id == "CITVOICE") {
		return getLevelForMeasure(state, mid) < 2;
	}
	return getLevelForMeasure(state, mid) < 3;
};

const getCellClass = (value) => {
	if (value === "n/a" || Number(value) === 0) {
		return;
	}
	if (value > 0) {
		return "positive";
	}
	if (value < 0) {
		return "negative";
	}
};

const getChildMeasures = (mid, state) => {
	return state.fullListOfMeasures.filter((measure) => measure.parent === mid);
}

const getMeasuresForLevels = (levels, state) => {
	return state.fullListOfMeasures.filter((measure) => levels.includes(measure.lvl) && !isCitvoiceMeasure(state, measure.s_id))
}

const createRows = (measures, state) => {
	return measures.map((row) => {
		const hasIcon = subjectHasIcon(row.s_id, state);
		const r2 = calculateCorrelation(defaultVariables.latest_year, state.selectedSubject, row.s_id, state);
		return {
			sortValue: r2,
			props: {
				class: `level-${getStyleLevelForMeasure(state, row.s_id)}`,
				subjectColor: getSubjectColor({ state, subject: row.s_id }),
				icon: hasIcon ? `/img/icons/${row.s_id}.svg` : null,
			},
			columns: [
				{
					props: {
						hasIcon,
						class: "",
						styles: hasIcon
							? { "--ttt-icon": `url("/img/icons/${row.s_id}.svg")` }
							: { "--ttt-color": getSubjectColor({ state, subject: row.s_id }) },
					},
					value: getSelectedSubjectName(state, row.s_id),
				},
				{
					props: { class: getCellClass(roundToOne(row.change).toFixed(1)) },
					value: r2.toFixed(2),
				},
			],
		};
	});
};

export default ({ state }) => {
	if (!state?.measureData) {
		return <LoadingSpinner />;
	}
	const measureData = { ...state.measureData };

	const lastYear = defaultVariables.latest_year;

	const categories = getMeasuresForLevels([0,1], state);
	const subcategories = getMeasuresForLevels([2], state);
	const indicators = getMeasuresForLevels([3], state);

	const categoriesData = createRows(categories, state);
	categoriesData.sort((a, b) => b.sortValue - a.sortValue);

	const subcategoriesData = createRows(subcategories, state);
	subcategoriesData.sort((a, b) => b.sortValue - a.sortValue);

	const indicatorsData = createRows(indicators, state).sort((a, b) => b.sortValue - a.sortValue).slice(0, 10)


	const tableData = useMemo(
		() => [
			{
				name: "categories",
				text: i18n.t("Overall Governance & Categories"),
				tables: [
					{
						name: "categoriesData",
						title: i18n.t("Categories"),
						titleInTable: true,
						rows: categoriesData,
					}
				],
			},
			{
				name: "subcategories",
				text: i18n.t("Subcategories"),
				tables: [
					{
						name: "subcategoriesData",
						title: i18n.t("Subcategories"),
						titleInTable: true,
						rows: subcategoriesData,
					}
				],
			},
			{
				name: "indicators",
				text: i18n.t("Top 10 Indicators"),
				tables: [
					{
						name: "indicatorsData",
						title: i18n.t("Top 10 Indicators"),
						titleInTable: true,
						rows: indicatorsData,
					}
				],
			},
		],
		[state.shouldShowAllMeasures, i18n.language, state.measureData]
	);


	const valueHeadings = [
		[  i18n.t("correlation table title") ]
	];

	const title = i18n.t("{{subject}} - Correlation", { subject: getSelectedSubjectName(state, state.selectedSubject) });
	const titleModal = <CorrelationNotice />;

	return <InfoPagesDataTable title={title} titleModal={titleModal} tableData={tableData} valueHeadings={valueHeadings} type="subject" tableClass="correlation" />;
};
