import React from "react";
import styles from "./styles.css";
import { useModalContext } from "../../../context/modalContext.jsx";

export default ({ title, description, secondTitle, secondDescription }) => {
	const [_, closeModal] = useModalContext();

	return (
		<div className="uk-modal uk-open" style={{ display: "block" }}>
			<div className={`uk-modal-body uk-modal-dialog ${styles.modalWrapper}`}>
				<button
					className="uk-modal-close-default uk-close-large uk-icon uk-close"
					uk-icon="icon:close"
					type="button"
					onClick={() => closeModal()}
				></button>
				<h2 className=" no_top_margin" id="lbl_location_info_modal_header" >
					{title}
				</h2>
				<hr />
				<div className="uk-grid modal_info_grid uk-margin-bottom">
					{Array.isArray(description) ? (
						description.map((el) => <p className={`uk-width-1-1 ${styles.description}`}>{el}</p>)
					) : (
						<p className="uk-width-1-1">{description}</p>
					)}
				</div>

				{ secondTitle && (
					<>
						<h3 className=" no_top_margin" id="" >
							{secondTitle}
						</h3>
						<hr />
						<div className="uk-grid modal_info_grid uk-margin-bottom">
							{Array.isArray(secondDescription) ? (
								secondDescription.map((el) => <p className={`uk-width-1-1 ${styles.description}`}>{el}</p>)
							) : (
								<p className="uk-width-1-1">{secondDescription}</p>
							)}
						</div>

					</>
				)}

			</div>
		</div>
	);
};
