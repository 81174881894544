import React from 'react';
import { useModalContext } from '../../context/modalContext.jsx';
import { ClassificationInfo } from '../Modals';
import styles from './styles.css';
import {useTranslation} from "react-i18next";
import {defaultVariables} from "../../referenceData";
import {scoreChangeFormat} from "../../actions";

export default ({ subjectData }) => {
  const [showModal] = useModalContext();
  const { t } = useTranslation();

  return (
    <div
      id={`column_${subjectData.index}`}
      className={`${subjectData.width} uk-panel uk-panel-box-secondary uk-text-center overview-multi-measure-measure ${subjectData.subject_level} ${subjectData.subject_type} `}
    >
      <div className="">
        <h4
          className="subject-name"
          placeholder-mif-translation-key={`${subjectData.subject_transKey} `}
        >
          {subjectData.subject}
        </h4>

        <div
          className={`percentage-circle ${subjectData.subject_type} ${subjectData.subject_level} `}
          style={{
            '--color': subjectData.subject_color,
            '--score': Math.max(0,subjectData.score_int),
          }}
        >
          <svg
            viewBox="0 0 100 100"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="circle-back"
              r="45%"
              cx="50%"
              cy="50%"
              fill="transparent"
              pathLength="100"
            ></circle>
            <circle
              className="circle-front"
              r="45%"
              cx="50%"
              cy="50%"
              fill="transparent"
              pathLength="100"
            ></circle>
          </svg>
          {subjectData.level <= 2 ? (
            <span className="">
              <span className={`${subjectData.hide_icon} `}>
                <object
                  className="measure-icon-image"
                  data={`img/icons/${subjectData.subject_sid}.svg`}
                  type="image/jpeg"
                ></object>
              </span>
            </span>
          ) : null}
          {subjectData.subject_type === 'citvoice' ? (
            <span className={styles.innerCircle}></span>
          ) : null}
        </div>

        <span className="overview_score uk-width-1-1">{subjectData.score}</span>
        <span
          className="overview-small-text uk-width-1-1 top_margin_10"
          template-mif-string-id="22"
        >
          { t ("Out of 100.0")}
        </span>
        <div
          className="uk-flex uk-flex-wrap uk-flex-center uk-margin-top dont-wrap-on-large-screens uk-grid-divider uk-grid-small"
          uk-grid="{}"
        >
          <div className="uk-width-1-2  measure-trend">
            <span
              className="overview-bold-title top_margin_10 bottom_margin_10"
              template-mif-string-id="24"
            >
              {subjectData.change_aat ? t("Trend") : t("Change") }
            </span>
            <span
              className={`trend-icon-small ${
                subjectData.hide_since_AT ? 'uk-hidden' : ''
              }`}
              style={{ '--color': subjectData.subject_color }}
            >
              <img src={`/img/icons/trend_${subjectData.ch_updown}.svg`} />
            </span>

            <span
              className={`trend-icon-aat-small ${
                subjectData.hide_since ? 'uk-hidden' : ''
              }`}
            >
              <img src={`/img/icons/trend_aat_${subjectData.ch_updown}.svg`} />
            </span>

            {subjectData.change_aat ? (
              subjectData.change_aat.split(' ').map((label, index) => (
                <div className={'overview-bold-title uk-width-1-1'}>
                  {label}
                  {subjectData.change_aat.split(' ').length - 1 === index ? (
                    <a
                      onClick={() => showModal(
                          <ClassificationInfo title={subjectData.change_aat} />,
                      )
                      }
                      className={`primary-button__classification-info ${
                        subjectData.hide_since ? 'uk-hidden' : ''
                      } ${styles.infoIcon}`}
                    >
                      <i
                        id={`btnClassificationInfo_${subjectData.index} `}
                        className="information_icon uk-icon-information"
                        uk-icon="icon:info; ratio:1"
                      ></i>
                    </a>
                  ) : null}
                </div>
              ))
            ) : (
              <span
                className={`overview_small_score uk-width-1-1 ${
                  subjectData.hide_since_AT ? 'uk-hidden' : ''
                }`}
              >
                {subjectData.change_at}
              </span>
            )}
            <span
              className={`overview-small-text top_margin_10 ${
                subjectData.hide_since ? 'uk-hidden' : ''
              }`}
              id="txtSince"
            >
              {scoreChangeFormat(subjectData.change_0, true)}{' '}
              <span template-mif-string-id="114">{ t ("AAT since {{year}}", { year: defaultVariables.earliest_year }) }</span>
              <br />
              {scoreChangeFormat(subjectData.change_1, true)}{' '}
              <span template-mif-string-id="115">
                { t("AAT since {{year}}",  {year: subjectData.since_year_1}) }
              </span>
            </span>

            <span
              className={`overview-small-text top_margin_10 ${
                subjectData.hide_since_AT ? 'uk-hidden' : ''
              } `}
              id="txtSinceAT"
            >
              <span template-mif-string-id="196">
                { t ("Change since {{year}}", { year: defaultVariables.earliest_year }) }
              </span>
              <br />
            </span>
          </div>

          {subjectData.rank ? (
            <div
              className={`measure-rank uk-width-1-2 uk-width-1-2@l ${subjectData.hideRank}`}
            >
              <span
                className="overview-bold-title top_margin_10 bottom_margin_10"
                template-mif-string-id="71"
              >
                { t ("Ranking")}
              </span>
              <div
                className={`percentage-circle-small  ${subjectData.subject_type} `}
                style={{
                  '--color': subjectData.subject_color,
                  '--score': Math.max(0,subjectData.rank_int),
                }}
              >
                <svg
                  viewBox="0 0 100 100"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    className="circle-back"
                    r="45%"
                    cx="50%"
                    cy="50%"
                    fill="transparent"
                    pathLength="100"
                  ></circle>
                  <circle
                    className="circle-front"
                    r="45%"
                    cx="50%"
                    cy="50%"
                    fill="transparent"
                    pathLength="100"
                  ></circle>
                </svg>
                <span className=""></span>
              </div>
              <span className="overview_medium_score uk-width-1-1">
                {subjectData.rank}
              </span>
              <span className="overview-small-text top_margin_5">
                <span template-mif-string-id="26">{ t("Out of") }</span>&nbsp;
                {subjectData.countries_with_rank}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
