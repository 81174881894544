import { default as getCountriesInGroup } from '../getCountriesInGroup';
import { default as getSpecificValue } from '../getSpecificValue';
import { default as roundToOne } from '../roundToOne';

export default (state, measure, year, groupCode) => {
  const countries = getCountriesInGroup(state, groupCode);
  let min;

  // Exception for ARTProv, which has no data for a defined period
  min = measure == 'ARTProv' && year >= 2000 && year <= 2009 ? -1 : 100;

  countries.forEach((object) => {
    const v = roundToOne(getSpecificValue(state, object.iso, measure, year));
    if (v >= 0)
    // Ignore -1, because it means no score was recorded
    { min = v < min ? v : min; }
  });
  return min;
};
