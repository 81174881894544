// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".legendWrapper--18Dvl {\n    display: flex;\n    font-family: museo-sans;\n    justify-content: center;\n}[dir] .legendWrapper--18Dvl {\n    margin: 20px auto !important;\n}\n\n[dir=ltr] .legendTitle--2VcG0 {\n    margin-right: 15px;\n}\n\n[dir=rtl] .legendTitle--2VcG0 {\n    margin-left: 15px;\n}\n\n.itemWrapper--3RFAB {\n    display: flex;\n}\n\n[dir] .itemWrapper--3RFAB {\n    margin: 0 10px;\n}\n\n.indicator--wDI4f {\n    width: 20px;\n    height: 20px;\n}\n\n[dir=ltr] .indicator--wDI4f {\n    margin-right: 5px;\n}\n\n[dir=rtl] .indicator--wDI4f {\n    margin-left: 5px;\n}\n\n[dir] .lowest--2uXOz {\n    background-color: #df1258;\n}\n\n[dir] .highest--2FnwH {\n    background-color: #d1df12;\n}\n\n[dir] .highlights--2RuL4 {\n    background-color: rgba(199, 252, 255, 1);\n}\n\n[dir] .trimmed--3Me3n {\n    border: rgb(172, 172, 172) 3px solid;\n}\n\n[dir] .estimated--3u7yz {\n    border: rgb(166,158,76) 3px solid;\n}\n\n[dir] .trimmed-estimated--ytySj {\n    border: rgb(29,90,119) 3px solid;\n}\n\n.strikeout--3KSom {\n    text-decoration: line-through;\n}\n\n.infoIcon--3_g6v {\n    width: 17px;\n    height: 17px;\n}\n\n[dir] .infoIcon--3_g6v {\n    margin-top: 2px;\n}\n\n[dir=ltr] .infoIcon--3_g6v {\n    margin-left: 5px;\n}\n\n[dir=rtl] .infoIcon--3_g6v {\n    margin-right: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"legendWrapper": "legendWrapper--18Dvl",
	"legendTitle": "legendTitle--2VcG0",
	"itemWrapper": "itemWrapper--3RFAB",
	"indicator": "indicator--wDI4f",
	"lowest": "lowest--2uXOz",
	"highest": "highest--2FnwH",
	"highlights": "highlights--2RuL4",
	"trimmed": "trimmed--3Me3n",
	"estimated": "estimated--3u7yz",
	"trimmed-estimated": "trimmed-estimated--ytySj",
	"strikeout": "strikeout--3KSom",
	"infoIcon": "infoIcon--3_g6v"
};
module.exports = exports;
