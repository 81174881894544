import getSelectedLocations from '../getSelectedLocations';
import getSelectedLocationName from '../getSelectedLocationName';
import i18n from "../../i18n";
import getSelectedSubjectName from "../getSelectedSubjectName";

export default (state) => {
  const selectedLocations = getSelectedLocations(state);
  if (selectedLocations.length === 0) {
    return i18n.t('No locations selected');
  }

  const selectedLocationFullNames = selectedLocations.map((location) => getSelectedLocationName(state, location));

  selectedLocationFullNames.sort((a, b) => a.localeCompare(b));

  if (selectedLocationFullNames.length === 1) {
    return selectedLocationFullNames[0];
  } else {
      return i18n.t("{{location}}, and {{other_count}} other", {
        location: selectedLocationFullNames[0],
        other_count: selectedLocationFullNames.length - 1,
        count: selectedLocationFullNames.length - 1
      });

  }

};
