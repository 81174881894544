import { default as getSubjectLevel } from '../getSubjectLevel';
import { default as isCitvoiceMeasure } from '../isCitvoiceMeasure';

export default (state, subject) => {
  let level = getSubjectLevel(state, subject);
  if (isCitvoiceMeasure(state, subject)) {
    level += 1;
  }
  return level;
};
