import getUrlQueryParams from '../getUrlQueryParams';

export default (state) => {
  const locations = getUrlQueryParams()
    ?.loc?.split('-')
    ?.filter((location) => location !== '')
    ?.filter((location) => {
      return !state.fullListOfCountries || state.fullListOfCountries.find((country) => country.iso === location)
      ||
      !state.fullListOfCountryGroups || state.fullListOfCountryGroups.find((group) => group.uid === location)
    }) || [];


  return locations;
};
