import { default as getCountriesInGroup } from '../getCountriesInGroup';
import { default as getSpecificValue } from '../getSpecificValue';
import { default as roundToOne } from '../roundToOne';

export default (state, measure, year, groupCode) => {
  const countries = getCountriesInGroup(state, groupCode);
  let countryTotal = countries.length;

  let total = 0;
  countries.forEach((country) => {
    // Special case to exclude South Sudan when necessary
    if (country.iso == 'SS' && year < 2011) {
      countryTotal -= 1;
    } else {
      const v = roundToOne(getSpecificValue(state, country.iso, measure, year));
      if (isNaN(v) || v == -1) {
        countryTotal -= 1;
      } else {
        total += v;
      }
    }
  });
  // Fix for rounding issue
  total += 0.000000000006; // 0.0000000000006;

  return total / countryTotal;
};
