import prettifyNumber from '../prettifyNumber';
import getChangeSummary from '../getChangeSummary';

export default (scoreChange) => {
  if (!isNaN(scoreChange)) {
    switch (getChangeSummary(scoreChange)) {
      case 'increase':
        return `+${prettifyNumber(scoreChange.toString())}`;
      case 'decrease':
        return scoreChange == -1 ? '-1.0' : prettifyNumber(scoreChange);
      default:
        return '0.0';
    }
  } else {
    return 'n/a';
  }
};
