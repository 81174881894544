import React from "react";
import {getUrlQueryParams, isSelectionInvalid} from "../../../../actions";
import {
	setUrlQueryParam,
	setUrlQueryParamForSubview,
	removeUrlQueryParam,
	setDefaultUrlQueryParamsForSubview,
} from "../../../../actions";
import {CorrelationNotice, SettingsHeader} from "../../../../components";
import { ShareModal } from "../";
import { useAppContext } from "../../../../context/AppState.jsx";
import { useModalContext } from "../../../../context/modalContext.jsx";
import i18n from "../../../../i18n";
import {remove} from "uikit/src/js/util";

const radioButtonSets = (view, state, urlParams) => {

	return ({
		comparison: [
			{ text: "Score", queryParam: "score" },
			{ text: "Rank", queryParam: "rank" },
		],
		overview: [
			{ text: i18n.t('Change'), queryParam: "absoluteTrends" },
			{ text: i18n.t("Annual average trend"), queryParam: "annualAvgTrend" },
		],
		graph: [
			{
				text: i18n.t("Score"),
				queryParam: "score",
				defaultOption: "line",
			},
			{
				text: i18n.t("Change"),
				queryParam: "absoluteTrend",
				defaultOption: "bar",
			},
			{
				text: i18n.t("Annual average trend"),
				queryParam: "annualAvgTrend",
				defaultOption: "scatter"
			},
			{
				text: i18n.t("Correlation"),
				queryParam: "correlation",
				defaultOption: "scatter",
				infoContent: <CorrelationNotice />,
				invalid: isSelectionInvalid(state, urlParams, { subview: "correlation" }),
			},
		],
		table: [
			{ text:  i18n.t("Score"), queryParam: "score" },
			{ text:  i18n.t("Rank"), queryParam: "rank" },
			// { text: i18n.t("Drivers of Change"), queryParam: "drivers" },
			// { text: i18n.t("Significant Changes"), queryParam: "changes" },
		],
		map: [
			{ text:  i18n.t("Score"), queryParam: "score" },
			{ text:  i18n.t("Trend"), queryParam: "trend" },
		],
	})[view];
}


const subviewOptions = (view) => ({
	graph: [
		{ text:  i18n.t("Line graph"), icon: "line_graph.svg", queryParam: "line", subview: ["score", "correlation"] },
		{ text:  i18n.t("Bar graph"), icon: "bar_graph.svg", queryParam: "bar", subview: ["score", "absoluteTrend"] },
		{ text:  i18n.t("Scatter graph"), icon: "scatter_graph.svg", queryParam: "scatter", subview: ["annualAvgTrend", "correlation"] },
	],
})[view];

const hasTooManySelectedParams = (meas, loc) =>
	meas.split("-").filter((item) => item !== "").length > 1 && loc.split("-").filter((item) => item !== "").length > 1;

export default ({ hideShareButton, isEmbed }) => {
	const [state, dispatch] = useAppContext();
	const [showModal] = useModalContext();
	const urlParams = getUrlQueryParams();
	let { view, subview, loc, meas, option } = getUrlQueryParams();

	if (state.disableSettingsHeader || !view) {
		return null;
	}
	
	if (!subview) {
		setUrlQueryParamForSubview(view);
		subview = getUrlQueryParams().subview;
	}

	const showAdvancedSettings = state.showAdvancedSettings;

	const advancedSettingsButton = {
		shouldShow: view === "overview" || (view === "map" && subview === "trend"),
		onClick: () => {
			dispatch({
				type: "showAdvancedSettings",
				payload: !showAdvancedSettings,
			});
		},
	};

	const radioButtonOnChange = (queryParam) => {
		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "subview", value: queryParam }),
		});
		if (view === "graph")
		switch (queryParam) {
			case "score":
				dispatch({
					type: "setUrlQueryParam",
					payload: [ setUrlQueryParam({ name: "option", value: "line" }), removeUrlQueryParam({ name: "ext" })],
				});
				break;

			case "absoluteTrend":
				dispatch({
					type: "setUrlQueryParam",
					payload: [ setUrlQueryParam({ name: "option", value: "bar" }), removeUrlQueryParam({ name: "ext" })],
				});
				break;

			case "annualAvgTrend":
				dispatch({
					type: "setUrlQueryParam",
					payload: [ setUrlQueryParam({ name: "option", value: "scatter" }), removeUrlQueryParam({ name: "ext" })],
				});
				break;

			case "correlation":
				dispatch({
					type: "setUrlQueryParam",
					payload: setUrlQueryParam({ name: "option", value: "scatter" }),
				});
				break;

			default:
				dispatch({
					type: "setUrlQueryParam",
					payload: [removeUrlQueryParam({ name: "option" }), removeUrlQueryParam({ name: "ext" })],
				});
				break;
		}
	};

	const optionOnChange = (queryParam) => {
		dispatch({
			type: "showAdvancedSettings",
			payload: false,
		});
		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "option", value: queryParam }),
		});
	};

	return (
		<SettingsHeader
			radioButtons={radioButtonSets(view, state, urlParams)}
			radioButtonOnChange={radioButtonOnChange}
			advancedSettingsButton={advancedSettingsButton}
			showAdvancedSettings={showAdvancedSettings}
			shoudlHideShareButton={hideShareButton}
			activeSubview={subview}
			callback={() => showModal(<ShareModal />)}
			isEmbed={isEmbed}
			optionOnChange={optionOnChange}
			subviewOptions={subviewOptions(view) || []}
			activeOption={getUrlQueryParams()?.option ? option : null}
		/>
	);
};
