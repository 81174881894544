export default async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register(
        `${window.location.origin}/initialAppDataServiceWorker.js`,// eslint-disable-line i18next/no-literal-string
      );
    } catch (error) {
      console.error(`Worker registration failed with ${error}`);// eslint-disable-line i18next/no-literal-string
    }
  }
};
