import React from "react";

export default ({ checkboxes }) => {
	return (
		<div className="idp-footer__highlight advanced-settings">
			<div className="uk-width-1-2@s">
				{checkboxes.map((checkbox, index) => (
					<label
						key={`${checkbox.text.split(" ").join("")}_${index}`}
						className={`reduced-size-label clickable_pointer`}
					>
						<input
							type="checkbox"
							onChange={(e) => {
								checkbox.onChange(e.target.checked);
							}}
							checked={checkbox.checked}
						/>
						{checkbox.text}
					</label>
				))}
			</div>
			<div className="uk-width-1-2@s"></div>
		</div>
	);
};
