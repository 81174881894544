// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".screenshotPdfFooter--1sJOO {\n    height: 120px;\n    color: black;\n    display: flex;\n    position: relative;\n}[dir] .screenshotPdfFooter--1sJOO {\n    background: #f5f5f5;\n}\n\n.screenshotPdfFooterCopyright--23Pp5 {\n    line-height: 120px;\n    color: #343c42;\n    width: 50%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n[dir=ltr] .screenshotPdfFooterCopyright--23Pp5 {\n    padding-right: 30px;\n}\n\n[dir=rtl] .screenshotPdfFooterCopyright--23Pp5 {\n    padding-left: 30px;\n}\n\n.screenshotPdfFooterTextWrapper--HmW7j {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 100%;\n}\n\n[dir] .screenshotPdfFooterTextWrapper--HmW7j {\n    padding: 0 15px;\n}\n\n[dir=ltr] .screenshotPdfFooterTextWrapper--HmW7j {\n    left: 0;\n}\n\n[dir=rtl] .screenshotPdfFooterTextWrapper--HmW7j {\n    right: 0;\n}\n\n.screenshotPdfFooterText--TZFQJ {\n    display: inline-block;\n    color: #343c42;\n}\n\n[dir] .screenshotPdfFooterText--TZFQJ {\n    margin: 0;\n}\n\n[dir] .screenshotPdfFooterDevider--37Q_m {\n    margin: 0 5px;\n}\n\n.screenshotPdfFooterLink--1aY6F {\n    display: inline-block;\n    color: #113e86;\n}\n\n.screenshotPdfFooterLogoWrapper--3yAdQ {\n    display: flex;\n    align-items: center;\n    width: 50%;\n    justify-content: flex-start;\n}\n\n[dir=ltr] .screenshotPdfFooterLogoWrapper--3yAdQ {\n    padding-left: 30px;\n}\n\n[dir=rtl] .screenshotPdfFooterLogoWrapper--3yAdQ {\n    padding-right: 30px;\n}\n\n.screenshotPdfFooterLogo--phUdG {\n    width: 175px;\n    height: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"screenshotPdfFooter": "screenshotPdfFooter--1sJOO",
	"screenshotPdfFooterCopyright": "screenshotPdfFooterCopyright--23Pp5",
	"screenshotPdfFooterTextWrapper": "screenshotPdfFooterTextWrapper--HmW7j",
	"screenshotPdfFooterText": "screenshotPdfFooterText--TZFQJ",
	"screenshotPdfFooterDevider": "screenshotPdfFooterDevider--37Q_m",
	"screenshotPdfFooterLink": "screenshotPdfFooterLink--1aY6F",
	"screenshotPdfFooterLogoWrapper": "screenshotPdfFooterLogoWrapper--3yAdQ",
	"screenshotPdfFooterLogo": "screenshotPdfFooterLogo--phUdG"
};
module.exports = exports;
