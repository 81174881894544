export default async function (url, options = {}, retries = 3, delay = 1000) {
	for (let i = 0; i < retries; i++) {
		try {
			const res = await fetch(url, options);
			if (!res.ok) {
				throw new Error(`Fetch error: ${res.status}`);
			}
			return res; // If successful, return the response
		} catch (err) {
			console.error(`Attempt ${i + 1} failed: ${err.message}`);
			if (i < retries - 1) {
				await new Promise(resolve => setTimeout(resolve, delay)); // Wait before retrying
			} else {
				throw err; // If retries are exhausted, throw the error
			}
		}
	}
}
