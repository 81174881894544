import React from 'react';
import { OverviewMultiLocationSingleSubjectInnerLocation } from '../../pages/Data/partials';

export default ({ subjectData }) => (
  <div className=" overview-main overview-multi-location">
    <div
      className={`uk-margin-top uk-margin-bottom uk-grid-medium uk-flex-center ${subjectData.child_width} `}
      id="pnl_sub_overview"
      uk-grid="{}"
      uk-height-match=".country-name"
    >
      {subjectData.locations.map((e) => (
        <OverviewMultiLocationSingleSubjectInnerLocation
          location={e}
          key={`overview_multi_location_single_subject_inner_location_${e}`}
          subject={subjectData.subject}
          isTakingScreenshot = {subjectData.isTakingScreenshot}
        />
      ))}
    </div>
  </div>
);
