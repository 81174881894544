import React from "react";
import styles from "../../styles.css";
import subIndicatorsStyles from "./styles.css";
import { default as SubSubIndicators } from "../SubSubIndicators/index.jsx";

import { isSearchedPhrase } from "../../utils";
import indicatorsStyles from "../Indicators/styles.css";

export default ({
	subIndicators,
	indicatorId,
	nestedListsToShow,
	showNestedLists,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	isSimplifiedView,
	subjectParent,
	queryParams,
	searchPhrase,
	onSubjectChange,
}) => {
	return (
	<ul className={`${styles.subIndicatorsList} ${!nestedListsToShow.includes(indicatorId) ? "uk-hidden" : ""}`}>
		{subIndicators.map((subIndicator, index) => {

			let disableExpandButton = false;

			let subjectWithNoChildren = false;


			let subjectsGroup = [];

			const getSubjectChildrens = (childrens) => {
				childrens.forEach((child) => {
					if (child.childrens) {
						getSubjectChildrens(child.childrens);
					}
					subjectsGroup.push(child.fullName);
				});
			};

			subIndicator.childrens ? getSubjectChildrens(subIndicator.childrens) : (subjectWithNoChildren = true);


			subjectsGroup.forEach((children) => {
				if (
					isSearchedPhrase({
						subjectName: children,
						phrase: searchPhrase,
					})
				) {
					disableExpandButton = true;
				}
			});

			return (
				<li
					key={`${subIndicator.fullName.split(" ").join("")}_${index}`}
					className={`${subIndicatorsStyles.subIndicatorItem} ${
						!isSimplifiedView && selectedSubjects.includes(subIndicator.id)
							? `${subIndicatorsStyles.subIndicatorItemSelected} ${subjectParent}`
							: ""
					}`}
				>
					<a
						onClick={(e) => {
							if (isSimplifiedView && onSubjectChange) {
								e.preventDefault();
								onSubjectChange(subIndicator.id);
							}
							if (!isSimplifiedView) {
								e.preventDefault();
								onSubjectSelect(subIndicator.id);
							}
						}}
						href={`/measures/${subIndicator.id.toLowerCase()}.html`}
						className={`${
							isSearchedPhrase({
								subjectName: subIndicator.fullName,
								phrase: searchPhrase,
							})
								? styles.highlightSearchedWord
								: ""
						} ${
							!isSimplifiedView && selectedSubjects.includes(subIndicator.id)
								? `${subIndicatorsStyles.selected} ${subjectParent}`
								: ""
						} ${subIndicatorsStyles.subjectName}`}
						style={
							!isSimplifiedView && selectedSubjects.includes(subIndicator.id)
								? {
										background: `rgba(${subjectColor}, 0.25)`,
										border: `1px solid rgb(${subjectColor})`,
								  }
								: null
						}
					>
						{subIndicator.fullName}
					</a>

					{!subjectWithNoChildren ? (
						<span>
						{!nestedListsToShow.includes(subIndicator.id) ? (
							<span
								className={`${subIndicatorsStyles.appMenuLevel4Expand}`}
								uk-icon="icon:plus; ratio: 0.75"
								onClick={() => {
									showNestedLists((displayedRows) => [...displayedRows, subIndicator.id]);
								}}
							></span>
						) : (
							<span
								className={`${subIndicatorsStyles.appMenuLevel4Expand} ${
									disableExpandButton ? indicatorsStyles.disabled : ""
								}`}
								uk-icon="icon:minus; ratio: 0.75"
								onClick={() => {
									if (disableExpandButton) {
										return;
									}
									showNestedLists((displayedRows) =>
										displayedRows.filter((item) => item !== subIndicator.id)
									);
								}}
							></span>
						)}
					</span>
					) : null}

					{subIndicator?.childrens ? (
						<SubSubIndicators
							subSubIndicators={subIndicator?.childrens}
							subIndicatorId={subIndicator.id}
							nestedListsToShow={nestedListsToShow}
							showNestedLists={showNestedLists}
							selectedSubjects={selectedSubjects}
							onSubjectSelect={onSubjectSelect}
							subjectColor={subjectColor}
							isSimplifiedView={isSimplifiedView}
							subjectParent={subjectParent}
							queryParams={queryParams}
							searchPhrase={searchPhrase}
							onSubjectChange={onSubjectChange}
						/>
					) : null}

				</li>
			);
		})}
	</ul>
)};
