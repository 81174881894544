export default (state, iso, measure, year) => {
  const { indexedYml } = state;

  if (
    indexedYml[year]
    && indexedYml[year][measure]
    && indexedYml[year][measure][iso]
  ) {
    return indexedYml[year][measure][iso].v;
  }
  return 'error';
};
