import React, { useMemo } from 'react';
import { Chartjs } from '../../../../components';
import { getChartConfig, getChartTitle } from './utils';
import { setDefaultUrlQueryParamsForSubview } from '../../../../actions';

export default ({ state, urlParams, isTakingScreenshot }) => {
  if (!state?.indexedYml) {
    return null;
  }

  if (
    typeof urlParams.range1from === 'undefined'
    || typeof urlParams.range1to === 'undefined'
  ) {
    setDefaultUrlQueryParamsForSubview(urlParams);
  }

  const chartConfig = useMemo(
    () => getChartConfig(state),
    [state.setUrlQueryParam, i18n.language],
  );

  const chartTitle = useMemo(
    () => getChartTitle(state, urlParams),
    [state.setUrlQueryParam, i18n.language],
  );

  const chartId = isTakingScreenshot ? 'screenshotBarChart' : 'bar-chart';

  const isOnMobile = window.innerWidth < 768;

  const chartSize = useMemo(
    () => ({
      height: isOnMobile ? '1200px' : '637px',
    }),
    [],
  );

  return (
    <Chartjs
      chartTitle={chartTitle}
      chartConfig={chartConfig}
      chartId={chartId}
      chartSize={chartSize}
      isTakingScreenshot={isTakingScreenshot}
    />
  );
};
