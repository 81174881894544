export const isAllChildrensSelected = (childrens, selectedSubjects) => {
  const isAllSubjectSelected = childrens.every((child) => {
    if (!selectedSubjects.includes(child)) {
      return false;
    }
    return true;
  });

  return isAllSubjectSelected;
};

export const isSearchedPhrase = ({ subjectName, phrase }) => (!!(phrase?.length > 2
  && subjectName.toLowerCase().includes(phrase?.toLowerCase())));
