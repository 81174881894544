// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dropdown--1MNgj {\n    position: relative;\n    width: auto;\n    color: black;\n    text-transform: uppercase;\n}[dir] .dropdown--1MNgj {\n    background-color: transparent;\n    box-shadow: none;\n    border-bottom: 1px solid black;\n    border-radius: 0;\n    background-repeat: no-repeat;\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23F59201' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%0A%3E%3Cpolyline points='6 9 12 15 18 9' /%3E%3C/svg%3E\");\n}[dir=ltr] .dropdown--1MNgj {\n    padding: 0 30px 0 5px;\n    background-position: right;\n}[dir=rtl] .dropdown--1MNgj {\n    padding: 0 5px 0 30px;\n    background-position: left;\n}\n\n.dropdown--1MNgj:focus-visible {\n    outline: none;\n}\n", ""]);
// Exports
exports.locals = {
	"dropdown": "dropdown--1MNgj"
};
module.exports = exports;
