import React, { useMemo } from "react";
import { Chartjs } from "../../../../components";
import { LoadingSpinner } from "../../../../components";
import { getChartConfig, getChartTitle } from "./utils";
import i18n from "../../../../i18n";

export default ({ state }) => {
	if (!state?.indexedYml) {
		return <LoadingSpinner />;
	}

	const locationTitleText = useMemo(() => getChartTitle(state), [state.setUrlQueryParam, state.selectedSubject, i18n.language]);

	const config = useMemo(
		() => getChartConfig(state, locationTitleText),
		[state.setUrlQueryParam, state.selectedSubject, i18n.language]
	);

	const isOnMobile = window.innerWidth < 768;

	const chartSize = useMemo(
		() => ({
			height: isOnMobile ? "1200px" : "550px",
		}),
		[]
	);

	return <Chartjs chartConfig={config} chartId="barGraph" chartSize={chartSize} />;
};
