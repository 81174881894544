import React from "react";
import { useAppContext } from "../../../../context/AppState.jsx";
import { LoadingSpinner } from "../../../../components";

export default () => {
	const [state, dispatch] = useAppContext();
	if (!state?.reloadingComponent) {
		return null;
	}

	return <LoadingSpinner coverLayout={true} />;
};
