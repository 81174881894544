export default ({ translations, selectedLanguage }) => {
  const translatableDomElements = document.querySelectorAll('[mif-string-id]');

  translatableDomElements.forEach((domElement) => {
    const translatedTextId = domElement.getAttribute('mif-string-id');
    if (!translatedTextId) {
      return;
    }
    const availableTranslations = translations
      .find((transl) => transl.uid === Number(translatedTextId));

    if (availableTranslations) {
      domElement.innerHTML = availableTranslations[selectedLanguage];
    }
  });
};
