import { regionCodes } from '../../referenceData';
import lookupWordByUid from '../lookupWordByUid';

export default (state, location) => {
  if (regionCodes.includes(location)) {
    return lookupWordByUid(state, location);
  }

  return lookupWordByUid(state, `cnm${location}`);
};
