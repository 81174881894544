import {
  calculateTrendNumbers,
  getCountriesInGroup,
  getSelectedSubjects,
  getSpecificValue,
  getStyleLevelForMeasure,
  getSubjectColor,
  getUrlQueryParams,
  isACountry,
  numCountriesWithRank,
  possibleException,
  prettifyNumber,
  roundToOne,
  getRank,
  getGroupCodeFromName,
  getSelectedLocationName,
  getSelectedSubjectName, scoreFormat,
} from '../../actions';

import { defaultVariables } from '../../referenceData';
import i18n from '../../i18n';
import {renderToString} from "react-dom/server";

let appData;
const mapClassifications = {};
const isException = function (val2014, val2015, val2016, endValue2) {
  // Compare 2014 - 2016 values
  if (val2014 == endValue2 || val2015 == endValue2 || val2016 == endValue2) {
    return true;
  }
  return false;
};

const tooltipHandler = (geography, data) => {
  const { subview, range1to } = getUrlQueryParams();
  const selectedSubjects = getSelectedSubjects(appData);
  const notApplicable = 'n/a';
  try {
    data.fillKey;
  } catch (err) {
    console.log(err);
    return null;
  }

  if (geography.id !== 'EH') {
    // If this is a Trend popup
    let scoreText = '';
    let extraText = '';
    const scoreRounded = scoreFormat(
      getSpecificValue(
        appData,
        geography.id,
        selectedSubjects[0],
        Number(range1to),
      )
    );
    // scoreRounded = scoreRounded == -1 ? notApplicable : scoreRounded;
    const rank = getRank(
      appData,
      selectedSubjects[0],
      Number(range1to),
      geography.id,
      getGroupCodeFromName(appData, 'Africa'),
    );
    // If English

    // Just use what's been passed in, as the classification description
    if (subview !== 'score') {
      scoreText = mapClassifications[geography.id];
    } else {
      scoreText = getSelectedSubjectName(appData, selectedSubjects[0]);
    }

    const mousePositionX = d3.event.screenX;

    const tooltipPosition = mousePositionX + 250 > window.innerWidth
      ? 'hoverinfoLeft'
      : 'hoverinfoRight';

    extraText = scoreRounded === notApplicable
      ? `<br />(${i18n.t("Scores").toLowerCase()} ${scoreRounded}, ${i18n.t("ranks")} ${rank})`// eslint-disable-line i18next/no-literal-string
      : `<br />(${i18n.t("Scores").toLowerCase()} ${renderToString(scoreRounded)}/${renderToString(scoreFormat(100))}, ${i18n.t("ranks")} ${rank}/${numCountriesWithRank(// eslint-disable-line i18next/no-literal-string
        selectedSubjects[0],
        Number(range1to),
      )})`;

    if (scoreText !== 'Dont Show' && scoreText !== undefined) {
      return `<div style="min-width: 200px; " class="hoverInfo ${tooltipPosition}">&nbsp;${getSelectedLocationName(// eslint-disable-line i18next/no-literal-string
        appData,
        geography.id,
      )} - ${scoreText}${extraText}&nbsp;</div>`;
    }
    return `<div class="hoverinfo">&nbsp;${getSelectedLocationName(// eslint-disable-line i18next/no-literal-string
      appData,
      geography.id,
    )}&nbsp;</div>`;
  }
};

const classificationColours = {
  'Increasing Improvement': '#418a8f',
  'Slowing Improvement': '#89a361',
  'Warning Signs': '#c5b300',
  'Bouncing Back': '#ffd500',
  'Slowing Deterioration': '#ee7330',
  'Increasing Deterioration': '#b1302e',
  'No Change': '#fff',
};

const hexToRgba = (hex, trans = 1) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(// eslint-disable-line i18next/no-literal-string
      result[2],
      16,
    )}, ${parseInt(result[3], 16)}, ${trans})`;
  }
  return hex;
};

export const colourMap = ({
  state,
  map,
  urlParams,
  selectedLocations,
  selectedSubjects,
}) => {
  if (typeof map === 'undefined') {
    return null;
  }
  appData = state;

  const options = {};
  const { subview, range1to, highlightedTrend } = urlParams;
  let englishClassification = '';
  // Clear existing map highlights
  state.fullListOfCountries.forEach((country) => {
    options[country.iso] = { fillKey: 'Dont_Show', borderColor: '#000000' };

    // Clear existing bubble highlights
    map.svg.selectAll('g').each(function () {
      const countryElement = this;

      if (countryElement.className.baseVal === 'bubbles') {
        for (const child of countryElement.children) {
          const dict = JSON.parse(child.attributes['data-info'].nodeValue);
          if (dict.id === country.iso) {
            countryElement.style.stroke = '#e7e7e7';
            countryElement.style.strokeWidth = 2;
          }
        }
      }
    });
  });
  // Set Western Sahara to white
  options.EH = { fillKey: 'white', borderColor: '#000000' };
  // For each location
  selectedLocations.forEach((location) => {
    const collection = [];
    if (isACountry(location)) {
      collection.push(location);
    } else {
      getCountriesInGroup(state, location).forEach((country) => {
        collection.push(country.iso);
      });
    }

    collection.forEach((location) => {
      {
        const startValue1 = roundToOne(
          getSpecificValue(
            state,
            location,
            selectedSubjects[0],
            defaultVariables.default_year_array[0],
          ),
        );
        const endValue1 = roundToOne(
          getSpecificValue(
            state,
            location,
            selectedSubjects[0],
            defaultVariables.default_year_array[
              defaultVariables.default_year_array.length - 1
            ],
          ),
        );
        const startValue2 = roundToOne(
          getSpecificValue(
            state,
            location,
            selectedSubjects[0],
            defaultVariables.default_secondary_year_array[0],
          ),
        );
        const endValue2 = roundToOne(
          getSpecificValue(
            state,
            location,
            selectedSubjects[0],
            defaultVariables.default_secondary_year_array[
              defaultVariables.default_secondary_year_array.length - 1
            ],
          ),
        );

        let exception = false;

        if (possibleException(endValue2)) {
          // Get latest_year-3 - latest_year-1 values and check for classification exception
          exception = isException(
            roundToOne(
              getSpecificValue(
                state,
                location,
                selectedSubjects[0],
                range1to ? Number(range1to) : defaultVariables.latest_year - 3,
              ),
            ),
            roundToOne(
              getSpecificValue(
                state,
                location,
                selectedSubjects[0],
                range1to ? Number(range1to) : defaultVariables.latest_year - 2,
              ),
            ),
            roundToOne(
              getSpecificValue(
                state,
                location,
                selectedSubjects[0],
                range1to ? Number(range1to) : defaultVariables.latest_year - 1,
              ),
            ),
            endValue2,
          );
        }

        const results = calculateTrendNumbers(
          startValue1,
          endValue1,
          startValue2,
          endValue2,
          defaultVariables.default_year_array,
          defaultVariables.default_secondary_year_array,
          exception,
        );

        if (subview !== 'score') {
          // If English
          englishClassification = results.classifText;
          mapClassifications[location] = englishClassification;

          // Colour relevant countries
          if (!highlightedTrend || highlightedTrend === englishClassification) {
            options[location] = {
              // fillColor: null,
              fillKey: `Classification_${results.classifIndex}`,// eslint-disable-line i18next/no-literal-string
              // borderColor: '#000000'
            };
          } else {
            options[location] = {
              fillKey: `Classification_${results.classifIndex}_SemiOpaque`,// eslint-disable-line i18next/no-literal-string
              borderColor: '#000000',
            };
          }
        } else {
          const score = getSpecificValue(
            state,
            location,
            selectedSubjects[0],
            range1to ? Number(range1to) : defaultVariables.latest_year,
          );
          const color = getSubjectColor({
            state,
            subject: selectedSubjects[0],
            toRgb: true,
          });

          const alpha = score / 100;
          options[location] = {
            fillColor: `rgba(${color},${alpha})`,// eslint-disable-line i18next/no-literal-string
            borderColor: '#000000',
          };
        }
        // fix here
        // Colour the bubbles to match the classification
        map.svg.selectAll('g').each(function () {
          if ($(this)[0].className.baseVal === 'bubbles') {
            $.each($(this)[0].children, function (index, obj) {
              const dict = $.parseJSON(obj.attributes['data-info'].nodeValue); // Get the attributes for the g object
              if (dict.id === location) {
                if (subview !== 'score') {
                  const enClassificationName = results.classifText;
                  let classificationColour = classificationColours[enClassificationName];

                  // Swap from white to grey if No Change
                  if (enClassificationName === 'No Change') {
                    classificationColour = 'lightgray';
                  }

                  if (
                    !highlightedTrend
                    || highlightedTrend === englishClassification
                  ) {
                    // classificationColours[results["classifText"]];
                    $(this)[0].style.stroke = classificationColour;
                  } else {
                    $(this)[0].style.stroke = hexToRgba(
                      classificationColour,
                      0.25,
                    );
                  }
                  $(this)[0].style.strokeWidth = 2;
                  mapClassifications[location] = results.classifText;
                } else {
                  const score = getSpecificValue(
                    state,
                    location,
                    selectedSubjects[0],
                    range1to ? Number(range1to) : defaultVariables.latest_year,
                  );
                  const color = getSubjectColor({
                    state,
                    subject: selectedSubjects[0],
                    toRgb: true,
                  });
                  const alpha = Math.max(0.05, score / 100); // otherwise, na or low scores are white
                  $(this)[0].style.stroke = `rgba(${color},${alpha})`; // eslint-disable-line i18next/no-literal-string
                }
              }
            });
          }
        });
      }
    });
  });

  const color = getSubjectColor({
    state,
    subject: selectedSubjects[0],
    toRgb: true,
  });
  $('.score_bar').css('--subject-start-color', `rgba(${color}, 0)`);// eslint-disable-line i18next/no-literal-string
  $('.score_bar').css('--subject-end-color', `rgba(${color}, 1)`);// eslint-disable-line i18next/no-literal-string

  const level = getStyleLevelForMeasure(state, selectedSubjects[0]);
  $('.trends-div').removeClass([
    'level-0',
    'level-1',
    'level-2',
    'level-3',
    'level-4',
    'level-5',
  ]);
  $('.trends-div').addClass(`level-${level}`);// eslint-disable-line i18next/no-literal-string

  map.updateChoropleth(options);
};

export const drawMap = ({
  htmlName,
  mapScale,
  whiteBackground,
  mapGeoJson,
  state,
  onMapClick,
  selectedSubject,
}) => {
  appData = state;
  const colors = d3.scale.category10();

  // Standard colours
  let borderColour = '#fdfdfd';
  const highlightFillColour = 'rgba(236, 144, 6, 1.0)';
  let defaultFillColour = '#6c6c6c';
  let borderWidth = 1;
  let highlightOnHover = true;

  const onMobile = window.innerWidth < 993;

  const dSmallBubble = 17;
  const dLargeBubble = 25;
  const dTinyBubble = 8;
  const mSmallBubble = 10;
  const mLargeBubble = 17;
  const mTinyBubble = 7;

  // Change the colours
  if (whiteBackground) {
    defaultFillColour = borderColour = '#ededed';
    borderColour = 'lightgray';
    borderWidth = 1;
    highlightOnHover = false;
  }

  const newmap = new Datamap({
    element: document.getElementById(htmlName),
    responsive: true,
    scope: 'test',

    done(datamap) {
      // When map is drawn and ready
      // Bubble radii sizes, dependent on display-width
      const radBig = onMobile ? mLargeBubble : dLargeBubble;
      const radSmall = onMobile ? mSmallBubble : dSmallBubble;
      const radTiny = onMobile ? mTinyBubble : dTinyBubble;

      //  rgb(231, 231, 231)

      // Add bubbles
      datamap.bubbles([
        {
          name: 'Cabo Verde',
          id: 'CV',
          latitude: 15.9388,
          longitude: -23.818,
          radius: radBig,
          fillKey: 'transparent',
        },
        {
          name: 'S�o Tom� & Pr�ncipe',
          id: 'ST',
          latitude: 0.2032,
          longitude: 6.44333,
          radius: radSmall,
          fillKey: 'transparent',
        },
        {
          name: 'Seychelles',
          id: 'SC',
          latitude: -4.6796,
          longitude: 55.492,
          radius: radBig,
          fillKey: 'transparent',
        },
        {
          name: 'Comoros',
          id: 'KM',
          latitude: -11.7,
          longitude: 43.65,
          radius: radBig,
          fillKey: 'transparent',
        },
        {
          name: 'Mauritius',
          id: 'MU',
          latitude: -20.16,
          longitude: 57.5,
          radius: radBig,
          fillKey: 'transparent',
        },
        {
          name: 'Gambia',
          id: 'GM',
          latitude: 13.9150022,
          longitude: -15.790259,
          radius: radTiny,
          fillKey: 'transparent',
        },
      ]);

      datamap.svg.selectAll('.datamaps-subunit').on('click', (geography) => {
        try {
          if (geography.id === 'EH') {
            console.warn('No current data available');
            return null;
          }
          onMapClick(geography.id, selectedSubject);
        } catch (err) {
          console.warn('Country is not located in Africa', err);
        }
      });

      datamap.svg.selectAll('.datamaps-bubble').on('click', (bubble) => {
        try {
          onMapClick(bubble.id, selectedSubject);
        } catch (err) {
          console.warn('Country is not located in Africa');
        }
      });
    },
    // Zoom in on Africa
    setProjection(element) {
      const projection = d3.geo
        .mercator()
        .center([21.4, 1.0])
        .rotate([4.4, 0])
        .scale(Math.min(mapScale, element.offsetWidth /2))
        .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
      const path = d3.geo.path().projection(projection);

      return { path, projection };
    },
    bubblesConfig: {
      borderWidth: 2,
      borderColor: 'rgb(231, 231, 231)',
      popupOnHover: true,
      popupTemplate: tooltipHandler,
      fillOpacity: 0.75,
      highlightOnHover: false,
      highlightFillColor: 'rgba(250, 15, 160, 0.0)',
      highlightBorderColor: 'rgba(236, 144, 6, 1.0)',
      highlightBorderWidth: 2,
      highlightFillOpacity: 1.0,
    },
    geographyConfig: {
      dataJson: mapGeoJson,
      hideAntarctica: false,
      borderWidth,
      borderOpacity: 1,
      borderColor: borderColour,
      popupTemplate: tooltipHandler,
      popupOnHover: true,
      highlightOnHover,
      highlightFillColor: highlightFillColour,
      highlightBorderColor: borderColour,
      highlightBorderWidth: 1,
      highlightBorderOpacity: 1,
    },
    fills: {
      defaultFill: defaultFillColour,
      highlightFill: highlightFillColour,
      red: '#df1b12',
      gold: '#f39000',
      beige: '#ffbf62',
      lightgrey: '#ededed',
      lightblue: '#96b0da',
      blue: '#0e3b83',
      green: '#4c5c0f',
      white: '#ffffff',
      Classification_7: hexToRgba('#418a8f', 1),
      Classification_7_SemiOpaque: hexToRgba('#418a8f', 0.25),
      Classification_6: hexToRgba('#89a361', 1),
      Classification_6_SemiOpaque: hexToRgba('#89a361', 0.25),
      Classification_5: hexToRgba('#c5b300', 1),
      Classification_5_SemiOpaque: hexToRgba('#c5b300', 0.25),
      Classification_4: hexToRgba('#ffd500', 1),
      Classification_4_SemiOpaque: hexToRgba('#ffd500', 0.25),
      Classification_3: hexToRgba('#ee7330', 1),
      Classification_3_SemiOpaque: hexToRgba('#ee7330', 0.25),
      Classification_2: hexToRgba('#b1302e', 1),
      Classification_2_SemiOpaque: hexToRgba('#b1302e', 0.25),
      Classification_1: hexToRgba('#b7b7b7', 1),
      Classification_1_SemiOpaque: hexToRgba('#b7b7b7', 1),
      Classification_0: hexToRgba('#f5f5f5', 1),
      Classification_0_SemiOpaque: hexToRgba('#f5f5f5', 1),
      'n/a': hexToRgba('#f5f5f5', 1),
      'n/a_SemiOpaque': hexToRgba('#f5f5f5', 1),
      Dont_Show: hexToRgba('#E4E4E4', 1),
      gt500: colors(Math.random() * 1),
      transparent: 'rgba(0,0,0,0.0)',
    },
  });

  return newmap;
};

