import React, { useEffect } from "react";
import { DriversOfChangeChart } from "../../../../components";
import { LoadingSpinner } from "../../../../components";
import {getLevelForMeasure, getLevelName, getSelectedLocationName, getSelectedSubjectName, isACountry} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import styles from "./styles.css";
import i18n from "../../../../i18n";

const getIconName = (value) => {
	if (value > 0) {
		return "up";
	}
	if (value < 0) {
		return "down";
	}
	return "zero";
};

const getHeaderPanelLabelData = (selectedMeasure, selectedYears) => {
	return {
		'direction': selectedMeasure.isUnchanged ? i18n.t('Unchanged').toUpperCase() : selectedMeasure.isImproved ? i18n.t("Improved").toUpperCase() : i18n.t("Decreased").toUpperCase(),
		'change': selectedMeasure.change,
		'score': selectedMeasure.currentScore,
		'start_year': selectedYears[0],
		'end_year': selectedYears[selectedYears.length - 1],
	};
}

const getSortedCategories = (categories, dir) => {
	const sortedCategories = [...categories].sort(
		(a, b) => {
			if (dir) {
				return Number(a.annualFields.find((field) => field.column === "AT_Range_1").value) -
				Number(b.annualFields.find((field) => field.column === "AT_Range_1").value)
			} else {
				return Number(b.annualFields.find((field) => field.column === "AT_Range_1").value) -
				Number(a.annualFields.find((field) => field.column === "AT_Range_1").value);
			}
		}
	);
	return sortedCategories.reverse();
};

export default ({ state, dispatch }) => {
	if (!state?.indexedYml || !state?.descriptors) {
		return <LoadingSpinner />;
	}

	const selectedLocations = [state.selectedLocation];
	const selectedSubjects = [state.selectedSubject];
	let urlParams = { subview: "score", range1from: defaultVariables.earliest_year, range1to: defaultVariables.latest_year, loc: state.selectedLocation };

	const selectedLocationName = getSelectedLocationName(state, selectedLocations[0]);
	const isCountry = isACountry(selectedLocations[0]);

	const selectedYearsRange1 = defaultVariables.default_year_array.map((year) => year.toString());

	useEffect(() => {
		const worker = new Worker("/dataTableWebWorker.js", { type: "module" });

		worker.postMessage({
			selectedLocations,
			selectedSubjects: selectedSubjects,
			selectedYears: selectedYearsRange1,
			selectedYears2: defaultVariables.default_secondary_year_array,
			showAll: false,
			urlParams,
			state,
			hasDefaultYearsSelected: true,
			defaultVariables,
			type: "createDriversOfChanges",
			activeLanguage: i18n.language,
		});

		worker.onerror = (err) => console.log("table worker error", err);

		worker.onmessage = (e) => {
			if (e.data === "loading") {
				dispatch({
					type: "driversOfChanges",
					payload: [],
				});
			} else {
				dispatch({
					type: "driversOfChanges",
					payload: e.data,
				});
				worker.terminate();
			}
		};
	}, [i18n.language, selectedSubjects[0]]);

	const driversOfChanges = state?.driversOfChanges || [];

	if (driversOfChanges.length !== 1) {
		return (
			<div className={styles.loadingSpinnerWrapper}>
				<LoadingSpinner />
			</div>
		);
	}

	const selectedMeasure = {
		currentScore: driversOfChanges[0].records.find((record) => record.column === urlParams.range1to.toString()).value,
		change: driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value,
		isImproved: driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value > 0,
		isUnchanged: driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value == 0,
		icon: getIconName(driversOfChanges[0].annualFields.find((record) => record.column === "AT_Range_1").value),
	};

	const categories = driversOfChanges[0].childrens;

	const selectedSubjectLevel = getLevelForMeasure(state, state.selectedSubject);
	const levelName = getLevelName(selectedSubjectLevel+1);

	let drivers = false;
	let opposers = false;

	let categoriesWithDrivingChanges;
	let categoriesWithOpposingChanges
	if (selectedMeasure.isImproved || selectedMeasure.isUnchanged) {
		categoriesWithDrivingChanges = categories.filter(
			(category) => category.annualFields.find((field) => field.column === "AT_Range_1").value > 0
		);

		categoriesWithOpposingChanges = categories.filter(
			(category) => category.annualFields.find((field) => field.column === "AT_Range_1").value <= 0
		);
	} else {
		categoriesWithOpposingChanges = categories.filter(
			(category) => category.annualFields.find((field) => field.column === "AT_Range_1").value >= 0
		);

		categoriesWithDrivingChanges = categories.filter(
			(category) => category.annualFields.find((field) => field.column === "AT_Range_1").value < 0
		);
	}

	const sortedDrivers = getSortedCategories(categoriesWithDrivingChanges, selectedMeasure.isImproved || selectedMeasure.isUnchanged);
	if (sortedDrivers.length > 0) {
		drivers = {
			title: selectedMeasure.isUnchanged ? i18n.t("Underlying positive change") : i18n.t("Change driven by"),
			tables: [{
				header: [
					levelName,
					i18n.t('{{year}} score', {year: selectedYearsRange1[selectedYearsRange1.length - 1]}),
					i18n.t('Change since {{year}}', {year: selectedYearsRange1[0]}),
				],
				rows: sortedDrivers.map((category) => ({
					firstColumn: {
						icon: category.firstColumn.props.icon,
						label: category.firstColumn.value,
						subjectLevel: category.firstColumn.props.subjectLevel,
					},
					score: category.records.find(
						(field) => field.column === selectedYearsRange1[selectedYearsRange1.length - 1].toString()
					).value,
					change: category.annualFields.find((field) => field.column === "AT_Range_1").value,
				})),
			}],
		};
	}

	const sortedOpposers = getSortedCategories(categoriesWithOpposingChanges, !(selectedMeasure.isImproved || selectedMeasure.isUnchanged));
	if (sortedOpposers.length > 0) {

		opposers = {
			title: selectedMeasure.isUnchanged ? i18n.t("Underlying negative change") : i18n.t("Change opposed by"),
			tables: [{
				header: [
					levelName,
					i18n.t('{{year}} score', {year: selectedYearsRange1[selectedYearsRange1.length - 1]}),
					i18n.t('Change since {{year}}', {year: selectedYearsRange1[0]}),
				],
				rows: sortedOpposers.map((category) => ({
					firstColumn: {
						icon: category.firstColumn.props.icon,
						label: category.firstColumn.value,
						subjectLevel: category.firstColumn.props.subjectLevel,
					},
					score: category.records.find(
						(field) => field.column === selectedYearsRange1[selectedYearsRange1.length - 1].toString()
					).value,
					change: category.annualFields.find((field) => field.column === "AT_Range_1").value,
				})),
			}],
		};
	}

	const title = i18n.t("Drivers of Change");

	const headerPanel = {

		title: {
			location: `${selectedLocationName} ${isCountry ? `` : `(${i18n.t('Average')})`}`,
			subject: getSelectedSubjectName(state, selectedSubjects[0]),
			subjectLevel: selectedSubjectLevel,
		},
		summary: {
			isUnchanged: selectedMeasure.isUnchanged,
			icon: selectedMeasure.icon,
			label: getHeaderPanelLabelData(selectedMeasure, selectedYearsRange1),
		},
	};

	return (
		<DriversOfChangeChart
			selectedSubjectLevel={selectedSubjectLevel}
			title={title}
			fullpage={false}
			headerPanel={headerPanel}
			drivers={drivers}
			opposers={opposers}
		/>
	);
};
