import i18n from "../../i18n";
export default (num, aat = false) => {

  if (isNaN(num)) {
      return i18n.t('n/a');
  }

  var formatter = new Intl.NumberFormat(i18n.language, { style: "decimal",  signDisplay: 'exceptZero', minimumFractionDigits: (aat ? 2 : 1), maximumFractionDigits: (aat ? 2 : 1) });
  // eslint-disable-next-line i18next/no-literal-string
  return <span dir="ltr">{formatter.format(num)}</span>;
};
