import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';

export default () => {
  const rootElement = document.getElementById('root_element');
  if (!rootElement) {
    return;
  }

  const reactRoot = ReactDOM.createRoot(
    rootElement,
  );

  reactRoot.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};
