import roundToOne from '../roundToOne';
import roundToTwo from '../roundToTwo';
import getClassificationDescription from '../getClassificationDescription';
import { classificationDescriptions } from '../../referenceData';
import {useTranslation} from "react-i18next";
import i18n from '../../i18n';

export default (
  startValue1,
  endValue1,
  startValue2,
  endValue2,
  yearArray1,
  yearArray2,
  isException,
) => {

  const results = {};
  const notApplicable = i18n.t('n/a');
  const scoreChangeRange1 = endValue1 !== -1 && startValue1 !== -1
    ? roundToOne(endValue1 - startValue1)
    : notApplicable;
  const scoreChangeRange2 = endValue2 !== -1 && startValue2 !== -1
    ? roundToOne(endValue2 - startValue2)
    : notApplicable;

  if (scoreChangeRange1 !== notApplicable) {
    results.AAT_Range_1_Unrounded = scoreChangeRange1 / (yearArray1.length - 1);
    results.AAT_Range_1_Rounded = roundToTwo(results.AAT_Range_1_Unrounded);
    results.AT_Range_1_Rounded = scoreChangeRange1;
  } else {
    results.AAT_Range_1_Unrounded = notApplicable;
    results.AAT_Range_1_Rounded = notApplicable;
    results.AT_Range_1_Rounded = notApplicable;
  }
  if (scoreChangeRange2 !== notApplicable) {
    results.AAT_Range_2_Unrounded = scoreChangeRange2 / (yearArray2.length - 1);
    results.AAT_Range_2_Rounded = roundToTwo(results.AAT_Range_2_Unrounded);
    results.AT_Range_2_Rounded = scoreChangeRange2;
  } else {
    results.AAT_Range_2_Unrounded = notApplicable;
    results.AAT_Range_2_Rounded = notApplicable;
    results.AT_Range_2_Rounded = notApplicable;
  }
  results.classifText = scoreChangeRange1 === notApplicable
    || scoreChangeRange2 === notApplicable
    || isException
    ? i18n.t(classificationDescriptions[0])
    : getClassificationDescription(
      results.AAT_Range_1_Unrounded,
      results.AAT_Range_2_Unrounded,
      startValue2,
      false,
    );

  results.classifIndex = scoreChangeRange1 === notApplicable
  || scoreChangeRange2 === notApplicable
  || isException
    ? 0
    : getClassificationDescription(
      results.AAT_Range_1_Unrounded,
      results.AAT_Range_2_Unrounded,
      startValue2,
      true,
    );

  return results;
};
