import React from "react";
import { SiteWideNotice } from "../../components";
import {getAppSelectedLanguage, readCookie} from "../../actions";
import { setCookie } from "../../actions";
import { useAppContext } from "../../context/AppState.jsx";

export default () => {
	const [state, dispatch] = useAppContext();
	const cookie = JSON.parse(readCookie("sitewide-notice-dismissed"));
	const embed = window.location.pathname == '/embed.html';

	if (cookie) {
		return null;
	}

	if (embed) {
		return null;
	}

	if (!state?.translatedText) {
		return null;
	}

	const onDismiss = () =>
		dispatch({
			type: "sitewide-notice-dismissed",
			payload: setCookie("sitewide-notice-dismissed", true, 365),
		});

	const lang = getAppSelectedLanguage();

	const title = state.translatedText[197][lang];
	const description = state.translatedText[198][lang];

	return <SiteWideNotice onDismiss={onDismiss} title={title} description={description} />;
};
