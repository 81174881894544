import { DataPage } from '../../pages';

export default (activeView) => {
  if (activeView === 'graph') {
    global.$('#screenshotPdfContent').html('');
  }

  global.$('.uk-icon-information').removeClass('uk-hidden');
  global.$('#btnTakeScreenshot').removeClass('uk-disabled');
  global.$('#chart_title').removeClass('uk-hidden');
  global.$('#table_main_heading').removeClass('uk-hidden');
  global.$('#headerTrends').removeClass('uk-hidden');
  global.$('#screenshotPdfFrame').addClass('uk-hidden');

  let chartContainer = document.getElementById('chart');

  if (activeView === 'graph' && !chartContainer) {
    const chartWrapper = document.getElementById('div_graph');
    chartContainer = document.createElement('div');
    chartContainer.setAttribute('id', 'chart');
    chartWrapper.appendChild(chartContainer);
    DataPage();
  }

  if (activeView === 'table') {
    const tableWrapper = document.getElementById('pnl_Table');
    const tableParent = document.getElementById('li_table');

    tableParent.appendChild(tableWrapper);
  }

  if (activeView === 'overview') {
    const overviewWrapper = document.getElementById('pnl_Overview');
    const overviewParent = document.getElementById('li_overview');

    overviewParent.appendChild(overviewWrapper);
  }

  if (activeView === 'map') {
    const mapWrapper = document.getElementById('pnl_Trends');
    const mapParent = document.getElementById('li_trends');

    mapParent.appendChild(mapWrapper);
  }

  global.$('.measure-icon-image').removeClass('uk-hidden');
  global.$('.screenshot-icon').remove();

  global.$('#screenshotPdfContent').html('');

  notifyError(
    "<div id='progress_message' class='uk-active'><h2>Downloading file</h2></div>",
    2000,
  );
};
