import { default as getSelectedYears } from '../getSelectedYears';
import { default as getSelectedSubjects } from '../getSelectedSubjects';
import { default as getMaxScoreForGroup } from '../getMaxScoreForGroup';
import { default as getGroupCodeFromName } from '../getGroupCodeFromName';

export default (state) => {
  // Put relevant data in Processing subset
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedSubjects = getSelectedSubjects(state);

  const maximums = [];
  selectedYears.forEach((obj) => {
    const pos = {};
    const value = getMaxScoreForGroup(
      state,
      selectedSubjects[0],
      obj,
      getGroupCodeFromName(state, 'Africa'),
    );

    // Exception for ARTProv - no reporting of values until 2010
    if (selectedSubjects[0] == 'ARTProv' && obj >= 2010) {
      pos[obj] = value;
      maximums.push(pos);
    } else if (value != -1 && selectedSubjects[0] != 'ARTProv') {
      pos[obj] = value;
      maximums.push(pos);
    } else {
      pos[obj] = null;
      maximums.push(pos);
    }
  });
  return maximums;
};
