import { defaultVariables } from '../../referenceData';

let db;

export default ({ tableName, tableData }) => {
  if (!window.indexedDB) {
    console.log(
      "Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.",// eslint-disable-line i18next/no-literal-string
    );
  }
  const request = window.indexedDB.open(
    `mo-ibrahim-foundation-${defaultVariables.latest_year}`,// eslint-disable-line i18next/no-literal-string
    1,
  );

  request.onerror = (event) => {};
  request.onsuccess = (event) => {
    db = event.target.result;
  };

  request.onupgradeneeded = function (event) {
    const dbResult = event.target.result;

    // check if our database already exists and contains our object store
    if (dbResult.objectStoreNames.contains(tableData)) {
      // if so, delete it so we can re-create it with our new structure
      dbResult.deleteObjectStore(tableData);
    }

    // create a new object store
    const store = dbResult.createObjectStore(tableName, {
      keyPath: 'id',
      autoIncrement: true,
    });
  };
};
