import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({ selectedSubjectColor }) => {
	const {t} = useTranslation();
	return (
		<div className={`${styles.wrapper} uk-width-1-1 trends-score-explanation-parent uk-overflow-auto`}>
			<div className="trends-byline">
				<div>
					<span>{t("Score")}</span>
				</div>
				<div/>
			</div>
			<table className="trends_left_table uk-width-1-1" cellPadding={0} cellSpacing={0}>
				<tbody>
				<tr>
					<td className="trends_left_table_key">
						<span>{t("Key")}:</span>
					</td>
					<td
						colSpan={6}
						className={styles.scoreBar}
						style={{
							background: `linear-gradient(90deg, rgba(${selectedSubjectColor},0) 0%, rgba(${selectedSubjectColor}, 1) 100%)`,
						}}
					/>
				</tr>
				<tr>
					<td/>
					<td className="text-left">0.0</td>
					<td/>
					<td/>
					<td/>
					<td/>
					<td className="text-right">100.0</td>
				</tr>
				</tbody>
			</table>
		</div>
	);
};
