
// i want them in the order
// meas, loc, view, subview
// and then any others alphabetically
function sortParams(url) {
  const order = ['meas', 'loc', 'view', 'subview'];

  // Extract the searchParams as an array of key/value objects
  // you can't callback sort on urlparams
  const paramsArray = Array.from(url.searchParams.entries());

  // we also need the keys, so we can unset everything
  // if you don't unset, then when you call set it keeps the existing order
  const keys = paramsArray.map(([key]) => key);
  keys.forEach((key) => {
    url.searchParams.delete(key);
  });

  // Sort the parameters based on custom logic
  paramsArray.sort((a, b) => {
    const aKey = a[0];
    const bKey = b[0];
    const aIndex = order.indexOf(aKey);
    const bIndex = order.indexOf(bKey);

    // If both keys are not in the order array, sort alphabetically
    if (aIndex === -1 && bIndex === -1) {
      return aKey.localeCompare(bKey);
    }

    // If only one key is in the order array, prioritize it
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }

    // Otherwise, sort by the index in the order array
    return aIndex - bIndex;
  });

  // set back in order
  paramsArray.forEach(([key, value]) => url.searchParams.set(key, value));
}

export default ({ name, value }) => {
  const url = new URL(global.location);
  url.searchParams.set(name, value);
  sortParams(url);
  global.history.replaceState({}, '', url);
};
