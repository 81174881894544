import React from "react";
import { ScoreLegend, TrendsLegend } from "./subComponents/index.js";

export default ({ urlParams, props, isTakingScreenshot }) => {
	const { subview } = urlParams;

	switch (subview) {
		case "score": {
			return <ScoreLegend selectedSubjectColor={props.selectedSubjectColor} />;
		}
		case "trend": {
			return (
				<TrendsLegend
					urlParams={urlParams}
					axisLegend={props.axisLegend}
					trends={props.trends}
					onTrendsClick={props.onTrendsClick}
					isTakingScreenshot={isTakingScreenshot}
					onInfoClick={props.onInfoClick}
				/>
			);
		}
		default: {
			return <ScoreLegend />;
		}
	}
};
