export default (s, len) => s.map((e) => {
  if (e.length > len) {
    e = (`${e} `)
      .replace(new RegExp(`(\\S(.{0,${len}}\\S)?)\\s+`, 'g'), '$1\n')// eslint-disable-line i18next/no-literal-string
      .trim()
      .split('\n');
  } else {
    e = [e];
  }
  return e;
});
