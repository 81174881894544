export default (state, measureCode) => {
  let returnVal;

  try {
    returnVal = state.fullListOfMeasures.find(
      (e) => e.s_id === measureCode,
    ).lvl;
  } catch (err) {
    returnVal = -1;
  }

  return returnVal;
};
