import * as images from '../../../img/icons';
import { getSubjectColor, getStyleLevelForMeasure } from '../../actions';
import { default as getFooterAndBody } from './utils';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = document.getElementById('template_chart_js_tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.setAttribute('class', 'chart_js_tooltip');
    const tooltipWrapper = document.createElement('div');
    const tooltipTitle = document.createElement('h5');
    const tooltipBodyList = document.createElement('ul');
    const tooltipDevider = document.createElement('hr');
    const tooltipFooterList = document.createElement('ul');

    tooltipWrapper.setAttribute('class', 'chart_js_tooltip_wrapper');
    tooltipTitle.setAttribute('id', 'chart_js_tooltip_title');
    tooltipBodyList.setAttribute('id', 'chart_js_tooltip_body_list');
    tooltipDevider.setAttribute('id', 'chart_js_tooltip_devider');
    tooltipFooterList.setAttribute('id', 'chart_js_tooltip_footer_list');
    tooltipEl.setAttribute('id', 'template_chart_js_tooltip');

    tooltipWrapper.appendChild(tooltipTitle);
    tooltipWrapper.appendChild(tooltipBodyList);
    tooltipWrapper.appendChild(tooltipDevider);
    tooltipWrapper.appendChild(tooltipFooterList);
    tooltipEl.appendChild(tooltipWrapper);
  }
  chart.canvas.parentNode.appendChild(tooltipEl);

  return tooltipEl;
};

export default ({
  state,
  context,
  tooltipData,
  activeDatasets = [],
  hideComparison,
  chartName = null,
  isMultiSubjectView,
  alwaysSign
}) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const devider = document.getElementById('chart_js_tooltip_devider');

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const bodyList = document.getElementById('chart_js_tooltip_body_list');
  const footerList = document.getElementById('chart_js_tooltip_footer_list');
  bodyList.innerHTML = '';
  footerList.innerHTML = '';

  const { footer, body, title } = getFooterAndBody({
    chartName,
    tooltip,
    tooltipData,
    hideComparison,
    activeDatasets,
    context,
    isMultiSubjectView,
  });

  if (body.length === 0 && chartName === 'dataPageBarChart') {
    return (tooltipEl.style.opacity = 0);
  }

  document.getElementById('chart_js_tooltip_title').innerHTML = title;

  body.sort((a, b) => b.value - a.value);

  body.forEach((stat) => {
    const listElement = document.createElement('li');
    const listElementLabel = document.createElement('span');
    const listElementValue = document.createElement('span');

    const subjectSVG = images[stat.abr];

    const icon = document.createElement('div');
    icon.classList.add('chart_js_tooltip-measure-icon');

    if (typeof subjectSVG === 'undefined') {
      icon.style.background = getSubjectColor({ state, subject: stat.abr });
      icon.style.borderColor = getSubjectColor({ state, subject: stat.abr });
      icon.style.marginRight = '15px';
      icon.style.marginLeft = '2px';
      icon.style.height = '15px';
      icon.style.width = '15px';
      icon.style.borderRadius = '8px';
      icon.style.borderRadius = '50%';
    } else {
      icon.style.backgroundImage = `url("/img/icons/${stat.abr}.svg")`; // eslint-disable-line i18next/no-literal-string
      icon.style.verticalAlign = 'top';
    }

    listElement.appendChild(icon);
    listElement.classList.add(
      'chart_js_tooltip_body_item',
      `level-${getStyleLevelForMeasure(state, stat.abr)}`,// eslint-disable-line i18next/no-literal-string
    );

    listElementLabel.classList.add('subject-name');
    listElementLabel.style.verticalAlign = 'top';
    listElementLabel.innerHTML = `${stat.label}`;
    listElementLabel.style.verticalAlign = 'top';
    listElementValue.innerHTML = isNaN(stat.value) || (isNaN(stat.raw) && typeof(stat.raw) != 'object')
      ? 'n/a'
      : `${alwaysSign && stat.value > 0 ? '+': ''}${Number(stat.value).toFixed(1)}`;
    listElementValue.classList.add('chart_js_tooltip_body_item_value');
    listElementValue.style.verticalAlign = 'top';
    listElementValue.dir = 'ltr';
    listElement.appendChild(listElementLabel);
    listElement.appendChild(listElementValue);
    bodyList.appendChild(listElement);
  });

  if (footer.length > 0) {
    footer.forEach((stat) => {
      const listElement = document.createElement('li');
      const listElementLabel = document.createElement('span');
      const listElementValue = document.createElement('span');

      listElementValue.classList.add('chart_js_tooltip_footer_item_value');
      listElement.classList.add('chart_js_tooltip_footer_item');

      listElementLabel.innerHTML = `${stat.dataset.label}`;
      // why is this json? seems to be core chartjs....
      // multi values/objects are relevant for range bar charts (max min)
      // we can't use raw values here as on scatters have 2 for x/y
      const deJsoned = JSON.parse(stat.formattedValue);
      if (typeof deJsoned === 'object') {
        listElementValue.innerHTML = `${Number(deJsoned[0]).toFixed(
          1,
        )}-${Number(deJsoned[1]).toFixed(1)}`;
      } else {
        listElementValue.innerHTML = `${alwaysSign && deJsoned > 0 ? '+': ''}${Number(deJsoned).toFixed(1)}`;
      }

      listElement.appendChild(listElementLabel);
      listElement.appendChild(listElementValue);
      footerList.appendChild(listElement);
    });
  }

  if (body.length <= 0 || footer.length <= 0) {
    devider.style.opacity = 0;
  } else {
    devider.style.opacity = 1;
  }

  const {
    offsetWidth: chartWidth,
    offsetLeft: positionX,
    offsetTop: positionY,
  } = chart.canvas;

  const mousePositionX = tooltip.caretX;
  const tooltipWidth = tooltipEl.offsetWidth + 30;

  const onMobile = window.innerWidth < 993;

  const offsetLeft = chart.canvas.getBoundingClientRect().left;
  const windowWidth = window.innerWidth;

  tooltipEl.style.opacity = 1;
  if (onMobile) {
    tooltipEl.style.left = 0;
    tooltipEl.style.maxWidth = '100%';
  } else if (
    (offsetLeft + mousePositionX + tooltipWidth) > windowWidth ||
    mousePositionX > tooltipWidth
  ) {
    tooltipEl.style.left = `${tooltip.caretX - tooltipWidth - 30}px`;// eslint-disable-line i18next/no-literal-string
    tooltipEl.style.minWidth = '400px';
  } else {
    tooltipEl.style.left = `${positionX + tooltip.caretX + 30}px`;// eslint-disable-line i18next/no-literal-string
  }

  tooltipEl.style.zIndex = 9999;
  tooltipEl.style.top = `${// eslint-disable-line i18next/no-literal-string
    positionY + tooltip.caretY - tooltipEl.offsetHeight / 2
  }px`;
};
