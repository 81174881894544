import React from "react";
import {
	extractValuePairs,
	getGroupAveragesForSubject,
	getGroupCodeFromName,
	getLevelForMeasure,
	getRank,
	getSpecificValue,
	getStyleLevelForMeasure,
	getSubjectColor,
	getUrlQueryParams,
	isACountry,
	isCitvoiceMeasure,
	lookupWordByUid,
	numCountriesWithRank,
	prettifyNumber,
	rankPercentInt,
	roundToOne,
} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import { createSingleTrend } from "../Overview/utils";
import { useAppContext } from "../../../../context/AppState.jsx";
import { OverviewMultiLocationSingleSubjectInnerLocation } from "../../../../components";

export default ({ location, subject, isModal, isTakingScreenshot }) => {
	const [state] = useAppContext();

	const level = getLevelForMeasure(state, subject);
	const linkToDetailsOfSelectedCountries = `/locations/${location.toLowerCase()}.html?meas=GOVERNANCE&loc=${location}`;
	const { subview, view } = getUrlQueryParams();
	const locationData = {};
	const index = location;

	let locationName;
	let scoreRounded;
	let rank;

	const subjectName = lookupWordByUid(state, `m${subject}`, true);

	if (isACountry(location)) {
		locationName = lookupWordByUid(state, `cnm${location}`, true);
		// Show flag

		// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = earliest_year;
		// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = earliest_year;

		let startYear = defaultVariables.latest_year - 9;
		// If no data for this country in this year, find first year with data
		if (getSpecificValue(state, location, subject, startYear) < 0) {
			for (let i = 1; i < defaultVariables.default_year_array.length - 1; i++) {
				if (getSpecificValue(state, location, subject, defaultVariables.default_year_array[i]) >= 0) {
					startYear = defaultVariables.default_year_array[i];
					// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = startYear.toString();
					// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = startYear.toString();
					break;
				}
			}
		}

		scoreRounded = roundToOne(getSpecificValue(state, location, subject, defaultVariables.latest_year));
		rank = getRank(state, subject, defaultVariables.latest_year, location, getGroupCodeFromName(state, "Africa"));

		// @TODO GLOBALSTRINGS translations[`location_${index}`] = lookupTranslationByUid(`cnm${location}`, true);
		// @TODO GLOBALSTRINGS dynamic_strings[`location_${index}`] = lookupWordByUid(state, `cnm${location}`, true);

		locationData.index = index;
		locationData.hide_flag = "";
		locationData.iso = location.toLowerCase();
		locationData.first_row_width = "uk-width-1-3";
		locationData.hide = "";
		locationData.hide_flag = "";
		locationData.hide_rank = "";
		locationData.location_transKey = `cnm${location}`;
		locationData.rank = rank;
		locationData.countries_with_rank = numCountriesWithRank(subject, defaultVariables.latest_year);
		locationData.rank_percent_int = rankPercentInt(rank, locationData.countries_with_rank);
		locationData.subHide = "";
		locationData.subWidth = "uk-width-1-2@s";
		locationData.view_more_country_info_link = linkToDetailsOfSelectedCountries;
		locationData.show_info_button = !isModal;
	} else {
		// Group
		locationName = lookupWordByUid(state, location, true);

		// @TODO GLOBALSTRINGS translations[`since_year_${index}`] = earliest_year;
		// @TODO GLOBALSTRINGS dynamic_strings[`since_year_${index}`] = earliest_year;

		const val = extractValuePairs(
			getGroupAveragesForSubject(state, location, subject, [
				defaultVariables.earliest_year,
				defaultVariables.latest_year,
			])
		);
		scoreRounded = roundToOne(val[1].value);

		locationData.index = index;
		locationData.hide_flag = "flag_block";
		locationData.first_row_width = "uk-width-1-2";
		locationData.hide = "uk-hidden";
		locationData.hide_flag = "uk-hidden";
		locationData.hide_rank = "uk-hidden";
		locationData.location_transKey = location;
		locationData.subHide = "uk-hidden";
		locationData.subWidth = "uk-width-1-1";
		locationData.show_info_button = false;
	}

	locationData.trend = createSingleTrend(location, subject, 1, subview);
	locationData.score = isNaN(scoreRounded) ? "n/a" : prettifyNumber(scoreRounded);
	locationData.since_year_0 = defaultVariables.default_year_array[0];
	locationData.since_year_1 = defaultVariables.default_secondary_year_array[0];
	locationData.score_int = parseInt(scoreRounded, 10);
	locationData.subject_type = isCitvoiceMeasure(state, subject) ? "citvoice" : "regular";
	locationData.subject_level = `level-${getStyleLevelForMeasure(state, subject)}`;
	locationData.subject_row_level = getStyleLevelForMeasure(state, subject);
	locationData.location = locationName;
	locationData.subject = subjectName;
	locationData.subject_color = getSubjectColor({ state, subject });
	locationData.subjectTransKey = `m${subject}`;
	locationData.subject_s_id = subject;
	locationData.hide_icon = level <= 1 || (!isCitvoiceMeasure(state, subject) && level <= 2) ? "" : "uk-hidden";

	locationData.hide_about_header = view === "map" ? "" : "uk-hidden";
	locationData.isModal = isModal;
	locationData.isTakingScreenshot = isTakingScreenshot;

	return <OverviewMultiLocationSingleSubjectInnerLocation locationData={locationData} key={locationData.iso} />;
};
