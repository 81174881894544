// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".spinnerWrapper--2e2Dl {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.spinnerWrapperCover--1qLOR {\n    position: absolute;\n    top: 0;\n    z-index: 999999;\n}\n\n[dir] .spinnerWrapperCover--1qLOR {\n    background-color: white;\n}\n\n[dir=ltr] .spinnerWrapperCover--1qLOR {\n    left: 0;\n}\n\n[dir=rtl] .spinnerWrapperCover--1qLOR {\n    right: 0;\n}\n\n.spinner--3w6MZ {\n    width: 50px;\n    height: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"spinnerWrapper": "spinnerWrapper--2e2Dl",
	"spinnerWrapperCover": "spinnerWrapperCover--1qLOR",
	"spinner": "spinner--3w6MZ"
};
module.exports = exports;
