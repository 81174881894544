import React from "react";
import { OverviewSingleScore } from "../../../../components";

import {
	extractValuePairs,
	getGroupAveragesForSubject,
	getLevelForMeasure,
	getSelectedLocationName,
	getSelectedSubjectName,
	getSpecificValue,
	getStyleLevelForMeasure,
	getSubjectColor,
	isACountry,
	isCitvoiceMeasure,
	prettifyNumber,
	roundToOne,
} from "../../../../actions";
import { useAppContext } from "../../../../context/AppState.jsx";
import { defaultVariables } from "../../../../referenceData";
import { SubjectPickerMenu } from "../../../../reusablePartials";

export default ({ subject, location, standalone, isLocationPage }) => {
	const [state, dispatch] = useAppContext();
	let data = { subject, location, isLocationPage };
	data.level = getLevelForMeasure(state, subject);
	data.style_level = getStyleLevelForMeasure(state, subject);
	data.subject_name = getSelectedSubjectName(state, subject);
	data.subject_color = getSubjectColor({ state, subject });
	data.citvoice = isCitvoiceMeasure(state, subject);

	data.title = data.subject_name;
	if (standalone) {
		data.title += "<br>for " + getSelectedLocationName(state, location);
	}

	if (isACountry(location)) {
		data.country = true;
		data.score_rounded = roundToOne(getSpecificValue(state, location, subject, defaultVariables.latest_year));
		// var firstRowWidth = "uk-width-1-3";
	} else {
		data.country = false;

		let val = extractValuePairs(
			getGroupAveragesForSubject(state, location, subject, [
				defaultVariables.earliest_year,
				defaultVariables.latest_year,
			])
		);
		data.score_rounded = roundToOne(val[1].value);
		// var firstRowWidth = "uk-width-1-2";
	}

	const onSubjectChange = (subject) => {
		dispatch({
			type: "selectedSubject",
			payload: subject,
		});
		dispatch({
			type: "showSubjectPicker",
			payload: false,
		});
	};

	const onSubjectMenuClick = (isMenuVisible) => {
		if (isMenuVisible) {
			dispatch({
				type: "showSubjectPicker",
				payload: false,
			});
		} else {
			dispatch({
				type: "showSubjectPicker",
				payload: true,
			});
		}
	};

	data.children = <SubjectPickerMenu isSimplifiedView={true} onSubjectChange={onSubjectChange} />;
	data.onSubjectMenuClick = onSubjectMenuClick;
	data.showChildren = state?.showSubjectPicker || false;

	data.score = isNaN(data.score_rounded) ? "n/a" : prettifyNumber(data.score_rounded);
	data.score_int = isNaN(data.score_rounded) ? "0" : parseInt(data.score_rounded);

	return <OverviewSingleScore data={data} />;
};
