import React from "react";
import styles from "./styles.css";
import {useModalContext} from "../../context/modalContext.jsx";
import {Information} from "../Modals";

const infoOnClick = ({title, description}) => {
	const [showModal] = useModalContext();
	return (e) => { e.preventDefault(); showModal(<Information title={title} description={[description]}  />) };
}

export default ({ legend }) => {
	return (
		<div className={styles.legendWrapper}>
			{legend.items.map((item) => (
				<div className={styles.itemWrapper} onClick={item.onclick} key={`raw-table-legend-${item.label}`}>
					<div className={`${styles[item.class]} ${styles.indicator}`} />
					<div className={`${item.checked ? '' : styles.strikeout }`}>{item.label}</div>
					{ item.info ? <img src="/dist/src/img/icons/infoSymbol.png"  onClick={infoOnClick(item.info)} className={`clickable_pointer ${styles.infoIcon}`} /> : null }
				</div>
			))}
		</div>
	);
};
