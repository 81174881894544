import * as images from '../../../img/icons';
import { getSubjectColor, getStyleLevelForMeasure } from '../../actions';
import styles from './styles.css';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = document.getElementById('template_chart_js_tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.setAttribute('class', 'chart_js_tooltip');
    const tooltipWrapper = document.createElement('div');
    const tooltipTitle = document.createElement('h5');
    const tooltipBodyList = document.createElement('ul');

    tooltipWrapper.setAttribute('class', 'chart_js_tooltip_wrapper');
    tooltipTitle.setAttribute('id', 'chart_js_tooltip_title');
    tooltipBodyList.setAttribute('id', 'chart_js_tooltip_body_list');
    tooltipEl.setAttribute('id', 'template_chart_js_tooltip');

    tooltipWrapper.appendChild(tooltipTitle);
    tooltipWrapper.appendChild(tooltipBodyList);
    tooltipEl.appendChild(tooltipWrapper);
  }
  chart.canvas.parentNode.appendChild(tooltipEl);

  return tooltipEl;
};

export default ({ state, context, selectedYears }) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);
  const body = [];

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const bodyList = document.getElementById('chart_js_tooltip_body_list');
  bodyList.innerHTML = '';

  const listElement = document.createElement('span');
  const listElementLabel = document.createElement('span');
  const listElementValue = document.createElement('div');
  const listElementValue2 = document.createElement('div');

  const subjectSVG = images[tooltip.dataPoints[0].dataset.subject];

  const icon = document.createElement('div');
  icon.classList.add('chart_js_tooltip-measure-icon');

  if (typeof subjectSVG === 'undefined') {
    icon.style.background = getSubjectColor({
      state,
      subject: tooltip.dataPoints[0].dataset.subject,
    });
    icon.style.borderColor = tooltip.dataPoints[0].dataset.borderColor;
    icon.style.marginRight = '15px';
    icon.style.marginLeft = '2px';
    icon.style.height = '15px';
    icon.style.width = '15px';
    icon.style.borderRadius = '8px';
    icon.style.borderRadius = '50%';
  } else {
    icon.innerHTML = images[tooltip.dataPoints[0].dataset.subject];
  }

  listElement.appendChild(icon);
  listElement.classList.add(
    'chart_js_tooltip_body_item',
      `level-${getStyleLevelForMeasure( // eslint-disable-line i18next/no-literal-string
      state,
      tooltip.dataPoints[0].dataset.subject,
    )}`,
  );

  listElementLabel.classList.add('subject-name');
  listElementLabel.innerHTML = `${tooltip.dataPoints[0].dataset.label}`;
  listElementValue.innerHTML = `${selectedYears.range1.from}-${ // eslint-disable-line i18next/no-literal-string
    selectedYears.range1.to
  }: <span dir="ltr">${tooltip.dataPoints[0].dataset.data[0].x > 0 ? '+':''}${Number(tooltip.dataPoints[0].dataset.data[0].x).toFixed(2)}</span>`;
  listElementValue.classList.add(styles.value);
  listElementValue2.innerHTML = `${selectedYears.range2.from}-${// eslint-disable-line i18next/no-literal-string
    selectedYears.range2.to
  }: <span dir="ltr">${tooltip.dataPoints[0].dataset.data[0].y > 0 ? '+':''}${Number(tooltip.dataPoints[0].dataset.data[0].y).toFixed(2)}</span>`;
  listElementValue2.classList.add(styles.value);
  listElementValue.classList.add('chart_js_tooltip_body_item_value');
  listElement.appendChild(listElementLabel);
  bodyList.appendChild(listElement);
  bodyList.appendChild(listElementValue);
  bodyList.appendChild(listElementValue2);

  const {
    offsetWidth: chartWidth,
    offsetLeft: positionX,
    offsetTop: positionY,
  } = chart.canvas;
  const mousePositionX = tooltip.caretX;
  const tooltipWidth = tooltipEl.offsetWidth + 30;

  tooltipEl.style.opacity = 1;
  if (global.onMobile) {
    tooltipEl.style.left = 0;
    tooltipEl.style.maxWidth = '100%';
  } else if (
    mousePositionX + tooltipWidth > chartWidth
    && mousePositionX > tooltipWidth
  ) {
    tooltipEl.style.left = `${tooltip.caretX - tooltipWidth - 30}px`; // eslint-disable-line i18next/no-literal-string
    tooltipEl.style.minWidth = '400px';
  } else {
    tooltipEl.style.left = `${positionX + tooltip.caretX + 30}px`; // eslint-disable-line i18next/no-literal-string
  }

  tooltipEl.style.zIndex = 9999;
  tooltipEl.style.top = `${ // eslint-disable-line i18next/no-literal-string
    positionY + tooltip.caretY - tooltipEl.offsetHeight / 2
  }px`;
};
