import React from "react";
import {Information} from "../../../Modals";
import {useModalContext} from "../../../../context/modalContext.jsx";
import styles from "./styles.css";

const infoOnClick = ({title, description}) => {
	const [showModal] = useModalContext();
	return (e) => { e.preventDefault(); showModal(<Information title={title} description={[description]}  />) };
}

export default ({ highlights, trends, checkboxes }) => (
	<div className="idp-footer__highlight advanced-settings">
		<div className="uk-width-1-2@s">
			{ highlights.options.length ? (
				<>
					<span>{highlights.header}</span>
					<br />
					<div className="idp-footer__highlight-inner ">
						{highlights.options.map((option, index) => (
							<label
								key={`${option.text.split(" ").join("")}_${index}`}
								className={`reduced-size-label clickable_pointer  ${option.class}`}
							>
								<input checked={option.checked} type="checkbox" onChange={(e) => option.onclick(e)} />
								{option.text}

								{ option.info ? <img src="/dist/src/img/icons/infoSymbol.png"  onClick={infoOnClick(option.info)} className={`clickable_pointer ${styles.infoIcon}`} /> : null }

							</label>
						))}
					</div>
				</>
			) : null }
		</div>
		<div className="uk-width-1-2@s">
			{trends ? (
				<>
					{trends.options.map((option, index) => (
						<label onClick={() => option.onClick()} key={`${option.text.split(" ").join("")}_${index}`}>
							<input
								type="radio"
								className="idp-footer__button"
								name={`footer_radio_${index}_advanced`}
								checked={option.checked}
								onChange={e=>{}}
							/>
							{option.text}
						</label>
					))}
				</>
			) : null}
			{trends ? (
				<div>
					{trends.legend.map((legend, index) => (
						<p key={index}>{legend}</p>
					))}
				</div>
			) : null}
		</div>
		<div className="uk-width-1-2@s">
			{checkboxes.map((checkbox, index) => (
				<label
					key={`${checkbox.text.split(" ").join("")}_${index}`}
					className={`reduced-size-label clickable_pointer`}
				>
					<input
						type="checkbox"
						onChange={(e) => {
							checkbox.onChange(e.target.checked);
						}}
						checked={checkbox.checked}
					/>
					{checkbox.text}
				</label>
			))}
		</div>
	</div>
);
