import React from "react";
import { BarChartPanel } from "../../../../../../components/SettingsPanel/subComponents";
import {
	setUrlQueryParam,
	getSelectedSubjects,
	getSelectedLocations,
	getUrlQueryParams,
} from "../../../../../../actions";
import i18n from "../../../../../../i18n";

const checkIfQueryParamsAreCorrect = (subjectsCount, sortBy, changeQueryParam) => {
	if (subjectsCount > 1 && sortBy === "name") {
		changeQueryParam("sortBy", "index");
	}
	if (subjectsCount === 1 && sortBy === "index") {
		changeQueryParam("sortBy", "name");
	}
};

export default ({ urlParams, dispatch, state }) => {
	const { sortBy, sortDir } = urlParams;
	const subjectsCount = getSelectedSubjects(state).length;
	const locationsCount = getSelectedLocations(state).length;

	if (subjectsCount === 1 && locationsCount === 1) {
		return null;
	}

	const changeQueryParam = (name, value) => {
		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name, value }),
		});
	};

	checkIfQueryParamsAreCorrect(subjectsCount, sortBy, changeQueryParam);

	const sortBySettings = () => {
		const title = i18n.t("Sort by:");
		if (subjectsCount > 1) {
			return {
				title,
				options: [
					{
						label: i18n.t("Score"),
						onClick: () => {
							changeQueryParam("sortDir", "des");
							changeQueryParam("sortBy", "score");
						},
						checked: typeof sortBy === "undefined" || sortBy === "score",
					},
					{
						label: i18n.t("Index structure"),
						onClick: () => {
							changeQueryParam("sortDir", "des");
							changeQueryParam("sortBy", "index");
						},
						checked: typeof sortBy !== "undefined" && (sortBy === "name" || sortBy === "index"),
					},
				],
			};
		} else {
			return {
				title,
				options: [
					{
						label: i18n.t("Score"),
						onClick: () => {
							changeQueryParam("sortDir", "des");
							changeQueryParam("sortBy", "score");
						},
						checked: typeof sortBy === "undefined" || sortBy === "score",
					},
					{
						label: i18n.t("Name"),
						onClick: () => {
							changeQueryParam("sortDir", "asc");
							changeQueryParam("sortBy", "name");
						},
						checked: typeof sortBy !== "undefined" && (sortBy === "name" || sortBy === "index"),
					},
				],
			};
		}
	};

	const sortDirectionSettings = {
		name: {
			title: i18n.t("Sort direction:"),
			options: [
				{
					label: "A-Z",
					onClick: () => changeQueryParam("sortDir", "asc"),
					checked: typeof sortDir === "undefined" || sortDir === "asc",
				},
				{
					label: "Z-A",
					onClick: () => changeQueryParam("sortDir", "des"),
					checked: typeof sortDir !== "undefined" && sortDir === "des",
				},
			],
		},
		score: {
			title: i18n.t("Sort direction:"),
			options: [
				{
					label: i18n.t("Descending"),
					onClick: () => changeQueryParam("sortDir", "des"),
					checked: typeof sortDir === "undefined" || sortDir === "des",
				},
				{
					label: i18n.t("Ascending"),
					onClick: () => changeQueryParam("sortDir", "asc"),
					checked: typeof sortDir !== "undefined" && sortDir === "asc",
				},
			],
		},
		index: {
			title: i18n.t("Sort direction:"),
			options: [
				{
					label: i18n.t("Descending"),
					onClick: () => changeQueryParam("sortDir", "des"),
					checked: typeof sortDir === "undefined" || sortDir === "des",
				},
				{
					label: i18n.t("Ascending"),
					onClick: () => changeQueryParam("sortDir", "asc"),
					checked: typeof sortDir !== "undefined" && sortDir === "asc",
				},
			],
		},
	};

	const sortDirectionParam = typeof urlParams.sortBy === "undefined" ? "score" : urlParams.sortBy;

	return (
		<BarChartPanel
			sortBySettings={sortBySettings()}
			sortDirectionSettings={sortDirectionSettings[sortDirectionParam]}
		/>
	);
};
