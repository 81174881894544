import React from "react";
import { Footer } from "../../components";
import logo from "../../../img/logo/full_logo_footer.png";
import i18n from "../../i18n";

export default () => {
	const seoLinks = [
		{
			link: {
				href: `${window.location.origin}/locations.html`,
				text: i18n.t("Countries"),
			},
		},
		{
			link: {
				href: `${window.location.origin}/measures.html`,
				text: i18n.t("Measures"),
			},
		}
	];
	const navigationLinks = [
		{
			link: {
				href: `${window.location.origin}/cookies.html`,
				text: i18n.t("Privacy & cookies"),
			},
		},
		{
			link: {
				href: `${window.location.origin}/media.html`,
				text: i18n.t("Media info"),
			},
		},
		{
			link: {
				href: `${window.location.origin}/updates.html`,
				text: i18n.t("Feature updates"),
			},
		},
	];
	const copyright = i18n.t('Copyright ©{{ current_year }} Mo Ibrahim Foundation', {'current_year': new Date().getFullYear()});

	return <Footer logo={logo} isEmbedPage={false} seoLinks={seoLinks} navigationLinks={navigationLinks} copyright={copyright} />;
};
