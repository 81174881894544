import React from "react";
import styles from "./styles.css";
import logo from "../../../img/logo/full_logo.png";
import { defaultVariables } from '../../referenceData';
import {useTranslation} from "react-i18next";
export default ({ currentYear }) => {
	const {t} = useTranslation();
	return (
		<div className={styles.screenshotPdfFooter}>
			<span className={styles.screenshotPdfFooterLogoWrapper}>
				<img className={styles.screenshotPdfFooterLogo} src="/dist/src/img/logo/logo.svg" alt="IIAG" />
			</span>
			<span className={styles.screenshotPdfFooterTextWrapper}>
				<p className={styles.screenshotPdfFooterText}>{t('{{index_year}} Ibrahim Index of African Governance', {index_year: defaultVariables.index_year })}</p>
				<span className={styles.screenshotPdfFooterDevider}>|</span>
				<a className={styles.screenshotPdfFooterLink} href="https://iiag.online">
					iiag.online
				</a>
			</span>
			<span className={styles.screenshotPdfFooterCopyright}>{t('Copyright ©{{ current_year }} Mo Ibrahim Foundation', { 'current_year':  new Date().getFullYear() } )}</span>
		</div>
	);
};
