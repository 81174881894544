// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper--1Rsjn {\n    position: relative;\n}[dir] .wrapper--1Rsjn {\n    margin-top: 20px;\n}\n\n.aboutLink--1q-UR {\n    position: absolute;\n    top: 15px;\n    color: black !important;\n}\n\n[dir=ltr] .aboutLink--1q-UR {\n    right: 15px;\n}\n\n[dir=rtl] .aboutLink--1q-UR {\n    left: 15px;\n}\n\n.aboutLink--1q-UR:hover {\n    color: black;\n}\n\n[dir] .aboutLink--1q-UR:hover {\n    cursor: pointer;\n}\n\n[dir=ltr] .flagIcon--PvvYQ {\n    margin-left: 10px;\n}\n\n[dir=rtl] .flagIcon--PvvYQ {\n    margin-right: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper--1Rsjn",
	"aboutLink": "aboutLink--1q-UR",
	"flagIcon": "flagIcon--PvvYQ"
};
module.exports = exports;
