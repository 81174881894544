export default (values) => {
  const val = [];
  Object.keys(values).map((obj) => {
    const v = {};
    const k = parseInt(Object.keys(values[obj])[0], 10);
    v.year = parseInt(Object.keys(values[obj])[0], 10);
    v.value = values[obj][k];
    val.push(v);
  });
  return val;
};
