// noinspection AllyPlainJsInspection

import '../../../../../legacyjs/custom/vars';
import {CorrelationLineChartTooltip} from '../../../../components';
import {
  getSelectedLocations,
  getSelectedSubjects,
  getSelectedYears,
  getSubjectTitle,
  getLocationTitle,
  generateObjectForGraph,
  isCitvoiceMeasure, getSelectedSubjectName, getSelectedLocationName, getExternalName, getExternalUnit
} from '../../../../actions';
import i18n from "../../../../i18n";
import regression from 'regression';
import Statistics from 'statistics.js';
import getSelectedExternals from "../../../../actions/getSelectedExternals";

const getChartScale = (data) => {

  let maxY = 0.05;
  let minY = 0;

  data.datasets.forEach((value) => {

    const thisMaxY = value.data.reduce((a, b) => Math.max(a, b));
    const thisMinY = value.data.reduce((a, b) => Math.min(a, b));

    if (thisMaxY > maxY) maxY = Number(thisMaxY);
    if (thisMinY < minY) minY = Number(thisMinY);
  });

  maxY *= 1.01;
  minY *= 1.01;

  return {
    y: {
      min: minY,
      max: maxY,
    },
  };
};

const getDataRecord = (state, year, subject, location) => {
  const indexedYml = state.indexedYml;
  const externalData = state.externalData;
  if (subject.type === 'external') {
    return externalData.find((item) => item.yr === year && item.identifier === subject.id && item.iso === location);
  } else {
    return indexedYml[year][subject.id][location];
  }
};


const getSubjectOrExternalName = (state, subject, with_unit) => {
  if (subject.type === 'external') {
    let name = getExternalName(state, subject.id);
    if (with_unit) {
      name = `${name} (${getExternalUnit(state, subject.id)})`;
    }
    return name;
  } else {
    return getSelectedSubjectName(state, subject.id);
  }
}

export const getChartTitle = (state) => {
  const subjects = resolveSubjects(state);
  const years = resolveYears();
  const chartTitle = i18n.t("Correlation of {{subject_1}} and {{subject_2}} for {{year1}} - {{year2}}", {
    'subject_1': `${getSubjectOrExternalName(state, subjects[0])}`,
    'subject_2': `${getSubjectOrExternalName(state, subjects[1])}`,
    'year1': years[0],
    'year2': years.pop(),
  });

  return chartTitle;
};


export const getTooltipTitle = (state) => {
  const subjects = resolveSubjects(state);
  return  i18n.t("Correlation of {{subject_1}} and {{subject_2}}", {
    'subject_1': `${getSubjectOrExternalName(state, subjects[0])}`,
    'subject_2': `${getSubjectOrExternalName(state, subjects[1])}`,

  });
};

function resolveSubjects(state) {
  const selectedSubjects = getSelectedSubjects(state);
  const selectedExternals = getSelectedExternals(state);
  const subjects = [...selectedSubjects.map((subject) => {
    return {
      id: subject,
      type: 'measure'
    };
  }), ...selectedExternals.map((external) => {
    return {
      id: external,
      type: 'external'
    };
  })];
  return subjects;
}

function resolveYears() {
  return getSelectedYears('range1from', 'range1to');
}

export default (state, urlParams) => {
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedLocations = getSelectedLocations(state);
  const allCountriesIso = state?.fullListOfCountries.map((country) => country.iso) || [];
  const collection = [];
  const fullContextCollection = [];
  const notApplicable = 'n/a';


  const dataset = [];
  const labels = [];
  const subjects = resolveSubjects(state);


  const backGroundColor = [];
  const borderColor = [];
  const hoverBackgroundColor = [];

  selectedYears.forEach((year) => {
    const statsData = [];
    allCountriesIso.forEach((location) => {
      const x = getDataRecord(state, year, subjects[0], location)?.v;
      const y = getDataRecord(state, year, subjects[1], location)?.v;

      if (!x || !y) return;

      if (typeof x === typeof y &&  // filter out one string & one number
      !isNaN(x) &&              // filter out `NaN`
      !isNaN(y) &&
      Math.abs(x) !== Infinity &&
      Math.abs(y) !== Infinity) {
        statsData.push({
          x: x,
          y: y,
        });
      }
    });

    if (!statsData.length) {
      return;
    }

    const stats = new Statistics(statsData, {x: 'metric', y: 'metric'});
    const correlation = stats.linearRegression('x', 'y');

    dataset.push(correlation.correlationCoefficient.toFixed(2))
  });

  const lineDataset = {
      backgroundColor: 'rgba(255,59,131, 0.5)',
      borderColor: 'rgba(255,59,131, 1)',
      borderWidth: 1,
      data: dataset,
      hitRadius: 8,
      hoverBackgroundColor: 'rgba(255,59,131, 1)',
      pointHoverRadius: 8,
      pointRadius: 0,
      type: 'line',
      annotationLine: false
  }


  const annotationLine = {
    id: 'annotationLine',
    afterDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip.dataPoints[0].dataset.annotationLine === true) {
        const { ctx } = chart;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.moveTo(chart.chartArea.left, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, activePoint.element.y);
        ctx.moveTo(activePoint.element.x, activePoint.element.y);
        ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
        ctx.lineWidth = 1;
        ctx.strokeStyle = chart.tooltip.dataPoints[0].dataset.borderColor;
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  const data = {
    datasets: [lineDataset],
    maintainAspectRatio: false,
    labels: selectedYears
  };


  const chartScale = getChartScale(data);

  return {
    data,
    plugins: [annotationLine],
    options: {
      maintainAspectRatio: false,
      responsive: true,
      clip: false,
      layout: {
        padding: {
          left: 0,
          right: 0,
          bottom: 35,
          top: 35,
          // autoPadding: true,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          title: {
            color: 'black',
            display: true,
            text: i18n.t('TIME (YEARS)'),
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          ticks: {
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
            color: 'black',
            autoSkip: false,
            maxRotation: 90,
            minRotation: 0,
            labelOffset: 0,
          },
        },
        y: {
          min: chartScale.y.min,
          max: chartScale.y.max,
          grid: {
            color(context) {
              if (context.tick.value === 0) {
                return '#000000';
              }
              return 'transparent';
            },
          },
          title: {
            display: true,
            text: 'r\u00B2',
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          display: true,
          ticks: {
            callback: (val) => Number(val).toFixed(2),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
              color: 'black',
            },
          },
        },
      },
      interaction: {
        intersect: true,
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            CorrelationLineChartTooltip({
              state,
              context,
              title: getTooltipTitle(state),
            });
          },
        },
      },
    },
  };
};
