import React from "react";
import { OverviewChildSubject } from "../../../../components";
import {
	extractValuePairs,
	getGroupAveragesForSubject,
	getLevelForMeasure,
	getSelectedSubjectName,
	getSpecificValue,
	getStyleLevelForMeasure,
	getSubjectColor,
	isACountry,
	isCitvoiceMeasure,
	prettifyNumber,
	roundToOne,
} from "../../../../actions";
import { useAppContext } from "../../../../context/AppState.jsx";
import { defaultVariables } from "../../../../referenceData";

export default ({ subject, location }) => {
	const [state, dispatch] = useAppContext();

	let data = { subject, location };
	data.level = getLevelForMeasure(state, subject);
	data.style_level = getStyleLevelForMeasure(state, subject);
	data.subject_name = getSelectedSubjectName(state, subject);
	data.subject_color = getSubjectColor({ state, subject });
	data.citvoice = isCitvoiceMeasure(state, subject);

	if (isACountry(location)) {
		data.score_rounded = roundToOne(getSpecificValue(state, location, subject, defaultVariables.latest_year));
	} else {
		// Group

		const val = extractValuePairs(
			getGroupAveragesForSubject(state, location, subject, [
				defaultVariables.earliest_year,
				defaultVariables.latest_year,
			])
		);
		data.score_rounded = val[1].value;
	}

	data.change = isNaN(data.score_rounded) ? "n/a" : prettifyNumber(data.score_rounded);
	data.change_rounded = isNaN(data.score_rounded) ? "0" : parseInt(data.score_rounded);
	//
	// childFragments.push(
	// 	childTemplate
	// 		.slice()
	// 		.replace('{ch}', isNaN(scoreRounded) ? 'n/a' : prettifyNumber(scoreRounded))
	// 		.replace('{ch_int}', isNaN(scoreRounded) ? '0' : parseInt(scoreRounded))
	// 		.replace('{child}', measureName)
	// 		.replace('{subject}', measure.s_id)
	// 		.replace('{childKey}', measure.uid)
	// 		.replace('{subject_color}', subjectColor(measure.s_id))
	// 		.replace('{subject_type}', isCitvoiceMeasure(measure.s_id) ? 'citvoice' : 'regular')
	// 		.replace(/{subject_level}/g, `level-${getStyleLevelForMeasure(measure.s_id)}`)
	// 		.replace(
	// 			'{hide_icon}',
	// 			level <= 1 || (!isCitvoiceMeasure(measure.s_id) && level <= 2) ? '' : 'uk-hidden',
	// 		),
	// );

	return <OverviewChildSubject data={data} />;
};
