import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {urls} from './referenceData';


import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en','fr','pt','ar'],
    ns: ['idp'],
    debug: true,
    saveMissing: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeparator: false,
    keySeparator: false,
    returnEmptyString: false,
    backend: {
      projectId: 'd5940bf0-20e8-4f72-8d11-e16a15553d79',
      apiKey: '24d214a5-8b57-4994-a510-07ced12513e4',
      version: urls.locizeVersion(),
      referenceLng: 'en',
      allowedAddOrUpdateHosts: ['idp-dev.mif.services','idp-test.mif.services'],
    }
  });


i18n.on('languageChanged', (lng) => {
  const langMap = {
    'en' : 1,
    'fr' : 2,
    'ar' : 3,
    'pt' : 4,
  };
  window.active_language = langMap[lng];
  document.dir = i18n.dir();
  if (document.documentElement.lang !== lng) {
    let target = document.querySelector(`link[rel=alternate][hreflang=${lng}]`);// eslint-disable-line i18next/no-literal-string
    if (target) {
      let href = target.getAttribute('href');
      if (href) {
        window.location.href = href;
      }
    }
  }
});

export default i18n;
