// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".screenshotPdfHeader--CQU28 {\n    height: 120px;\n    color: white;\n    display: flex;\n}[dir] .screenshotPdfHeader--CQU28 {\n    background: #0E3B83;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.screenshotPdfHeaderItem--38saW {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.screenshotPdfHeaderTitle--1AiWc {\n    font-size: 26px;\n    flex: 1;\n}\n\n[dir] .screenshotPdfHeaderTitle--1AiWc {\n    padding: 0 15px;\n}\n\n[dir=ltr] .screenshotPdfHeaderLogoWrapper--1XaLj {\n    padding-left: 30px;\n}\n\n[dir=rtl] .screenshotPdfHeaderLogoWrapper--1XaLj {\n    padding-right: 30px;\n}\n\n.screenshotPdfHeaderLinkWrapper--1EQBa {\n    height: 100%;\n    display: flex;\n    align-items: center;\n}\n\n[dir=ltr] .screenshotPdfHeaderLinkWrapper--1EQBa {\n    justify-content: right;\n    padding-right: 30px;\n}\n\n[dir=rtl] .screenshotPdfHeaderLinkWrapper--1EQBa {\n    justify-content: left;\n    padding-left: 30px;\n}\n.screenshotPdfHeaderLink--3plwz {\n    color: white;\n}\n[dir] .screenshotPdfHeaderLink--3plwz {\n    padding: 5px 10px;\n    border: 3px solid #f59201;\n}\n\n.screenshotPdfHeaderLink--3plwz:hover {\n    color: white;\n}\n", ""]);
// Exports
exports.locals = {
	"screenshotPdfHeader": "screenshotPdfHeader--CQU28",
	"screenshotPdfHeaderItem": "screenshotPdfHeaderItem--38saW",
	"screenshotPdfHeaderTitle": "screenshotPdfHeaderTitle--1AiWc",
	"screenshotPdfHeaderLogoWrapper": "screenshotPdfHeaderLogoWrapper--1XaLj",
	"screenshotPdfHeaderLinkWrapper": "screenshotPdfHeaderLinkWrapper--1EQBa",
	"screenshotPdfHeaderLink": "screenshotPdfHeaderLink--3plwz"
};
module.exports = exports;
