import React, { useMemo } from "react";
import { Chartjs, LoadingSpinner } from "../../../../components";

import { getChartConfig, getChartTitle } from "./utils";
import i18n from "../../../../i18n";

export default ({ state }) => {
	if (!state?.measureData) {
		return <LoadingSpinner />;
	}

	const chartTitle = useMemo(() => getChartTitle(state), [state.setUrlQueryParam, i18n.language]);

	const config = useMemo(() => getChartConfig(state, chartTitle), [state.setUrlQueryParam, i18n.language]);

	const isOnMobile = window.innerWidth < 768;

	const chartSize = useMemo(
		() => ({
			height: isOnMobile ? "1200px" : "637px",
		}),
		[state.setUrlQueryParam]
	);

	return <Chartjs chartConfig={config} chartId="barGraphSubject" chartSize={chartSize} />;
};
