// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modalWrapper--9xc2H {\n    max-width: 1070px;\n}\n", ""]);
// Exports
exports.locals = {
	"modalWrapper": "modalWrapper--9xc2H"
};
module.exports = exports;
