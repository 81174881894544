export default (allMeasures) => {
  const indexed_yml = {};

  allMeasures.map((value) => {
    indexed_yml[value.yr] || (indexed_yml[value.yr] = {});
    indexed_yml[value.yr][value.s_id]
      || (indexed_yml[value.yr][value.s_id] = {});
    indexed_yml[value.yr][value.s_id][value.iso] = value;
  });
  return indexed_yml;
};
