import React from "react";
import styles from "../../styles.css";
import subCategoriesStyles from "./styles.css";
import { default as Indicators } from "../Indicators/index.jsx";

import { isAllChildrensSelected, isSearchedPhrase } from "../../utils";
import {useTranslation} from "react-i18next";

export default ({
	isSimplifiedView,
	subjectId,
	subCategories,
	nestedListsToShow,
	showNestedLists,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	subjectParent,
	subjectType,
	selectedGroupsOfSubjects,
	onSelectAllSubjects,
	removeSelectedSubject,
	addSelectedSubject,
	queryParams,
	searchPhrase,
	onSubjectChange,
}) => {
	const {t} = useTranslation();
	return (
		<div
			className={`${isSimplifiedView ? "measure-expand-info-menu" : ""} ${subCategoriesStyles.measureExpand} ${
				!nestedListsToShow.includes(subjectId) ? "uk-hidden" : ""
			}`}
		>
			<div className="uk-grid-small uk-grid">
				{subCategories.map((subCategory, index) => {
					let subjectsGroupIds = [subCategory.id];
					let subjectsGroup = [subCategory.fullName];

					const getSubjectChildrens = (childrens) => {
						childrens.forEach((child) => {
							if (child.childrens) {
								getSubjectChildrens(child.childrens);
							}
							subjectsGroup.push(child.fullName);
							subjectsGroupIds.push(child.id);
						});
					};

					getSubjectChildrens(subCategory.childrens);

					if (subCategory.isCV) {
						subjectColor = subCategory.subjectColor;
					}

					return (
						<div key={`${subCategory.fullName.split(" ").join("")}_${index}`} className="uk-width-1-4@m">
							<ul className={`${subCategoriesStyles.subCategoryList} ${subCategoriesStyles.appMenuList}`}>
								<img
									className={`${subCategoriesStyles.listIcon}`}
									src={`/img/icons/${subCategory.id}.svg`}
									alt={subCategory.fullName}
								/>
								<li className={subCategoriesStyles.listItem}>
									<a
										onClick={(e) => {
											if (isSimplifiedView && onSubjectChange) {
												e.preventDefault();
												onSubjectChange(subCategory.id);
											}
											if (!isSimplifiedView) {
												e.preventDefault();
												onSubjectSelect(subCategory.id);
											}
										}}
										href={`/measures/${subCategory.id.toLowerCase()}.html`}
										className={`${
											isSearchedPhrase({
												subjectName: subCategory.fullName,
												phrase: searchPhrase,
											})
												? styles.highlightSearchedWord
												: ""
										} ${subCategoriesStyles.subjectNameLevel2} ${
											!isSimplifiedView && selectedSubjects.includes(subCategory.id)
												? `${subCategoriesStyles.selectedSubMeasure} ${subjectParent}`
												: ""
										}`}
										style={
											!isSimplifiedView && selectedSubjects.includes(subCategory.id)
												? {
														background: `rgba(${subjectColor}, 0.25)`,
														border: `1px solid rgb(${subjectColor})`,
												  }
												: null
										}
									>
										{subCategory.fullName}
									</a>
								</li>
								{subCategory?.childrens ? (
									<Indicators
										isSimplifiedView={isSimplifiedView}
										indicators={subCategory.childrens}
										subCategoryId={subCategory.uid}
										nestedListsToShow={nestedListsToShow}
										showNestedLists={showNestedLists}
										selectedSubjects={selectedSubjects}
										onSubjectSelect={onSubjectSelect}
										subjectColor={subjectColor}
										subjectParent={subjectParent}
										queryParams={queryParams}
										searchPhrase={searchPhrase}
										onSubjectChange={onSubjectChange}
									/>
								) : null}
								{!isSimplifiedView ? (
									<li className={`level-3 level-3-select-all ${subCategoriesStyles.level3SelectAll}`}>
										<a
											onClick={(e) => {
												e.preventDefault();

												if (!isAllChildrensSelected(subjectsGroupIds, selectedSubjects)) {
													addSelectedSubject({ subjectsGroup: subjectsGroupIds, selectedSubjects });
												} else {
													removeSelectedSubject({ subjectsGroup: subjectsGroupIds, selectedSubjects });
												}
											}}
											href="#"
											className={`${
												isAllChildrensSelected(subjectsGroupIds, selectedSubjects)
													? `${styles.selectedAll} ${subjectParent}`
													: ""
											}`}
											style={
												isAllChildrensSelected(subjectsGroupIds, selectedSubjects)
													? {
															background: `rgba(${subjectColor}, 0.25)`,
															border: `1px solid rgb(${subjectColor})`,
													  }
													: null
											}
										>
											{t("Select All")}
										</a>
									</li>
								) : null}
							</ul>
						</div>
					);
				})}
			</div>
		</div>
	);
};
