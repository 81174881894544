// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .backgroundImage--3sUQV {\n    background-image: url(\"/dist/src/img/backgrounds/grey_background.png\");\n    background-size: cover;\n    padding-bottom: 70px;\n}\n\n.backgroundWhite--AtHOs {\n    position: relative;\n}\n\n[dir] .backgroundWhite--AtHOs {\n    background-color: white;\n    border-radius: 14px;\n}\n\n.backgroundOffWhite--3LxCV {\n    position: relative;\n    overflow: auto;\n}\n\n[dir] .backgroundOffWhite--3LxCV {\n    background-color: #f9f7f5;\n    padding-bottom: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"backgroundImage": "backgroundImage--3sUQV",
	"backgroundWhite": "backgroundWhite--AtHOs",
	"backgroundOffWhite": "backgroundOffWhite--3LxCV"
};
module.exports = exports;
