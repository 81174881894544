const getTopLevelParent = (state, parentId) => {
  const item = state.fullListOfMeasures.filter(
    (measure) => measure.s_id === parentId,
  )[0];

  if (!parentId) {
    return false;
  }

  if (!item) {
    return false;
  }

  if (item.lvl == 0) {
    return item;
  }

  const parent = state.fullListOfMeasures.find((el) => el.s_id == item.ps_id);
  if (!parent || parent.s_id == parentId) {
    // should never happen, but best not to crash
    return state.fullListOfMeasures.find((e) => e.s_id == 'GOVERNANCE');
  }
  return getTopLevelParent(state, parent.s_id);
};

export default (state, mid) => getTopLevelParent(state, mid);
