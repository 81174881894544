import React, { createContext, useContext, useState } from "react";

export const MenuContext = createContext(null);

export const MenuProvider = ({ children }) => {
	const [Menu, setMenu] = useState(false);

	const closeMenu = () => {
		setMenu(false);
	};

	const showMenu = (menu) => {
		setMenu(menu);
	};

	return (
		<MenuContext.Provider value={[showMenu, closeMenu]}>
			{Menu}
			{children}
		</MenuContext.Provider>
	);
};

export const useMenuContext = () => {
	return useContext(MenuContext);
};
