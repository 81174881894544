import React, { useMemo } from "react";
import { Chartjs } from "../../../../components";
import { default as getChartConfig, getChartTitle } from "./utils";
import { setDefaultUrlQueryParamsForSubview } from "../../../../actions";

export default ({ state, urlParams, isTakingScreenshot }) => {
	if (!state?.indexedYml) {
		return null;
	}

	if (
		typeof urlParams.range1from === "undefined" ||
		typeof urlParams.range1to === "undefined" ||
		typeof urlParams.range2from === "undefined" ||
		typeof urlParams.range2to === "undefined"
	) {
		setDefaultUrlQueryParamsForSubview(urlParams);
	}

	const chartConfig = useMemo(() => getChartConfig(state, urlParams), [state.setUrlQueryParam, i18n.language]);

	const chartTitle = useMemo(() => getChartTitle(state), [state.setUrlQueryParam, i18n.language]);

	const chartId = isTakingScreenshot ? "screenshotBarChart" : "scatter-chart";

	const chartSize = useMemo(
		() => ({
			height: "745px",
		}),
		[state.setUrlQueryParam]
	);

	return (
		<Chartjs
			chartTitle={chartTitle}
			chartConfig={chartConfig}
			chartId={chartId}
			chartSize={chartSize}
			isTakingScreenshot={isTakingScreenshot}
		/>
	);
};
