import React from "react";
import { setUrlQueryParam } from "../../../../../../actions";
import { SignificantChangesPanel } from "../../../../../../components/SettingsPanel/subComponents";

export default ({ urlParams, dispatch }) => {
	let trends = null;

	const dropdowns = [
		{
			text: "Significant Changes",
			onChange: (value) => {
				dispatch({
					type: "setUrlQueryParam",
					action: setUrlQueryParam({ name: "significantChange", value: value }),
				});
			},
			options: ["95", "90", "85", "80"],
		},
	];

	return <SignificantChangesPanel dropdowns={dropdowns} />;
};
