export default ({ image }) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.style.marginRight = '10px';
  canvas.classList.add('screenshot-icon');

  ctx.canvas.width = image.offsetWidth;
  ctx.canvas.height = image.offsetHeight;

  try {
    ctx.drawImage(image, 0, 0);
  } catch (err) {
    console.warn(err);
  }

  image.parentElement.append(canvas);
  image.remove();
};
