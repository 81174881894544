import React, { useMemo } from "react";
import { LoadingSpinner, InfoPagesDataTable } from "../../../../components";
import {
	getSubjectColor,
	prettifyNumber,
	getLevelForMeasure,
	isCitvoiceMeasure,
	getStyleLevelForMeasure,
	getSelectedSubjectName,
	roundToOne,
	getTopLevelParent, scoreChangeFormat, scoreFormat,
} from "../../../../actions";
import { defaultVariables } from "../../../../referenceData";
import { useAppContext } from "../../../../context/AppState.jsx";
import i18n from "../../../../i18n";

const subjectHasIcon = (mid, state) => {
	var parent = getTopLevelParent(state, mid);
	if (parent.s_id == "CITVOICE") {
		return getLevelForMeasure(state, mid) < 2;
	}
	return getLevelForMeasure(state, mid) < 3;
};

const getCellClass = (value) => {
	if (value === "n/a" || Number(value) === 0) {
		return;
	}
	if (value > 0) {
		return "positive";
	}
	if (value < 0) {
		return "negative";
	}
};

const createRows = (data, state) => {
	return data.map((row) => {
		const hasIcon = subjectHasIcon(row.s_id, state);
		return {
			props: {
				class: `level-${getStyleLevelForMeasure(state, row.s_id)}`,
				subjectColor: getSubjectColor({ state, subject: row.s_id }),
				icon: hasIcon ? `/img/icons/${row.s_id}.svg` : null,
			},
			columns: [
				{
					props: {
						hasIcon,
						class: "",
						styles: hasIcon
							? { "--ttt-icon": `url("/img/icons/${row.s_id}.svg")` }
							: { "--ttt-color": getSubjectColor({ state, subject: row.s_id }) },
					},
					value: getSelectedSubjectName(state, row.s_id),
				},
				{ props: {}, value: scoreFormat(row.v) },
				{
					props: { class: getCellClass(roundToOne(row.change).toFixed(1)) },
					value: scoreChangeFormat(row.change),
				},
			],
		};
	});
};

export default ({ state }) => {
	const [_, dispatch] = useAppContext();

	if (!state?.indexedYml) {
		return <LoadingSpinner />;
	}
	const measureData = {};
	const selectedLocation = state.selectedLocation;
	const firstYear = defaultVariables.earliest_year;
	const lastYear = defaultVariables.latest_year;

	Object.keys(state.indexedYml).forEach((yr) => {
		measureData[yr] = Object.values(state.indexedYml[yr])
			.map((el) => Object.values(el).find((e2) => e2.iso === selectedLocation))
			.filter(
				(e) =>
					e.v > -1 &&
					(!state.shouldShowAllMeasures
						? getLevelForMeasure(state, e.s_id) === 3
						: getLevelForMeasure(state, e.s_id)) &&
					!isCitvoiceMeasure(state, e.s_id)
			)
			.sort((e1, e2) => e2.v - e1.v);
	});

	measureData[lastYear] = measureData[lastYear].map((el) => {
		const subject = el.s_id;
		const location = el.iso;
		if (el.v === -1 || state.indexedYml[firstYear][subject][location].v === -1) {
			el.change = "n/a";
		} else {
			el.change = (
				Number(el.v).toFixed(1) - Number(state.indexedYml[firstYear][el.s_id][el.iso].v).toFixed(1)
			).toFixed(1);
		}
		return el;
	});

	const measureSortFn =
		(prop = "v", dir = 1) =>
		(e1, e2) => {
			let e1v = roundToOne(e1[prop]);
			let e2v = roundToOne(e2[prop]);
			if (e2v === e1v && e2.lvl === e1.lvl) {
				const indexa = state.fullListOfMeasures.findIndex((meas) => meas.s_id === e1.s_id);
				const indexb = state.fullListOfMeasures.findIndex((meas) => meas.s_id === e2.s_id);
				return indexa - indexb;
			}
			if (e2v === e1v) {
				return e1.lvl - e2.lvl;
			}
			if (dir === 1) {
				return e2v - e1v;
			}
			return e1v - e2v;
		};

	measureData[lastYear].sort(measureSortFn());
	const topTenHighestData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(tth, topTenHighestData, state);

	measureData[lastYear].sort(measureSortFn("v", -1));
	const topTenLowestData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(ttl, topTenLowestData, state);

	// and then we want the nas removed from consideration for the most improved/deteriorated
	measureData[lastYear] = measureData[lastYear].filter((e) => e.change !== "n/a");

	measureData[lastYear].sort(measureSortFn("change"));
	const topImprovementsData = createRows(measureData[lastYear].slice(0, 10), state);
	// updateTable(ti, topImprovementsData, state);

	measureData[lastYear].sort(measureSortFn("change", -1));
	const topDeteriorationsData = createRows(measureData[lastYear].slice(0, 10), state);

	const tableData = useMemo(
		() => [
			{
				name: "score",
				text: i18n.t("Scores"),
				filters: [
					{
						name: i18n.t("Show indicators only"),
						onClick: () =>
							dispatch({
								type: "shouldShowAllMeasures",
								payload: false,
							}),
						checked: !state.shouldShowAllMeasures,
					},
					{
						name: i18n.t("Show all measures"),
						onClick: () =>
							dispatch({
								type: "shouldShowAllMeasures",
								payload: true,
							}),
						checked: state.shouldShowAllMeasures,
					},
				],
				tables: [
					{
						name: "topTenHighestData",
						title: state.shouldShowAllMeasures ? i18n.t("Top 10 measures") : i18n.t("Top 10 Indicators"),
						rows: topTenHighestData,
					},
					{
						name: "topTenLowestData",
						title: state.shouldShowAllMeasures ? i18n.t("Bottom 10 measures") : i18n.t("Bottom 10 Indicators"),
						rows: topTenLowestData,
					},
				],
			},
			{
				name: "trend",
				text: i18n.t("Trends"),
				filters: [
					{
						name: i18n.t("Show indicators only"),
						onClick: () =>
							dispatch({
								type: "shouldShowAllMeasures",
								payload: false,
							}),
						checked: !state.shouldShowAllMeasures,
					},
					{
						name: i18n.t("Show all measures"),
						onClick: () =>
							dispatch({
								type: "shouldShowAllMeasures",
								payload: true,
							}),
						checked: state.shouldShowAllMeasures,
					},
				],
				tables: [
					{
						name: "topImprovementsData",
						title: state.shouldShowAllMeasures ? i18n.t("Most improved measures") : i18n.t("Most improved Indicators"),
						rows: topImprovementsData,
					},
					{
						name: "topDeteriorationsData",
						title: state.shouldShowAllMeasures ? i18n.t("Most deteriorated measures") : i18n.t("Most deteriorated Indicators"),
						rows: topDeteriorationsData,
					},
				],
			},
		],
		[state.shouldShowAllMeasures, i18n.language]
	);

	const valueHeadings = [
		[  i18n.t("Score"), defaultVariables.latest_year ],
		[  i18n.t("Change"), defaultVariables.year_range_short]
	];

	return <InfoPagesDataTable tableData={tableData} type="location" valueHeadings={valueHeadings} />;
};
