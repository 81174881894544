// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .mapContainer--37ge4 {\n    padding-top: 20px;\n}\n\n.mapWrapper--1ohbd {\n    position: relative;\n}\n", ""]);
// Exports
exports.locals = {
	"mapContainer": "mapContainer--37ge4",
	"mapWrapper": "mapWrapper--1ohbd"
};
module.exports = exports;
