import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({ urlParams, axisLegend, trends, onTrendsClick, isTakingScreenshot, onInfoClick }) => {
	const { highlightedTrend } = urlParams;
	const {t} = useTranslation();
	return (
		<div className={`${styles.wrapper} uk-width-1-1 trends-explanation-parent uk-overflow-auto`}>
			<div className="trends-byline">
				<div>
					<i uk-icon="arrow-left" /> <span>{axisLegend.left}</span>
				</div>
				<div>
					<span>{axisLegend.right}</span> <i uk-icon="arrow-right" />
				</div>
			</div>
			<table className="trends_left_table uk-width-1-1" cellPadding={0} cellSpacing={0}>
				<tbody>
					<tr>
						<td className="trends_left_table_key">
							<span>
								{t("Key")}:
								{!isTakingScreenshot ? (
									<span
										onClick={() => onInfoClick()}
										uk-icon="info"
										className={`trends-info-icon uk-icon-information ${styles.infoIcon}`}
									/>
								) : null}
							</span>
						</td>
						{trends.map((trend, index) => (
							<td
								key={`${trend.text.split(" ").join("")}_${index}`}
								onClick={() => {
									onTrendsClick(trend.text, urlParams);
								}}
								className={
									typeof highlightedTrend !== "undefined" && highlightedTrend !== trend.text
										? styles.fadeTrend
										: ""
								}
							>
								<div className="trends_progress_bar">
									<div className={`progress-bar ${styles.trendsCell} ${styles[trend.class]}`}>
										<span className="sr-only trends_score">{trend.text}</span>
									</div>
								</div>
							</td>
						))}
					</tr>
					<tr>
						<td />
						{trends.map((trend, index) => (
							<td key={`${trend.text.split(" ").join("")}_${index}`}>
								<span>{trend.text}</span>
							</td>
						))}
					</tr>
				</tbody>
			</table>
		</div>
	);
};
