import React from "react";
import styles from "./styles.css";

export default ({ legend }) => {
	return (
		<div className={styles.legendWrapper}>
			{legend.map((item) => (
				<div className={styles.itemWrapper}>
					<span className={`${styles[item.class]} ${styles.indicator}`} />
					<span>{item.label}</span>
				</div>
			))}
		</div>
	);
};
