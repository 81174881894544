import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./styles.css";
import { default as LoadingSpinner } from "../LoadingSpinner/index.jsx";
import {useTranslation} from "react-i18next";

const previousArrow = (onClickHandler, label, t) => {
	return (
		<a className={styles.previousArrow} type="button" onClick={onClickHandler} title={label}>
			<span className="sr-only">{t("Previous")}</span>
		</a>
	);
}

const nextArrow = (onClickHandler, label, t) => {
	return (
		<a className={styles.nextArrow} type="button" onClick={onClickHandler} title={label}>
			<span className="sr-only">{t("Next")}</span>
		</a>
	);
}

export default ({ slides }) => {
	const {t} = useTranslation();
	return (
		<div className={styles.carouselContentWrapper} dir="ltr">
			{slides.length === 0 ? (
				<LoadingSpinner />
			) : (
				<>
					<Carousel
						className={`uk-flex uk-flex-wrap uk-margin-remove uk-padding-small`}
						autoPlay={true}
						axis={"horizontal"}
						dynamicHeight={false}
						interval={5000}
						showStatus={false}
						showThumbs={false}
						infiniteLoop={true}
						showArrows={true}
						showIndicators={false}
						renderArrowPrev={(onClickHandler, _, label) => previousArrow(onClickHandler, label, t)}
						renderArrowNext={(onClickHandler, _, label) => nextArrow(onClickHandler, label, t)}
					>
						{slides.map((slide, index) => (
							<div className={styles.carouselItem} key={index}>
								<h2 className={styles.title}>{slide.title}</h2>
								<h6 className={styles.description}>{slide.description}</h6>
								<a
									className={`btn btn-outline-primary ${styles.viewButton}`}
									href={`app.html?v=${slide.link}`}
								>
									{t("View now")}
								</a>
							</div>
						))}
					</Carousel>
				</>
			)}
		</div>
	);
}
