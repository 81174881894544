// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button--101jj {\n    display: inline-block;\n}\n\n.navigateTo--2YMP5 {\n    text-decoration: underline;\n}\n\n[dir=ltr] .navigateTo--2YMP5 {\n    margin-left: 5px;\n    margin-right: 10px;\n}\n\n[dir=rtl] .navigateTo--2YMP5 {\n    margin-right: 5px;\n    margin-left: 10px;\n}\n\n.cookiealert--3apzY {\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n    z-index: 41;\n    opacity: 0;\n    visibility: hidden;\n    transition: all 500ms ease-out;\n    color: #ecf0f1;\n}\n\n[dir] .cookiealert--3apzY {\n    margin: 0 !important;\n    border-radius: 0;\n    transform: translateY(100%);\n    padding: 15px 5px;\n    background: #212327;\n    text-align: center;\n}\n\n[dir=ltr] .cookiealert--3apzY {\n    left: 0;\n}\n\n[dir=rtl] .cookiealert--3apzY {\n    right: 0;\n}\n\n.show--2iz-o {\n    opacity: 1;\n    visibility: visible;\n}\n\n[dir] .show--2iz-o {\n    transform: translateY(0%);\n    transition-delay: 1000ms;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "button--101jj",
	"navigateTo": "navigateTo--2YMP5",
	"cookiealert": "cookiealert--3apzY",
	"show": "show--2iz-o"
};
module.exports = exports;
