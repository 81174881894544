const getItemsWithParent = (state, parent_id) => state.fullListOfMeasures.filter((n) => n.ps_id == parent_id);

const getItem = (state, s_id) => state.fullListOfMeasures.filter((n) => n.s_id == s_id);

const getDescendants = (state, parent_id, max_level, and_self = false) => {
  let out = [];
  const self = getItem(state, parent_id)[0];
  if (and_self) {
    out.push(self);
  }
  const children = getItemsWithParent(state, parent_id);
  out = out.concat(children);

  const _out = children.map((e) => getDescendants(state, e.s_id, max_level).filter((i) => i.lvl <= max_level));
  out = out.concat(..._out);

  return out;
};

export default getDescendants;
