import { default as getSelectedYears } from '../getSelectedYears';
import { default as getSelectedSubjects } from '../getSelectedSubjects';
import { default as getMinScoreForGroup } from '../getMinScoreForGroup';
import { default as getGroupCodeFromName } from '../getGroupCodeFromName';

export default (state) => {
  // Put relevant data in Processing subset
  const selectedYears = getSelectedYears('range1from', 'range1to');
  const selectedSubjects = getSelectedSubjects(state);

  const minimums = [];
  $.each(selectedYears, (index, obj) => {
    const pos = {};
    const value = getMinScoreForGroup(
      state,
      selectedSubjects[0],
      obj,
      getGroupCodeFromName(state, 'Africa'),
    );
    // pos[obj] = (value == -1) ? 'n/a' : value;

    // Exception for ARTProv - no reporting of values until 2010
    if (selectedSubjects[0] == 'ARTProv' && obj >= 2010) {
      pos[obj] = value;
      minimums.push(pos);
    } else if (value != -1 && selectedSubjects[0] != 'ARTProv') {
      pos[obj] = value;
      minimums.push(pos);
    } else {
      pos[obj] = null;
      minimums.push(pos);
    }
  });
  return minimums;
};
