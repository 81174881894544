// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modalWrapper--3ia0f {\n    max-width: 650px !important;\n    top: 0 !important;\n    opacity: 0;\n    overflow-y: auto;\n}[dir] .modalWrapper--3ia0f {\n    transform: translateY(0) !important;\n}[dir=ltr] .modalWrapper--3ia0f {\n    animation: show--2uI9f 0.2s linear 0s 1 normal forwards;\n}[dir=rtl] .modalWrapper--3ia0f {\n    animation: show--2uI9f 0.2s linear 0s 1 normal forwards;\n}\n\n[dir] .description--2c_un {\n    margin-bottom: 16px;\n}\n\n@keyframes show--2uI9f {\n    from {\n        transform: translateY(-100px);\n        opacity: 0;\n    }\n    to {\n        transform: translateY(0);\n        opacity: 1;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"modalWrapper": "modalWrapper--3ia0f",
	"show": "show--2uI9f",
	"description": "description--2c_un"
};
module.exports = exports;
