import { getAvgScoreForGroup, roundToOne } from '../index';

export default (state, groupCode, subject, selected_years) => {
  const averages = [];
  selected_years.map((yr) => {
    const pos = {};
    let sc = roundToOne(getAvgScoreForGroup(state, subject, yr, groupCode));
    sc = isNaN(sc) ? -1 : sc;
    pos[yr] = sc;
    averages.push(pos);
  });

  return averages;
};
