import getNonCvLevelItems from '../getNonCvLevelItems';
import getCvLevelItems from '../getCvLevelItems';

export default ({ state, subjects }) => {
  const collapsedSubjects = [];
  const subjectsToRemove = [];

  // Magic numbers
  // 4 is maximum used measure level
  // skipping 0 since it's always GOVERNANCE

  for (let level = 1; level <= 4; level++) {
    const allSubjectsInLevel = getNonCvLevelItems(state, level).map(
      (e) => e.s_id,
    );

    if (allSubjectsInLevel.every((subject) => subjects.includes(subject))) {
      collapsedSubjects.push(`_${level}`);
      subjectsToRemove.push(
        ...[...subjects].filter((subject) => allSubjectsInLevel.includes(subject)),
      );
    }

    const allCvSubjectsInLevel = getCvLevelItems(state, level).map(
      (e) => e.s_id,
    );

    if (allCvSubjectsInLevel.every((subject) => subjects.includes(subject))) {
      collapsedSubjects.push(`.${level}`);
      subjectsToRemove.push(
        ...[...subjects].filter((subject) => allCvSubjectsInLevel.includes(subject)),
      );
    }
  }

  if (subjectsToRemove.length !== 0) {
    const newSubjectArray = subjects.filter(
      (subject) => !subjectsToRemove.includes(subject),
    );
    newSubjectArray.push(...collapsedSubjects);
    return newSubjectArray;
  }

  return [...subjects];
};
