import getSelectedSubjects from '../getSelectedSubjects';
import getSelectedSubjectName from '../getSelectedSubjectName';
import i18n from "../../i18n";

export default (state) => {
  const selectedSubjects = getSelectedSubjects(state);
  if (selectedSubjects.length === 0) {
    return i18n.t('No measures selected');
  } else if (selectedSubjects.length === 1) {
    return getSelectedSubjectName(state, selectedSubjects[0]);
  } else {
    return i18n.t("{{subject}}, and {{other_count}} other", {
      subject: getSelectedSubjectName(state, selectedSubjects[0]),
      other_count: selectedSubjects.length - 1,
      count: selectedSubjects.length - 1
    });
  }
};
