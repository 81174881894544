import { measureColors } from '../../referenceData';

let out = '#dddddd';

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(// eslint-disable-line i18next/no-literal-string
      result[3],
      16,
    )}`
    : null;
};

const getSubjectColor = (state, subject, toRgb) => {
  const item = state.fullListOfMeasures.filter(
    (measure) => measure.s_id === subject,
  );
  if (measureColors[subject]) {
    out = measureColors[subject];
  } else if (item.length && item[0].ps_id && item[0].ps_id !== item[0].s_id) {
    out = getSubjectColor(state, item[0].ps_id);
  }
  return toRgb ? hexToRgb(out) : out;
};

export default ({ state, subject, toRgb = false }) => getSubjectColor(state, subject, toRgb);
