import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({ description, navigateTo, onDismiss }) => {
	const {t} = useTranslation();
	return (
		<div className={` ${styles.cookiealert} ${styles.show}`} role="alert">
			<b className={`${styles.button}`}>
				{description}
				<a href="/cookies.html" className={`${styles.navigateTo}`} target="_blank">
					{navigateTo.label}
				</a>
				<button
					onClick={() => onDismiss()}
					type="button"
					className={`${styles.button} btn btn-primary btn-sm acceptcookies`}
				>
					{t("OK")}
				</button>
			</b>
		</div>
	);
};
