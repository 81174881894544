import React from "react";
import styles from "./styles.css";
import logo from "../../../img/logo.png";
import {useTranslation} from "react-i18next";

export default () => {
	const { t } = useTranslation();
	return (
		<div className={styles.screenshotPdfHeader}>
			<span className={`${styles.screenshotPdfHeaderItem} ${styles.screenshotPdfHeaderLogoWrapper}`}>
				<img className={styles.screenshotPdfHeaderLogo} src={logo} alt="IIAG" />
			</span>
			<span className={`${styles.screenshotPdfHeaderItem} ${styles.screenshotPdfHeaderTitle}`}>
				{t('The Most Comprehensive dataset of African Governance.')}
			</span>
			<span className={`${styles.screenshotPdfHeaderItem} ${styles.screenshotPdfHeaderLinkWrapper}`}>
				<a className={styles.screenshotPdfHeaderLink} href="https://iiag.online">
					{t("VISIT IIAG.ONLINE")}
				</a>
			</span>
		</div>
	);
};
