import React from "react";
import { Information } from "../../../../components/Modals";
import i18n from "../../../../i18n";

export default () => {
	const title = i18n.t("About this chart");
	const description = [
		i18n.t("Radar (or spider) charts are used to plot one or more groups of values over multiple common variables. Each variable has its own axis, and all axes are joined in the centre of the figure. Data from a single set of results are plotted along each axis and connected to form a polygon."),
		i18n.t("This radial chart compares the 16 IIAG sub-category scores in latest data year (2019) for the location of choice, alongside the African average scores."),
	];

	return <Information title={title} description={description} />;
};
