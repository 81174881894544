import { default as getCountriesInGroup } from '../getCountriesInGroup';
import { default as getSpecificValue } from '../getSpecificValue';
import { default as roundToOne } from '../roundToOne';

export default (state, measure, year, groupCode) => {
  const countries = getCountriesInGroup(state, groupCode);
  let max = -1;
  countries.forEach((object) => {
    const v = roundToOne(getSpecificValue(state, object.iso, measure, year));
    max = v > max ? v : max;
  });
  return max;
};
