export const cached_ranks = {};

export default (state, measure, year, country) => {
  const { indexedYml } = state;
  cached_ranks[country] || (cached_ranks[country] = {});
  cached_ranks[country][measure] || (cached_ranks[country][measure] = {});
  if (!cached_ranks[country][measure][year]) {
    if (indexedYml[year][measure]) {
      const measureYrData = Object.values(indexedYml[year][measure]);
      // Order the scores
      measureYrData.map((e) => (e.vrnd = Math.round(e.v * 10) / 10));
      measureYrData.sort((a, b) => b.v - a.v);

      // cache out all the scores
      let lastScore = -1;
      let lastRank = -1;
      measureYrData.forEach((el, index) => {
        cached_ranks[el.iso] || (cached_ranks[el.iso] = {});
        cached_ranks[el.iso][el.s_id] || (cached_ranks[el.iso][el.s_id] = {});
        if (el.vrnd == -1) {
          cached_ranks[el.iso][el.s_id][el.yr] = 'n/a';
        } else if (el.vrnd == lastScore) {
          cached_ranks[el.iso][el.s_id][el.yr] = lastRank;
        } else {
          cached_ranks[el.iso][el.s_id][el.yr] = index + 1;
          lastRank = index + 1;
        }
        lastScore = el.vrnd;
      });
    }
    if (!cached_ranks[country][measure][year]) {
      cached_ranks[country][measure][year] = 'n/a';
    }
  }

  return cached_ranks[country][measure][year];
};
