import React, { useMemo } from "react";
import {
	getWorldMapJson,
	getSelectedSubjects,
	getSelectedLocations,
	getUrlQueryParams,
	getSubjectTitle,
	getLocationTitle,
	getSubjectColor,
	roundToOne,
	getSpecificValue,
	getStyleLevelForMeasure,
	isCitvoiceMeasure,
	getClassificationDescription, isACountry, getAvgScoreForGroup, scoreFormat, getSelectedYears,
} from "../../../../actions";
import { removeUrlQueryParam, setUrlQueryParam, setDefaultUrlQueryParamsForSubview } from "../../../../actions";
import { TooManySelectionsPlaceholder, MapLegend, MapChart } from "../../../../components";
import { useAppContext } from "../../../../context/AppState.jsx";
import { defaultVariables } from "../../../../referenceData";
import { createSingleTrend } from "../Overview/utils.js";
import { default as OverviewMultiLocationSingleSubjectInnerLocation } from "../OverviewMultiLocationSingleSubjectInnerLocation/index.jsx";
import { useModalContext } from "../../../../context/modalContext.jsx";
import { ClassificationInfo } from "../../../../components/Modals";
import i18n from "../../../../i18n";

const trends = () => [
	{
		text: i18n.t("Increasing Deterioration"),
		class: "trendsIncreasingDeterioration",
	},
	{
		text: i18n.t("Slowing Deterioration"),
		class: "trendsSlowingDeterioration",
	},
	{
		text: i18n.t("Warning Signs"),
		class: "trendsWarningSigns",
	},
	{
		text: i18n.t("Bouncing Back"),
		class: "trendsBouncingBack",
	},
	{
		text: i18n.t("Slowing Improvement"),
		class: "trendsSlowingImprovement",
	},
	{
		text: i18n.t("Increasing Improvement"),
		class: "trendsIncreasingImprovement",
	},
];

export default ({ urlParams, isTakingScreenshot }) => {
	const [state, dispatch] = useAppContext();
	const [showModal] = useModalContext();

	if (typeof urlParams.range1to === "undefined") {
		setDefaultUrlQueryParamsForSubview(urlParams);
	}

	const selectedSubjects = getSelectedSubjects(state);
	const selectedLocations = getSelectedLocations(state);

	urlParams = getUrlQueryParams();
	const years = getSelectedYears('range1from', 'range1to');
	const range1from = years[0];
	const range1to = years[years.length - 1];
	const years2 = getSelectedYears('range2from', 'range2to');
	const range2from = years2[0];
	const range2to = years2[years2.length - 1];
	if (selectedSubjects.length > 1) {
		const title = i18n.t("Too Many Measures");
		const description = [
			i18n.t("Sorry, it is currently only possible to view the trends map for a single measure."),
			i18n.t("Please change your selection to select a single measure."),
		];
		return <TooManySelectionsPlaceholder title={title} description={description} />;
	}
	const selectedSubjectColor = getSubjectColor({ state, subject: selectedSubjects[0], toRgb: true });

	const results = createSingleTrend(selectedLocations[0], selectedSubjects[0], null, urlParams.subview, false);

	const legendProps = useMemo(() => {
		const props = {};
		props.selectedSubjectColor = selectedSubjectColor;
		if (urlParams.subview === "trend") {
			props.trends = trends();
			props.axisLegend = {
				left: i18n.t("Deterioration"),
				right: i18n.t("Improvement"),
			};
			props.onTrendsClick = (trend, urlParams) => {
				const { highlightedTrend } = urlParams;
				if (typeof highlightedTrend !== "undefined" && highlightedTrend === trend) {
					removeUrlQueryParam({ name: "highlightedTrend" });
				} else {
					setUrlQueryParam({ name: "highlightedTrend", value: trend });
				}
				dispatch({
					type: "getUrlQueryParams",
					payload: JSON.stringify(getUrlQueryParams()),
				});
			};

			props.onInfoClick = () => {
				showModal(<ClassificationInfo title={i18n.t("trend classifications")} />);
			};
		}
		return props;
	}, [state.setUrlQueryParam, i18n.language]);

	const mapTitle = `${i18n.t("{{subject}} for {{location}}", {
			subject: getSubjectTitle(state),
			location: getLocationTitle(state)
		  })}${
		Number(range1to) !== defaultVariables.latest_year && urlParams.subview !== "trend"
			? ` (${range1to})`
			: ""
	}`;

	const sidePopup = useMemo(() => {
		const props = {};
		props.title = mapTitle;
		props.classification = getClassificationDescription(
			range1from,
			range1to,
			range2from,
			range2to,
			defaultVariables.default_secondary_year_array,
			defaultVariables.default_year_array,
			false
		);

		if (urlParams.subview === "trend") {
			props.aatRange1 = results.change_0;
			props.aatRange2 = results.change_1;
			props.icon = results.ch_updown;
			props.classification = results.ch_aat;
		} else {
			props.score = isACountry(selectedLocations[0])
				? getSpecificValue(state, selectedLocations[0], selectedSubjects[0], Number(range1to))
				: getAvgScoreForGroup(state, selectedSubjects[0], Number(range1to), selectedLocations[0]);
			props.subjectColor = selectedSubjectColor;
			props.icon = selectedSubjects[0];
			props.level = getStyleLevelForMeasure(state, selectedSubjects[0]);
			props.citvoice = isCitvoiceMeasure(state, selectedSubjects[0]);
			props.subjectLevel = `level-${getStyleLevelForMeasure(state, selectedSubjects[0])}`;
			props.subjectType = isCitvoiceMeasure(state, selectedSubjects[0]) ? "citvoice" : "regular";
		}
		return props;
	}, [state.setUrlQueryParam, i18n.language]);

	const onMapClick = (location, subject) => {
		const years = getSelectedYears('range1from', 'range1to');
		const range1to = years[years.length - 1];
		if (Number(range1to) === defaultVariables.latest_year) {
			showModal(
				<OverviewMultiLocationSingleSubjectInnerLocation location={location} subject={subject} isModal={true} />
			);
		}
	};

	const chartId = isTakingScreenshot ? "screenshotContent" : "africa_map_3";

	return (
		<>
			<MapChart
				state={state}
				mapTitle={mapTitle}
				selectedLocations={selectedLocations}
				selectedSubjects={selectedSubjects}
				urlParams={urlParams}
				mapGeoJson={getWorldMapJson()}
				sidePopup={sidePopup}
				onMapClick={onMapClick}
				chartId={chartId}
				isTakingScreenshot={isTakingScreenshot}
			/>
			<MapLegend urlParams={urlParams} props={legendProps} isTakingScreenshot={isTakingScreenshot} />
		</>
	);
};
