import React, { useMemo } from 'react';
import { Chartjs } from '../../../../components';
import { default as getChartConfig, getChartTitle } from './utils';
import { setDefaultUrlQueryParamsForSubview } from '../../../../actions';

export default ({ state, urlParams, isTakingScreenshot }) => {

  if (
    typeof urlParams.range1from === 'undefined'
    || typeof urlParams.range1to === 'undefined'
  ) {
    setDefaultUrlQueryParamsForSubview(urlParams);
  }

  if (!state?.indexedYml) {
    return null;
  }

  const chartConfig = useMemo(
    () => getChartConfig(state),
    [state.setUrlQueryParam, i18n.language],
  );

  const chartTitle = useMemo(
    () => getChartTitle(state),
    [state.setUrlQueryParam, i18n.language],
  );

  const chartId = isTakingScreenshot ? 'screenshotBarChart' : 'range-chart';

  return (
    <Chartjs
      chartTitle={chartTitle}
      chartConfig={chartConfig}
      chartId={chartId}
      isTakingScreenshot={isTakingScreenshot}
    />
  );
};
