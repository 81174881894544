import { urls } from '../../referenceData';
import {getDefaultChartSettings} from "../index";

const isLegacyData = (params) => params.version === 1;

const mapLegacyObjectKeys = (savedViewParams) => ({
  meas: savedViewParams.measure || '',
  loc: savedViewParams.location || '',
  view: savedViewParams.view || '',
  subview: savedViewParams.subview,
  range1from: savedViewParams.years1.split('|')[0] || '',
  range1to: savedViewParams.years1.split('|')[1] || '',
  range2from: savedViewParams.years2.split('|')[0] || '',
  range2to: savedViewParams.years2.split('|')[1] || '',
});

export default async (viewId) => {
  const { saved_views: savedViewParams } = await fetch(
    `${urls.apiUrl()}savedviews/?view_id=${viewId}`,// eslint-disable-line i18next/no-literal-string
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  ).then((res) => res.json());

  let paramsToSet = savedViewParams[0];

  if (isLegacyData(paramsToSet)) {
    paramsToSet = mapLegacyObjectKeys(paramsToSet);
    if (paramsToSet.subview === 'scores') {
      paramsToSet.subview = 'score';
    }
    if (paramsToSet.view === 'trends') {
      paramsToSet.view = 'map';
    }
    if (paramsToSet.view === 'map') {
      delete paramsToSet.range1from;
      delete paramsToSet.range2from;
      delete paramsToSet.range2to;
      if (paramsToSet.subview == '') {
        paramsToSet.subview = 'score';
      }
    }
    if (paramsToSet.view === 'graph') {
      if (paramsToSet.subview === 'at') {
        paramsToSet.subview = 'score';
      } else if (paramsToSet.subview === 'aat') {
        paramsToSet.subview = 'annualAvgTrend';
      }
    }
    if (paramsToSet.view === 'table') {
      if (paramsToSet.subview === 'at') {
        paramsToSet.subview = 'score';
        paramsToSet.showAAT = '0';
      } else if (paramsToSet.subview === 'aat') {
        paramsToSet.subview = 'score';
        paramsToSet.showAAT = '1';
      }
    }
  } else {
    paramsToSet = JSON.parse(paramsToSet.urldata);
  }

  paramsToSet = Object.keys(paramsToSet).reduce(
    (acc, param) => ({
      ...acc,
      [param]: paramsToSet[param].split('|').join('-'),
    }),
    {},
  );

  const defaults = getDefaultChartSettings(paramsToSet);


  defaults.forEach((setting) => {
    const key = Object.keys(setting);
    if (!paramsToSet[key]) {
      paramsToSet[key] = setting[key];
    }
  });

  return { viewId, urlQueryParams: paramsToSet };
};
