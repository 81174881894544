// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".errorMessage--3A_4A {\n    color: red;\n}\n\n.messageBoxWrapper--3i1NW {\n    pointer-events: none;\n}\n\n[dir=ltr] .messageBoxWrapper--3i1NW {\n    animation: hide--oJQ_k 0.5s ease-out 2s 1 normal forwards;\n}\n\n[dir=rtl] .messageBoxWrapper--3i1NW {\n    animation: hide--oJQ_k 0.5s ease-out 2s 1 normal forwards;\n}\n\n@keyframes hide--oJQ_k {\n    from {\n        opacity: 1;\n    }\n    to {\n        opacity: 0;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"errorMessage": "errorMessage--3A_4A",
	"messageBoxWrapper": "messageBoxWrapper--3i1NW",
	"hide": "hide--oJQ_k"
};
module.exports = exports;
