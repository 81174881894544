import React from "react";
import { Breadcrumb } from "../../components";

export default () => {
	const crumbs = [
		{
			text: "Explore",
		}
	];
	return <Breadcrumb crumbs={crumbs} />;
};
