import React from "react";
import { setUrlQueryParam, removeUrlQueryParam, getUrlQueryParams } from "../../../../../../actions";
import { DataTablePanel } from "../../../../../../components/SettingsPanel/subComponents";
import i18n from "../../../../../../i18n";
import {Trans} from "react-i18next";


export default ({ urlParams, dispatch }) => {
	let trends = null;

	const checkboxes = [
		{
			text: i18n.t("Show full context"),
			onChange: (checked) => {
				if (!checked) {
					dispatch({
						type: "setUrlQueryParam",
						action: removeUrlQueryParam({ name: "showFullContext" }),
					});
				} else {
					dispatch({
						type: "setUrlQueryParam",
						action: setUrlQueryParam({ name: "showFullContext", value: true }),
					});
				}
			},
			checked: JSON.parse(urlParams.showFullContext || false),
		},
		// {
		// 	text: "Show Raw Data",
		// 	checked: JSON.parse(urlParams.dataType || false),
		// 	onChange: (checked) => {
		// 		if (!checked) {
		// 			dispatch({
		// 				type: "dataType",
		// 				payload: "v",
		// 			});
		// 			dispatch({
		// 				type: "setUrlQueryParam",
		// 				payload: setUrlQueryParam({ name: "dataType", value: false }),
		// 			});
		// 		} else {
		// 			dispatch({
		// 				type: "dataType",
		// 				payload: "r",
		// 			});
		// 			dispatch({
		// 				type: "setUrlQueryParam",
		// 				payload: setUrlQueryParam({ name: "dataType", value: true }),
		// 			});
		// 		}
		// 	},
		// },
	];

	const highlights = {
		header: i18n.t("Highlight:"),
		options: [
			{
				text: urlParams.showAAT == 2 ? i18n.t("Worst Scores") : i18n.t("Lowest Scores"),
				onclick: (e) => {
					dispatch({
						type: "setDataTableFilters",
						payload: setUrlQueryParam({ name: "showLowest", value: e.target.checked }),
					});
				},
				checked: JSON.parse(urlParams?.showLowest || true),
				class: "table-low-score",
			},
			{
				text: urlParams.showAAT == 2 ? i18n.t("Best Scores") : i18n.t("Highest Scores"),
				onclick: (e) => {
					dispatch({
						type: "setDataTableFilters",
						payload: setUrlQueryParam({ name: "showHighest", value: e.target.checked }),
					});
				},
				checked: JSON.parse(urlParams?.showHighest || true),
				class: "table-high-score",
			},
			{
				text: i18n.t("Cell Highlights"),
				onclick: (e) => {
					dispatch({
						type: "setDataTableFilters",
						payload: setUrlQueryParam({ name: "showHighlights", value: e.target.checked }),
					});
				},
				checked: JSON.parse(urlParams?.showHighlights || true),
				class: "table-highlight",
			},
		],
	};

	if (urlParams.subview === "score") {
		if (JSON.parse(urlParams.showAAT) === 3) {
			highlights.options = [];
			checkboxes.length = 0;
		} else if (JSON.parse(urlParams.showAAT) === 2) {
			highlights.options.push(
				...[{
					class: "table-estimated",
					text: i18n.t("Estimated"),
					checked: JSON.parse(urlParams?.showEstimated || true),
					onclick: (e) => {
						dispatch({
							type: "setDataTableFilters",
							payload: setUrlQueryParam({ name: "showEstimated", value: e.target.checked }),
						});
					},
					info: {
						title: i18n.t("Estimated"),
						description: <Trans>This represents data points which are missing at source and have been estimated by the Foundation using one of the estimation methods outlined in the <a href="/methodology.html">IIAG methodology</a>.</Trans>,
					},
				},
					{
						class: "table-trimmed",
						text: i18n.t("Trimmed"),
						checked: JSON.parse(urlParams?.showTrimmed || true),
						onclick: (e) => {
							dispatch({
								type: "setDataTableFilters",
								payload: setUrlQueryParam({ name: "showTrimmed", value: e.target.checked }),
							});
						},
						info: {
							title: i18n.t("Trimmed"),
							description: <Trans>This represents data points which have been treated for outliers so as to not skew the overall distribution of the data as outlined in the <a href="/methodology.html">IIAG methodology</a>.</Trans>,
						},
					},
					{
						class: "table-trimmed-estimated",
						text: i18n.t("Trimmed and estimated"),
						checked: JSON.parse(urlParams?.showTrimmedEstimated || true),
						onclick: (e) => {
							dispatch({
								type: "setDataTableFilters",
								payload: setUrlQueryParam({ name: "showTrimmedEstimated", value: e.target.checked }),
							});
						},
						info: {
							title: i18n.t("Trimmed and estimated"),
							description: <Trans>This represents data points which have been estimated using the information in available raw data points from source that have first been trimmed (treated for outliers). Trimming and estimation methods used by the Foundation are  outlined in the <a href="/methodology.html">IIAG methodology</a>.</Trans>,
						},
					}]
			);
		}
		trends = {
			options: [
				{
					text: i18n.t("Change"),
					checked: typeof urlParams.showAAT === "undefined" ? true : (JSON.parse(urlParams.showAAT) === 0 || JSON.parse(urlParams.showAAT) === false),
					onClick: () => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "showAAT", value: 0 }),
						});
					},
				},
				{
					text: i18n.t("Show Annual Average Trend (AAT)"),
					checked: typeof urlParams.showAAT === "undefined" ? false : JSON.parse(urlParams.showAAT) === 1,
					onClick: () => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "showAAT", value: 1 }),
						});
					},
				},
				{
					text: i18n.t("Show Raw Data"),
					checked: typeof urlParams.showAAT === "undefined" ? false : JSON.parse(urlParams.showAAT) === 2,
					onClick: () => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "showAAT", value: 2 }),
						});
					},
				},
				{
					text: i18n.t("Drivers of Change"),
					checked: typeof urlParams.showAAT === "undefined" ? false : JSON.parse(urlParams.showAAT) === 3,
					onClick: () => {
						dispatch({
							type: "setUrlQueryParam",
							payload: setUrlQueryParam({ name: "showAAT", value: 3 }),
						});
					},
				},
			],
			legend: [
				i18n.t("Change - difference in the score obtained by a country/group in any measure over two periods in time"),
				i18n.t("AAT - average annual change in the score obtained by a country/group in any measure in a given period of time"),
				i18n.t("Raw Data - data point direct from source"),
				i18n.t("Drivers of Change - breakdown of the measures driving (and opposing) any change."),
			],
		};
	}
	return <DataTablePanel highlights={highlights} trends={trends} checkboxes={checkboxes} />;
};
