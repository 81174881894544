import { defaultVariables } from '../../referenceData';
import { default as setUrlQueryParam } from '../setUrlQueryParam';
import { default as getUrlQueryParams } from '../getUrlQueryParams';
import { default as removeUrlQueryParam } from '../removeUrlQueryParam';

const defaultParams = {
  overview: [{ subview: 'absoluteTrends' }],
  graph: [
    {
      subview: 'score',
      option: 'line',
      range1from: defaultVariables.earliest_year,
      range1to: defaultVariables.latest_year,
    },
  ],
  table: [
    {
      subview: 'score',
      range1from: defaultVariables.earliest_year,
      range1to: defaultVariables.latest_year,
      range2from: defaultVariables.default_secondary_year_array[0],
      range2to:
        defaultVariables.default_secondary_year_array[
          defaultVariables.default_secondary_year_array.length - 1
        ],
      showAAT: false,
      showLowest: true,
      showHighest: true,
      showEstimated: true,
      showTrimmed: true,
      showTrimmedEstimated: true,
      showHighlights: true,
      showFullContext: false,
    },
  ],
  map: [
    {
      subview: 'score',
      range1to: defaultVariables.latest_year,
    },
  ],
};
export default (view) => {
  const urlParams = getUrlQueryParams();
  const allowedParams = ['meas', 'loc', 'view'];

  Object.keys(urlParams).forEach((param) => {
    if (!allowedParams.includes(param)) {
      removeUrlQueryParam({ name: param });
    }
  });

  defaultParams[view].forEach((param) => {
    setUrlQueryParam({
      name: Object.keys(param)[0],
      value: Object.values(param)[0],
    });
  });
};
