import { urls } from '../../referenceData';
import {fetchWithRetry} from "../index";

let loadingPromise;
let output;

export default async () => {
  if (loadingPromise) {
    await loadingPromise;
  }

  if (output) {
    return output;
  } else {
    let resolveLoadingPromise;
    loadingPromise = new Promise(function (resolve, reject) {
      resolveLoadingPromise = resolve;
    });
    try {
      const res = await fetchWithRetry(
        `${urls.apiUrl()}most/?format=json&_v=${process.env.VERSION}`,// eslint-disable-line i18next/no-literal-string
      );

      const toJson = await res.json();

      resolveLoadingPromise(true);

      output = toJson;
      return toJson;
    } catch (err) {
      console.log(err);
    }
  }
};
