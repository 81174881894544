// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .wrapper--qyxBh {\n    padding: 30px 60px;\n}\n\n[dir=ltr] .infoIcon--CaBQP {\n    margin-left: 5px;\n}\n\n[dir=rtl] .infoIcon--CaBQP {\n    margin-right: 5px;\n}\n\n.trendsCell--_rIi2 {\n    width: 100%;\n}\n\n[dir=ltr] .infoIcon--CaBQP {\n    margin-left: 5px;\n}\n\n[dir=rtl] .infoIcon--CaBQP {\n    margin-right: 5px;\n}\n\n.fadeTrend--K9BUc {\n    opacity: 0.25;\n}\n\n.trendsIncreasingImprovement--3G5GA {\n    color: white;\n}\n\n[dir] .trendsIncreasingImprovement--3G5GA {\n    background-color: #418a8f;\n}\n\n[dir] .trendsSlowingImprovement--RLNHi {\n    background-color: #89a361;\n}\n\n[dir] .trendsWarningSigns--YuaNI {\n    background-color: #c5b300;\n}\n\n[dir] .trendsBouncingBack--frpGk {\n    background-color: #ffd500;\n}\n\n[dir] .trendsSlowingDeterioration--28fzf {\n    background-color: #ee7330;\n}\n\n.trendsIncreasingDeterioration--1urw7 {\n    color: white;\n}\n\n[dir] .trendsIncreasingDeterioration--1urw7 {\n    background-color: #b1302e;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper--qyxBh",
	"infoIcon": "infoIcon--CaBQP",
	"trendsCell": "trendsCell--_rIi2",
	"fadeTrend": "fadeTrend--K9BUc",
	"trendsIncreasingImprovement": "trendsIncreasingImprovement--3G5GA",
	"trendsSlowingImprovement": "trendsSlowingImprovement--RLNHi",
	"trendsWarningSigns": "trendsWarningSigns--YuaNI",
	"trendsBouncingBack": "trendsBouncingBack--frpGk",
	"trendsSlowingDeterioration": "trendsSlowingDeterioration--28fzf",
	"trendsIncreasingDeterioration": "trendsIncreasingDeterioration--1urw7"
};
module.exports = exports;
