import React from "react";
import { ScatterChartPanel } from "../../../../../../components/SettingsPanel/subComponents";
import { removeUrlQueryParam, setUrlQueryParam } from "../../../../../../actions";
import i18n from "../../../../../../i18n";

export default ({ urlParams, dispatch }) => {
	const checkboxes = [
		{
			text: i18n.t("Show full context"),
			onChange: (checked) => {
				if (!checked) {
					dispatch({
						type: "setUrlQueryParam",
						action: removeUrlQueryParam({ name: "showFullContext" }),
					});
				} else {
					dispatch({
						type: "setUrlQueryParam",
						action: setUrlQueryParam({ name: "showFullContext", value: true }),
					});
				}
			},
			checked: JSON.parse(urlParams.showFullContext || false),
		},
	];

	return <ScatterChartPanel checkboxes={checkboxes} />;
};
