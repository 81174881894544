import React from "react";
import { TooManySelectionsPlaceholder } from "../../../../components";
import i18n from "../../../../i18n";

export default () => {
	const title = i18n.t("Invalid Selection");
	const description = [
		i18n.t("Due to the number of items you have selected, it is not possible to produce an overview display."),
		i18n.t("When a single location is selected, the limit for measures is {{measure_limit}}. When a single measure is viewed, the limit for locations is {{location_limit}}.", {'measure_limit': "12", 'location_limit': "8"}),
	];

	return <TooManySelectionsPlaceholder title={title} description={description} />;
};
