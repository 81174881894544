import React from "react";
import { Footer } from "../../../../components";
import logo from "../../../../../img/logo/full_logo_footer.png";
import i18n from "../../../../i18n";

export default () => {
  const navigationLinks = [
    {
      link: {
        href: `${window.location.origin}/data.html${window.location.search}`,
        text: "IIAG",
      },
      prepandText: i18n.t("Source:"),
    },
  ];
  const copyright = i18n.t('Copyright ©{{ current_year }} Mo Ibrahim Foundation', {'current_year': new Date().getFullYear()});

  return (
    <Footer
      logo={logo}
      isEmbedPage={true}
      navigationLinks={navigationLinks}
      copyright={copyright}
    />
  );
};
