// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .backgroundImage--FxeE7 {\n    background-image: url(\"/dist/src/img/backgrounds/grey_background.png\");\n    background-size: cover;\n    padding-bottom: 70px;\n}\n\n.backgroundWhite--f0SBj {\n    position: relative;\n}\n\n[dir] .backgroundWhite--f0SBj {\n    background-color: white;\n    padding: 25px;\n    margin-top: 15px;\n    border-radius: 14px;\n}\n\n.infoIcon--3d5wI {\n    position: absolute;\n    top: 15px;\n\n    color: #a4a4a4;\n    transition: color 0.6s ease;\n\n    &:hover {\n        color: #E6512D;\n    }\n}\n\n[dir] .infoIcon--3d5wI {\n    cursor: pointer\n}\n\n[dir=ltr] .infoIcon--3d5wI {\n    right: 15px\n}\n\n[dir=rtl] .infoIcon--3d5wI {\n    left: 15px\n}\n", ""]);
// Exports
exports.locals = {
	"backgroundImage": "backgroundImage--FxeE7",
	"backgroundWhite": "backgroundWhite--f0SBj",
	"infoIcon": "infoIcon--3d5wI"
};
module.exports = exports;
