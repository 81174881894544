import React, { useMemo, useEffect } from "react";
import { DataTable } from "../../../../components";
import {
	getLocationTitle,
	getSelectedSubjects,
	getSelectedYears,
	getUrlQueryParams,
	getSelectedLocations,
	getSubjectTitle,
	getSubjectLevel, getAppSelectedLanguageId,
} from "../../../../actions";
import { setDefaultUrlQueryParamsForSubview } from "../../../../actions";
import { useAppContext } from "../../../../context/AppState.jsx";
import { defaultVariables } from "../../../../referenceData";
import { default as TableLegend } from "../TableLegend/index.jsx";
import i18n from "../../../../i18n";

const getSubjectCategoryParent = (state, parentId) => {
	const item = state.fullListOfMeasures.filter((measure) => measure.s_id === parentId)[0];

	if (!parentId) {
		return false;
	}

	if (!item) {
		return false;
	}

	if (item.lvl == 1) {
		return item;
	}

	const parent = state.fullListOfMeasures.find((el) => el.s_id == item.ps_id);
	if (!parent || parent.s_id == parentId) {
		// should never happen, but best not to crash
		return state.fullListOfMeasures.find((e) => e.s_id == "GOVERNANCE");
	}
	return getSubjectCategoryParent(state, parent.s_id);
};

const shortenedYear = (year) => `\'${year.substring(2)}`;

const createTableHeader = (urlParams, hasDefaultYearsSelected) => {
	const tableHeader = { firstColumn: {}, records: [], annualFields: {} };
	const { loc, range1from, range1to, subview, range2from, range2to } = urlParams;
	const selectedLocations = loc.split("-");

	if (selectedLocations.length > 1) {
		tableHeader.firstColumn = "Location";
	} else {
		tableHeader.firstColumn = "Measure";
	}

	for (let i = Number(range1from); i <= Number(range1to); i++) {
		tableHeader.records.push(`${i}`);
	}
	tableHeader.annualFields.rank_change = [];
	tableHeader.annualFields.rank_change.push("Change", `${shortenedYear(range1from)}-${shortenedYear(range1to)}`);

	return tableHeader;
};

const supplementSelectedItemsBytFullContext = (state, selectedLocations, selectedSubjects) => {
	if (selectedSubjects.length === 1) {
		const listOfRemainingCountries = state.fullListOfCountries.filter(
			(country) => !selectedLocations.includes(country.iso)
		);

		return {
			listOfAllLocations: [...listOfRemainingCountries.map((country) => country.iso), ...selectedLocations],
			listOfAllSubjects: selectedSubjects,
		};
	} else {
		let subjectsSuplementedByFullContext = [];

		selectedSubjects.forEach((subject) => {
			const subjectLevel = getSubjectLevel(state, subject);
			if (subjectLevel === 1 && !subjectsSuplementedByFullContext.includes("GOVERNANCE")) {
				subjectsSuplementedByFullContext.push("GOVERNANCE");
			}
			if (subjectLevel === 1 && !subjectsSuplementedByFullContext.includes(subject)) {
				subjectsSuplementedByFullContext.push(subject);
			}
			if (subjectLevel !== 1 && subjectLevel !== 0) {
				const categoryParent = getSubjectCategoryParent(state, subject);
				if (!subjectsSuplementedByFullContext.includes(categoryParent.s_id)) {
					subjectsSuplementedByFullContext.push(categoryParent.s_id);
				}
			}
		});

		return {
			listOfAllLocations: selectedLocations,
			listOfAllSubjects: subjectsSuplementedByFullContext,
		};
	}
};

export default ({ urlParams }) => {
	const [state, dispatch] = useAppContext();

	if (
		typeof urlParams.range1from === "undefined" ||
		typeof urlParams.range1to === "undefined" ||
		typeof urlParams.range2from === "undefined" ||
		typeof urlParams.range2to === "undefined"
	) {
		setDefaultUrlQueryParamsForSubview(urlParams);
	}

	const selectedYearsRange1 = getSelectedYears("range1from", "range1to");
	const selectedYearsRange2 = getSelectedYears("range2from", "range2to");
	const selectedSubjects = getSelectedSubjects(state);
	urlParams = getUrlQueryParams();
	const selectedLocations = getSelectedLocations(state);
	const measuresView = useMemo(() => selectedLocations.length === 1, [selectedLocations]);
	const tableTitle = useMemo(() => i18n.t('{{subject}} for {{location}}', {'subject': getSubjectTitle(state), 'location': getLocationTitle(state)}), [selectedLocations]);
	const filters = {
		showAAT: JSON.parse(urlParams?.showAAT || null),
		showHighest: JSON.parse(urlParams?.showHighest || true),
		showLowest: JSON.parse(urlParams?.showLowest || true),
		showHighlights: JSON.parse(urlParams?.showHighlights || true),
		sortBy: urlParams?.sortBy?.toString() || null,
		sortDir: urlParams?.sortDir || null,
		subview: urlParams?.subview || "score",
		visibleRecordColumns: getSelectedYears("range1from", "range1to").map((year) => year.toString()),
		collapseAll: JSON.parse(urlParams?.collapseAll || false),
		showFullContext: JSON.parse(urlParams?.showFullContext || false),
		...JSON.parse(urlParams?.filters || null),
	};

	const hasDefaultYearsSelected =
		selectedYearsRange1[0] === defaultVariables.default_year_array[0] &&
		selectedYearsRange1[selectedYearsRange1.length - 1] ===
			defaultVariables.default_year_array[defaultVariables.default_year_array.length - 1] &&
		selectedYearsRange2[0] === defaultVariables.default_secondary_year_array[0] &&
		selectedYearsRange2[selectedYearsRange2.length - 1] ===
			defaultVariables.default_secondary_year_array[defaultVariables.default_secondary_year_array.length - 1];

	useEffect(() => {
		const worker = new Worker("./dataTableWebWorker.js", { type: "module" });

		let selectedItemsSuplementedByFullContext;

		if (JSON.parse(urlParams.showFullContext || false)) {
			selectedItemsSuplementedByFullContext = supplementSelectedItemsBytFullContext(
				state,
				selectedLocations,
				selectedSubjects
			);
		} else {
			selectedItemsSuplementedByFullContext = {
				listOfAllLocations: selectedLocations,
				listOfAllSubjects: selectedSubjects,
			};
		}

		worker.postMessage({
			selectedLocations: selectedItemsSuplementedByFullContext.listOfAllLocations,
			selectedSubjects: selectedItemsSuplementedByFullContext.listOfAllSubjects,
			selectedYears: selectedYearsRange1,
			selectedYears2: selectedYearsRange2,
			contextSubjects: selectedSubjects,
			urlParams,
			state,
			hasDefaultYearsSelected,
			defaultVariables,
			type: "createTable",
			activeLanguage: i18n.language,
		});

		worker.onerror = (err) => err;
		worker.onmessage = (e) => {
			if (e.data === "loading") {
				dispatch({
					type: "dataTable",
					payload: [],
				});
			} else {
				dispatch({
					type: "dataTable",
					payload: e.data,
				});
				worker.terminate();
			}
		};
	}, [state.setUrlQueryParam, i18n.language]);

	const tableHeader = createTableHeader(urlParams, hasDefaultYearsSelected);

	const tableRows = state?.dataTable || [];

	const measureTreeOrder = useMemo(() => state.measureTreeOrder, [state.measureTreeOrder]);

	const onInfoIconClick = (subjectName) => {
		const descriptor = state.descriptors.find(
			(subject) => subject.uid === `md${subjectName}` && subject.lang === getAppSelectedLanguageId()
		);
		return descriptor.wd;
	};

	// this just puts it into appContext so that share modals etc can find these to
	// build complete URLs
	const onFilterChange = (_filterState) => {
		let filterState = {
			sortBy: _filterState.columnId,
			sortDir: _filterState.direction,
		};

		dispatch({
			type: "tableFilters",
			payload: filterState,
		});
	};

	return (
		<>
			<DataTable
				measuresView={measuresView}
				tableRows={tableRows}
				tableHeader={tableHeader}
				tableTitle={tableTitle}
				filters={filters}
				onInfoIconClick={onInfoIconClick}
				measureTreeOrder={measureTreeOrder}
				onFilterChange={onFilterChange}
			/>
			<TableLegend />
		</>
	);
};
