import getUrlQueryParams from '../getUrlQueryParams';
import {defaultVariables} from "../../referenceData";
export default (from, to) => {
  const urlParams = getUrlQueryParams();
  let firstSelectedYear = Number(urlParams[from]);
  let lastSelectedYear = Number(urlParams[to]);

  if (firstSelectedYear < defaultVariables.earliest_year) {
    // let offset = defaultVariables.earliest_year - firstSelectedYear;
    firstSelectedYear = defaultVariables.earliest_year;
    // lastSelectedYear = Math.min(lastSelectedYear + offset, defaultVariables.latest_year);
  }
  if (lastSelectedYear > defaultVariables.latest_year) {
    // let offset = lastSelectedYear - defaultVariables.latest_year;
    lastSelectedYear = defaultVariables.latest_year;
    // firstSelectedYear = Math.max(defaultVariables.earliest_year, firstSelectedYear - offset);
  }

  if (isNaN(lastSelectedYear)) return [firstSelectedYear];
  if (isNaN(firstSelectedYear)) return [lastSelectedYear];

  const data = [];
  for (let i = firstSelectedYear; i <= lastSelectedYear; i++) {
    data.push(i);
  }
  return data;
};
