import React from "react";
import { SubjectPickerMenu } from "../../components";
import { Information } from "../../components/Modals";
import { useAppContext } from "../../context/AppState.jsx";
import { useModalContext } from "../../context/modalContext.jsx";
import {
	getSelectedSubjectName,
	getSubjectColor,
	getSelectedSubjects,
	getUrlQueryParams,
	getSelectedExternals, getExternalName
} from "../../actions";
import { setUrlQueryParam, setMenuToOpen, removeUrlQueryParam, collapseSubjects } from "../../actions";
import i18n from "../../i18n";

let fullListOfSubjects;

const mapSubjectLevelToType = {
	0: "Measures",
	1: "Categories",
	2: "SubCategories",
	3: "Indicators",
	4: "SubIndicators",
	5: "SubSubIndicators",
};

const getDescription = (isSimplifiedView) =>
	isSimplifiedView
		? i18n.t("Please select a measure below to find out more about it.")
		: i18n.t("Please select your measure(s) below. You can choose single or multiple measures all the way down to indicator level. Please note, it is not possible to compare multiple measures with multiple locations.");

const getItemsWithParent = (parentId) => fullListOfSubjects.filter((n) => n.ps_id === parentId);

const createMainSubjectList = ({ state, subjects, mainParent, isCV, ancestors }) => {
	const levelAncestors = ancestors || [];
	return subjects.map((subject) => {
		const thisAncestors = [...levelAncestors, subject.s_id];
		const subjectChildren = getItemsWithParent(subject.s_id);
		if (subjectChildren.length > 0 && subject.lvl < 5) {
			return {
				id: subject.s_id,
				parent: subject.ps_id,
				ancestors: levelAncestors,
				topLevelParent: typeof mainParent === "undefined" ? subject.s_id : mainParent,
				subjectColor: subject.lvl === 1 ? getSubjectColor({ state, subject: subject.s_id, toRgb: true }) : "",
				fullName: getSelectedSubjectName(state, subject.s_id),
				itemType: mapSubjectLevelToType[isCV ? subject.lvl + 1 : subject.lvl],
				isCV,
				childrens: createMainSubjectList({
					state,
					subjects: subjectChildren,
					mainParent: typeof mainParent === "undefined" ? subject.s_id : mainParent,
					isCV,
					ancestors: thisAncestors,
				}),
			};
		}
		return {
			id: subject.s_id,
			parent: subject.ps_id,
			ancestors: levelAncestors,
			subjectColor: subject.lvl === 1 ? getSubjectColor({ state, subject: subject.s_id, toRgb: true }) : "",
			fullName: getSelectedSubjectName(state, subject.s_id),
			itemType: mapSubjectLevelToType[isCV ? subject.lvl + 1 : subject.lvl],
			topLevelParent: typeof mainParent === "undefined" ? subject.s_id : mainParent,
			isCV,
		};
	});
};

const showExternals = () => {
	const { view, subview } = getUrlQueryParams();

	if (view === 'graph' && subview === 'correlation') {
		return true;
	}

	return false;
}

const createExternalsList = (state, externals) => {
	if (!externals || !state?.descriptors) {
		return [];
	}
	return externals.map((external) => {
		return {
			id: external.id,
			name: `${getExternalName(state, external.id)} (${external.first_year}-${external.last_year})`,
		};
	});

}

const countSelectedSubjects = (childrens, selectedSubjects, counter) => {
	childrens.forEach((child) => {
		if (selectedSubjects?.includes(child.id)) {
			counter++;
		}
		if (child.childrens) {
			counter = countSelectedSubjects(child.childrens, selectedSubjects, counter);
		}
	});
	return counter;
};

export default ({ isSimplifiedView = false, onSubjectChange = false }) => {
	const [state, dispatch] = useAppContext();
	const [showModal] = useModalContext();
	const show = state?.setMenuToOpen === "measure";
	const queryParams = getUrlQueryParams();

	const onPhraseChange = (phrase) => {
		if (phrase.length === 0) {
			dispatch({
				type: "setUrlQueryParam",
				payload: removeUrlQueryParam({ name: "search" }),
			});
		} else {
			dispatch({
				type: "setUrlQueryParam",
				payload: setUrlQueryParam({ name: "search", value: phrase }),
			});
		}
	};

	const searchPhrase = getUrlQueryParams()?.search;

	const description = getDescription(isSimplifiedView);

	fullListOfSubjects = state?.fullListOfMeasures || [];

	const categories = getItemsWithParent("GOVERNANCE");

	const mainSubjectList = createMainSubjectList({ state, subjects: categories, isCV: false });

	if (isSimplifiedView) {
		return (
			<SubjectPickerMenu
				description={description}
				isSimplifiedView={isSimplifiedView}
				mainSubjectList={mainSubjectList}
				onPhraseChange={onPhraseChange}
				searchPhrase={searchPhrase}
				onSubjectSelect={onSubjectSelect}
				onSubjectChange={onSubjectChange}
			/>
		);
	}

	// const totalAmountOfMainSubjects = getTotalAmountOfMainSubjects(mainSubjectList);
	const selectedSubjects = state?.fullListOfMeasures ? getSelectedSubjects(state) : [];
	const selectedExternals = state?.externalMetrics ? getSelectedExternals(state) : [];

	const externalList = createExternalsList(state, state?.externalMetrics);

	const subjectCounters = mainSubjectList.reduce((acc, subject) => {
		let counter = selectedSubjects?.includes(subject.id) ? 1 : 0;

		return (acc = {
			...acc,
			[subject.id]: countSelectedSubjects(subject.childrens, selectedSubjects, counter),
		});
	}, {});

	const onSubjectSelect = (subjectId) => {
		let newQueryParams;
		if (selectedSubjects.includes(subjectId)) {
			newQueryParams = selectedSubjects.filter((subject) => subject !== subjectId);
		} else {
			newQueryParams = selectedSubjects;

			newQueryParams.push(subjectId);
		}
		const collapsedSubjects = collapseSubjects({ state, subjects: newQueryParams });

		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "meas", value: collapsedSubjects.join("-") }),
		});
	};

	const onSelectAllSubjects = ({ groupId, selectedGroupsOfSubjects }) => {
		let newQueryParams;
		if (selectedGroupsOfSubjects.includes(groupId)) {
			newQueryParams = selectedGroupsOfSubjects.filter((group) => group !== groupId);
		} else {
			selectedGroupsOfSubjects.push(groupId);
			newQueryParams = selectedGroupsOfSubjects;
		}
		const collapsedSubjects = collapseSubjects({ state, subjects: newQueryParams });

		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "selectAll", value: collapsedSubjects.join("-") }),
		});
	};

	const addSelectedSubject = ({ subjectsGroup, selectedSubjects }) => {
		let subjectsToAdd = [];

		subjectsGroup.forEach((subjectId) => {
			if (!selectedSubjects.includes(subjectId)) {
				subjectsToAdd.push(subjectId);
			}
		});

		const mergedSubjects = [...selectedSubjects, ...subjectsToAdd];

		const collapsedSubjects = collapseSubjects({ state, subjects: mergedSubjects });

		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "meas", value: collapsedSubjects.join("-") }),
		});
	};

	const removeSelectedSubject = ({ subjectsGroup, selectedSubjects }) => {
		let subjectsToRemove = [];
		subjectsGroup.forEach((subjectId) => {
			if (selectedSubjects.includes(subjectId)) {
				subjectsToRemove.push(subjectId);
			}
		});
		const filteredSelectedSubjects = selectedSubjects
			.filter((subject) => !subjectsToRemove.includes(subject));

		const collapsedSubjects = collapseSubjects({ state, subjects: filteredSelectedSubjects });

		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "meas", value: collapsedSubjects.join("-") }),
		});
	};

	const clearSelectedSubjects = (showNestedLists) => {
		dispatch({
			type: "setUrlQueryParam",
			payload: removeUrlQueryParam({ name: "meas" }),
		});
		dispatch({
			type: "setUrlQueryParam",
			payload: removeUrlQueryParam({ name: "selectAll" }),
		});
		dispatch({
			type: "setUrlQueryParam",
			payload: removeUrlQueryParam({ name: "ext" }),
		});

		if (showNestedLists) {
			showNestedLists([]);
		}

	};

	const selectedGroupsOfSubjects = queryParams?.selectAll?.split("-") || [];

	const onExternalSelect = (id) => {
		let newQueryParams;
		if (selectedExternals.includes(id)) {
			newQueryParams = selectedExternals.filter((subject) => subject !== id);
		} else {
			newQueryParams = selectedExternals;

			newQueryParams.push(id);
		}

		dispatch({
			type: "setUrlQueryParam",
			payload: setUrlQueryParam({ name: "ext", value: newQueryParams.join("~") })
		});
	};


	const buttons = [
		{
			label: i18n.t("Clear Selection"),
			onClick: (showNestedLists) => clearSelectedSubjects(showNestedLists),
			class: "clear-comparison",
		},
		{
			label: i18n.t("Show Comparison"),
			onClick: () => {
				dispatch({
					type: "setMenuToOpen",
					payload: setMenuToOpen(""),
				});
			},
			class: "show-comparison",
		},
	];

	if (queryParams?.selectAll?.split("-")?.includes("ALL") && selectedSubjects.length !== 281) {
		dispatch({
			type: "setUrlQueryParam",
			payload: removeUrlQueryParam({ name: "selectAll" }),
		});
	}

	// filter delets empty params
	const selectAllList = queryParams?.selectAll?.split("-").filter((item) => item) || [];

	const quickSelectionDescription = {
		label: i18n.t("Quick selection"),
		onClick: () =>
			showModal(
				<Information
					title={i18n.t("About Quick Selection")}
					description={[
						i18n.t("Use these links to quickly select multiple measures at once."),
						// i18n.t("Please note that this will not select any measures from the Citizens’ Voices section. To add those, simply scroll down to Public Perception of Overall Governance and use the quick select options there."),
					]}
				/>
			),
	};

	return (
		<SubjectPickerMenu
			show={show}
			description={description}
			isSimplifiedView={isSimplifiedView}
			mainSubjectList={mainSubjectList}
			onSubjectSelect={onSubjectSelect}
			selectedSubjects={selectedSubjects}
			buttons={buttons}
			subjectCounters={subjectCounters}
			queryParams={queryParams}
			selectedGroupsOfSubjects={selectedGroupsOfSubjects}
			onSelectAllSubjects={onSelectAllSubjects}
			addSelectedSubject={addSelectedSubject}
			removeSelectedSubject={removeSelectedSubject}
			selectAllList={selectAllList}
			quickSelectionDescription={quickSelectionDescription}
			onPhraseChange={onPhraseChange}
			searchPhrase={searchPhrase}
			externals={showExternals() ? externalList : {}}
			selectedExternals={selectedExternals}
			onExternalSelect={onExternalSelect}
		/>
	);
};
