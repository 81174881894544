import React from "react";
import { useModalContext } from "../../context/modalContext.jsx";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {scoreFormat} from "../../actions";

export default ({ locationData }) => {
	const [_, closeModal] = useModalContext();
	const {t }= useTranslation();
	return (
		<div
			id={`column_${locationData.index}`}
			className={`uk-panel uk-panel-box-secondary uk-text-center uk-text-middle ${locationData.subject_level} ${
				locationData.isModal ? styles.modal : ""
			}`}
			style={{ height: "auto" }}
			key={`column_${locationData.index}`}
		>
			<div
				className={`uk-vertical-align-middle overview-white overview-multi-country-country uk-box-shadow-medium uk-border-rounded ${styles.modalContainer}`}
			>
				{locationData.isModal && !locationData.isTakingScreenshot ? (
					<div className={`uk-margin-small-right uk-text-right uk-width-1-1 ${styles.closeButtonWrapper}`}>
						<button
							onClick={() => {
								closeModal();
							}}
							id={`close_template_overview_multi_location_country_${locationData.iso}`}
							uk-icon="icon: close; ratio: 2"
						></button>
					</div>
				) : null}

				<div className={`country-name ${locationData.isModal ? styles.modalCountryName : ""}`}>
					<h1 className="overview-title">
						<span className={`overview_headline ${locationData.isModal ? styles.modalHeadline : ""}`}>
							{locationData.location}
						</span>
						<img
							src={`img/flags/${locationData.iso}.svg`}
							className={`flag-image flag ${locationData.hide_flag} ${styles.flagIcon} ${
								locationData.isModal ? styles.modalFlagIcon : ""
							} measure-icon-image`}
						/>
					</h1>
				</div>
				<h4 className="subject-name">{locationData.subject}</h4>
				<div
					className={`percentage-circle circle-red measure-icon-image ${
						locationData.isModal ? styles.modalPercentageCircle : ""
					} ${locationData.subject_type} ${locationData.subject_level}`}
					style={{
						"--color": locationData.subject_color,
						"--score": Math.max(locationData.score_int, 0),
					}}
				>
					<svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<circle
							className="circle-back"
							r="45%"
							cx="50%"
							cy="50%"
							fill="transparent"
							pathLength="100"
						></circle>
						<circle
							className="circle-front"
							r="45%"
							cx="50%"
							cy="50%"
							fill="transparent"
							pathLength="100"
						></circle>
					</svg>
					{locationData.subject_type === "citvoice" && locationData.subject_row_level > 2 ? (
						<span className={styles.innerCircle}></span>
					) : (
						<span className="">
							<span className={locationData.hide_icon}>
								<img
									className="measure-icon-image"
									src={`/img/icons/${locationData.subject_s_id}.svg`}
								/>
							</span>
						</span>
					)}
				</div>
				<span className="overview_score uk-width-1-1">{scoreFormat(locationData.score)}</span>
				<span className="overview-small-text uk-width-1-1 top_margin_5" template-mif-string-id="22">
					{t("Out of 100.0")}
				</span>
				<div className=" uk-grid-divider uk-margin-top" uk-grid="{}">
					<div className={`${locationData.subWidth} country-trend`}>{locationData.trend}</div>
					<div className={`country-rank ${locationData.subWidth} ${locationData.hide_rank}`}>
						<span
							className="overview-bold-title uk-width-1-1 top_margin_10 bottom_margin_10"
							template-mif-string-id="71"
						>
							{t("Ranking")}
						</span>

						<div
							className={`percentage-circle-small  ${locationData.subject_type} circle-red`}
							style={{
								"--color": locationData.subject_color,
								"--score": Math.max(0, locationData.rank_percent_int),
							}}
						>
							<svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
								<circle
									className="circle-back"
									r="45%"
									cx="50%"
									cy="50%"
									fill="transparent"
									pathLength="100"
								></circle>
								<circle
									className="circle-front"
									r="45%"
									cx="50%"
									cy="50%"
									fill="transparent"
									pathLength="100"
								></circle>
							</svg>
							<span className=""></span>
						</div>

						<span className="overview_small_score uk-width-1-1">{locationData.rank}</span>
						<span className="overview-small-text uk-width-1-1 top_margin_5">
							<span template-mif-string-id="26">{t("Out of")}</span> {locationData.countries_with_rank}
						</span>
					</div>
				</div>
				{!locationData.isTakingScreenshot && locationData.view_more_country_info_link ? (
					<div className="uk-margin">
						<a
							href={locationData.view_more_country_info_link}
							id="view_more_country_info_link"
							className="uk-button uk-button-default uk-border-rounded uk-background-muted uk-text-uppercase"
						>
							{t("VIEW MORE COUNTRY INFO")}
						</a>
					</div>
				) : null}
			</div>
		</div>
	);
};
