import React from "react";
import styles from "../../styles.css";
import subIndicatorsStyles from "./styles.css";

import { isSearchedPhrase } from "../../utils";

export default ({
	subSubIndicators,
	subIndicatorId,
	nestedListsToShow,
	showNestedLists,
	selectedSubjects,
	onSubjectSelect,
	subjectColor,
	isSimplifiedView,
	subjectParent,
	queryParams,
	searchPhrase,
	onSubjectChange,
}) => (
	<ul className={`${styles.subSubIndicatorsList} ${!nestedListsToShow.includes(subIndicatorId) ? "uk-hidden" : ""}`}>
		{subSubIndicators.map((subSubIndicator, index) => {

			return (
				<li
					key={`${subSubIndicator.fullName.split(" ").join("")}_${index}`}
					className={`${subIndicatorsStyles.subIndicatorItem} ${
						!isSimplifiedView && selectedSubjects.includes(subSubIndicator.id)
							? `${subIndicatorsStyles.subIndicatorItemSelected} ${subjectParent}`
							: ""
					}`}
				>
					<a
						onClick={(e) => {
							if (isSimplifiedView && onSubjectChange) {
								e.preventDefault();
								onSubjectChange(subSubIndicator.id);
							}
							if (!isSimplifiedView) {
								e.preventDefault();
								onSubjectSelect(subSubIndicator.id);
							}
						}}
						href={`/measures/${subSubIndicator.id.toLowerCase()}.html`}
						className={`${
							isSearchedPhrase({
								subjectName: subSubIndicator.fullName,
								phrase: searchPhrase,
							})
								? styles.highlightSearchedWord
								: ""
						} ${
							!isSimplifiedView && selectedSubjects.includes(subSubIndicator.id)
								? `${subIndicatorsStyles.selected} ${subjectParent}`
								: ""
						} ${subIndicatorsStyles.subjectName}`}
						style={
							!isSimplifiedView && selectedSubjects.includes(subSubIndicator.id)
								? {
										background: `rgba(${subjectColor}, 0.25)`,
										border: `1px solid rgb(${subjectColor})`,
								  }
								: null
						}
					>
						{subSubIndicator.fullName}
					</a>
				</li>
			);
		})}
	</ul>
);
