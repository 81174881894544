import React, { useState } from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";
import {scoreFormat} from "../../actions";


export default ({ data }) => {
	const firstRowWidth = () => {
		if (data.standalone) {
			return "uk-width-1-1";
		} else if (data.country) {
			return "uk-width-1-3";
		} else {
			return "uk-width-1-2";
		}
	};
	const {t} = useTranslation();
	const subjectTypeClass = () => (data.citvoice ? "citvoice" : "regular");
	const hideIcon = () => (data.citvoice && data.level > 2) || (!data.citvoice && data.level > 2);

	return (
		<div
			id="first_row_1"
			className={`${firstRowWidth()} uk-panel uk-panel-box-secondary  uk-text-center uk-text-middle`}
		>
			<div className="single-score">
				{data.isLocationPage ? (
					<>
						<label
							onClick={(e) => {
								e.preventDefault();
								data.onSubjectMenuClick(data.showChildren);
							}}
							className={styles.dropdown}
						>
							{data.subject_name}
						</label>
						<div className={styles.subjectPickerWrapper}>{data.showChildren ? data.children : null}</div>
					</>
				) : (
					<h4
						className="uk-width-1-1 subject-name"
						placeholder-mif-translation-key=""
						template-mif-string-id="61"
					>
						{data.subject_name}
					</h4>
				)}

				<div
					className={`percentage-circle circle-red ${subjectTypeClass()} level-${data.style_level} `}
					style={{ "--color": data.subject_color, "--score": Math.max(0, data.score_int) }}
				>
					<svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<circle
							className="circle-back"
							r="45%"
							cx="50%"
							cy="50%"
							fill="transparent"
							pathLength="100"
						></circle>
						<circle
							className="circle-front"
							r="45%"
							cx="50%"
							cy="50%"
							fill="transparent"
							pathLength="100"
						></circle>
					</svg>
					{data.citvoice && data.level >= 2 ? (
						<span className={styles.innerCircle}></span>
					) : (
						<span className="">
							<span className={` ${hideIcon() ? "uk-hidden" : ""} `}>
								<img className="measure-icon-image" src={`/img/icons/${data.subject}.svg`} />
							</span>
						</span>
					)}
				</div>
				<span className="overview_score uk-width-1-1 ">{scoreFormat(data.score)}</span>
				<h4 className=" uk-width-1-1 overview-body-copy" template-mif-string-id="22">
					{t("Out of 100.0")}
				</h4>
			</div>
		</div>
	);
};
