// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".linkToSource--2e6qc {\n    text-decoration: underline;\n    color: black;\n}\n", ""]);
// Exports
exports.locals = {
	"linkToSource": "linkToSource--2e6qc"
};
module.exports = exports;
