// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".infoIcon--3tPfK {\n    width: 17px;\n    height: 17px;\n}[dir] .infoIcon--3tPfK {\n    margin-top: -4px;\n}[dir=ltr] .infoIcon--3tPfK {\n    margin-left: 5px;\n}[dir=rtl] .infoIcon--3tPfK {\n    margin-right: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"infoIcon": "infoIcon--3tPfK"
};
module.exports = exports;
