let url_site;
let url_api;
let url_ss;
let locize_version;
(function () {
  const url_site_dev = 'http://localhost/mif/_site/';
  const url_site_test_public = 'https://idp-test.mif.services/';
  const domain_site_test_public = 'idp-test.mif.services';
  const url_site_dev_public = 'https://idp-dev.mif.services/';
  const domain_site_dev_public = 'idp-dev.mif.services';
  const url_site_prod = 'https://iiag.online/';

  const url_api_dev = 'https://api-test.iiag.online/svc/';
  const url_api_test_public = 'https://api-test.iiag.online/svc/';
  const url_api_dev_public = 'https://api-dev.iiag.online/svc/';
  const url_api_prod = 'https://api.iiag.online/svc/';

  const url_ss_test = 'https://ss-test.iiag.online/';
  const url_ss_dev = 'https://ss-dev.iiag.online/';
  const url_ss_prod = 'https://ss.iiag.online/';

  const locize_latest = 'latest';
  const locize_2024 = '2024%20data%20update';

  if (
    typeof window.location !== 'undefined'
    && (window.location.href.indexOf('localhost') != -1
      || window.location.href.indexOf('127.0.0.1') != -1
      || window.location.href.indexOf('0.0.0.0') != -1)
  ) {
    url_site = url_site_dev;
    url_api = url_api_dev;
    url_ss = url_ss_test;
    locize_version = locize_2024;
  } else if (
    typeof window.location !== 'undefined'
    && window.location.href.indexOf(domain_site_test_public) != -1
  ) {
    url_site = url_site_test_public;
    url_api = url_api_test_public;
    url_ss = url_ss_test;
    locize_version = locize_2024;
  } else if (
    typeof window.location !== 'undefined'
    && window.location.href.indexOf(domain_site_dev_public) != -1
  ) {
    url_site = url_site_dev_public;
    url_api = url_api_dev_public;
    url_ss = url_ss_dev;
    locize_version = locize_2024;
  } else {
    url_site = url_site_prod;
    url_api = url_api_prod;
    url_ss = url_ss_prod;
    locize_version = locize_2024;
  }
}());

export default {
  apiUrl: () => url_api,
  siteUrl: () => url_site,
  ssUrl: () => url_ss,
  locizeVersion: () => locize_version,
};
