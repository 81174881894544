import React from "react";
import { CookieConsentNotice } from "../../components";
import { readCookie } from "../../actions";
import { setCookie, removeUrlQueryParam } from "../../actions";
import { useAppContext } from "../../context/AppState.jsx";
import i18n from "../../i18n";

export default () => {
	const [_, dispatch] = useAppContext();

	if (JSON.parse(readCookie("acceptCookies"))) {
		return null;
	}

	const description = i18n.t("We use cookies to deliver this service to you.");
	const navigateTo = { label: i18n.t("Learn more"), href: "/cookies.html" };
	const onDismiss = () => {
		removeUrlQueryParam({ name: "cookie_accept" });
		dispatch({
			type: "acceptCookies",
			payload: setCookie("acceptCookies", true, 365),
		});
	};
	return <CookieConsentNotice description={description} navigateTo={navigateTo} onDismiss={onDismiss} />;
};
