import i18n from "../../i18n";
export default (num) => {

  if (isNaN(num) || num < 0) {
    return i18n.t('n/a');
  }

  var formatter = new Intl.NumberFormat(i18n.language, { style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1 });
  // eslint-disable-next-line i18next/no-literal-string
  return <span dir="ltr">{formatter.format(num)}</span>;
};
