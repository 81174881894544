import React from "react";
import { MessageBox } from "../../components";
import { useAppContext } from "../../context/AppState.jsx";

export default () => {
	const [state] = useAppContext();
	const message = state.getMessage;

	if (!message) {
		return null;
	}

	return <MessageBox message={message} />;
};
