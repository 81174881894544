import React, { useState } from "react";
import styles from "./styles.css";
import { default as LoadingSpinner } from "../LoadingSpinner/index.jsx";
import {
	ShowClearButtons,
	QuickSelectionButtons,
	MeasureGroupBar,
	SubCategories,
	ExternalMetricsBar
} from "./SubComponents";

import { isSearchedPhrase } from "./utils";
import {useTranslation} from "react-i18next";

const OverallGovernanceBar = ({
	queryParams,
	isSimplifiedView,
	selectedSubjects,
	onSubjectSelect,
	searchPhrase,
	onSubjectChange,
}) => {
	const {t} = useTranslation();
	return (
		<div className={styles.measureCollapse}>
			<img className={styles.measureIcon} src="/img/icons/OG.svg" alt={t("Overall Governance")} />
			<h5
				className={`${styles.measureName}`}
			>
				<a
					href={`/measures/${"GOVERNANCE".toLowerCase()}.html`}
					className={`${
						isSearchedPhrase({
							subjectName: "Overall Governance",
							phrase: searchPhrase,
						})
							? styles.highlightSearchedWord
							: ""
					} ${styles.level0}
				${isSimplifiedView ? styles.measureTitle : ""}
				${!isSimplifiedView && selectedSubjects.includes("GOVERNANCE") ? styles.selectedGovernance : ""}
				`}
					onClick={(e) => {
						if (isSimplifiedView && onSubjectChange) {
							e.preventDefault();
							onSubjectChange("GOVERNANCE");
						}
						if (!isSimplifiedView) {
							e.preventDefault();
							onSubjectSelect("GOVERNANCE", queryParams);
						}
					}}
				>
					{t("Overall Governance")}
				</a>
			</h5>
		</div>
	);
}

const doSearch = ({children, searchPhrase, nestedListsToShow, showNestedLists, lastSearchPhrase, setLastSearchPhrase}) => {
	if (searchPhrase !== lastSearchPhrase) {
		setLastSearchPhrase(searchPhrase);
		const ancestors = _doSearch({children, searchPhrase, ancestors: []});
		if (JSON.stringify([...ancestors].sort()) !== JSON.stringify([...nestedListsToShow].sort())) {
			showNestedLists(ancestors);
		}

	}

}

const _doSearch = ({children, searchPhrase, ancestors}) => {
	children.forEach((child) => {
		if (child.childrens) {
			ancestors = _doSearch({children: child.childrens, searchPhrase, ancestors});
		}

		if (isSearchedPhrase({subjectName: child.fullName, phrase: searchPhrase})) {
			child.ancestors.forEach((ancestor) => {
				if (!ancestors.includes(ancestor)) {
					ancestors.push(ancestor);
				}
			});
		}
	});
	return ancestors;
};

const SearchInput = ({ onPhraseChange, searchPhrase, mainSubjectList, nestedListsToShow, showNestedLists, lastSearchPhrase, setLastSearchPhrase }) => {
	const {t} = useTranslation();

	return (
		<div className="search-container">
			<input
				onChange={(e) => {
					if (e.target.value.length < 3) {
						showNestedLists([]);
					} else {
						doSearch({
							children: mainSubjectList,
							searchPhrase: e.target.value,
							nestedListsToShow,
							showNestedLists,
							lastSearchPhrase,
							setLastSearchPhrase
						});
					}
					onPhraseChange(e.target.value);
				}}
				type="text"
				placeholder={t("Search measures")}
				className="search"
				value={searchPhrase || ""}
			/>
			{searchPhrase?.length > 0 ? (
				<span
					onClick={() => {
						showNestedLists([]);
						onPhraseChange("");
					}}
					uk-icon="icon: close"
					className="search-icon"
				/>
			) : (
				<span uk-icon="icon: search" className="search-icon" />
			)}
		</div>
	);
}

export default ({
	isSimplifiedView,
	description,
	mainSubjectList,
	show,
	selectedSubjects,
	onSubjectSelect,
	buttons,
	subjectCounters,
	queryParams,
	selectedGroupsOfSubjects,
	onSelectAllSubjects,
	removeSelectedSubject,
	addSelectedSubject,
	selectAllList,
	quickSelectionDescription,
	onPhraseChange,
	searchPhrase,
	onSubjectChange,
	externals,
	selectedExternals,
	onExternalSelect
}) => {
	const { t, i18n } = useTranslation();
	const [nestedListsToShow, showNestedLists] = useState([]);
	const [expandedMeasureBars, setExpandedMeasureBars] = useState([]);
	const [lastSearchPhrase, setLastSearchPhrase] = useState("");

	doSearch({
		children: mainSubjectList,
		searchPhrase,
		nestedListsToShow,
		showNestedLists,
		lastSearchPhrase,
		setLastSearchPhrase
	})

	return (
		<div
			className={`app-menu measure-menu ${
				isSimplifiedView ? styles.simplifiedSubjectPickerWrapper : styles.subjectPickerWrapper
			} ${show || isSimplifiedView ? "" : "uk-hidden"}`}
		>
			<div className="uk-container icon-container"></div>
			{mainSubjectList.length === 1 ? (
				<LoadingSpinner />
			) : (
				<>
					<div className={`${styles.subjectInfoMenuInner}`}>
						<p className={isSimplifiedView ? styles.simplifiedViewDescription : ""}>{description}</p>

						{!isSimplifiedView ? <ShowClearButtons buttons={buttons} showNestedLists={showNestedLists} /> : null}
						<div
							className={` ${
								isSimplifiedView ? styles.simplifiedViewSearchInput : styles.quickSelectionWrapper
							}`}
						>
							{!isSimplifiedView ? (
								<QuickSelectionButtons
									onSelectAllSubjects={onSelectAllSubjects}
									selectedGroupsOfSubjects={selectedGroupsOfSubjects}
									selectAllList={selectAllList}
									mainSubjectList={mainSubjectList}
									selectedSubjects={selectedSubjects}
									removeSelectedSubject={removeSelectedSubject}
									addSelectedSubject={addSelectedSubject}
									quickSelectionDescription={quickSelectionDescription}
									nestedListsToShow={nestedListsToShow}
									showNestedLists={showNestedLists}
								/>
							) : null}
							<SearchInput
								onPhraseChange={onPhraseChange}
								searchPhrase={searchPhrase}
								mainSubjectList={mainSubjectList}
								showNestedLists={showNestedLists}
								nestedListsToShow={nestedListsToShow}
								lastSearchPhrase={lastSearchPhrase}
								setLastSearchPhrase={setLastSearchPhrase}
							/>
						</div>
						<OverallGovernanceBar
							queryParams={queryParams}
							isSimplifiedView={isSimplifiedView}
							selectedSubjects={selectedSubjects}
							onSubjectSelect={onSubjectSelect}
							searchPhrase={searchPhrase}
							onSubjectChange={onSubjectChange}
						/>
						{mainSubjectList.map((subject, index) => (
							<div key={`${subject.fullName.split(" ").join("")}_${index}`}>
								<MeasureGroupBar
									subject={subject}
									isSimplifiedView={isSimplifiedView}
									selectedSubjects={selectedSubjects}
									onSubjectSelect={onSubjectSelect}
									queryParams={queryParams}
									nestedListsToShow={nestedListsToShow}
									showNestedLists={showNestedLists}
									subjectCounters={subjectCounters}
									selectedGroupsOfSubjects={selectedGroupsOfSubjects}
									onSelectAllSubjects={onSelectAllSubjects}
									addSelectedSubject={addSelectedSubject}
									removeSelectedSubject={removeSelectedSubject}
									expandedMeasureBars={expandedMeasureBars}
									setExpandedMeasureBars={setExpandedMeasureBars}
									searchPhrase={searchPhrase}
									onSubjectChange={onSubjectChange}
								/>
								{subject?.childrens ? (
									<SubCategories
										isSimplifiedView={isSimplifiedView}
										subjectId={subject.id}
										subCategories={subject.childrens}
										nestedListsToShow={nestedListsToShow}
										showNestedLists={showNestedLists}
										selectedSubjects={selectedSubjects}
										onSubjectSelect={onSubjectSelect}
										subjectColor={subject.subjectColor}
										subjectParent={subject.id}
										subjectType={subject.parent}
										selectedGroupsOfSubjects={selectedGroupsOfSubjects}
										onSelectAllSubjects={onSelectAllSubjects}
										removeSelectedSubject={removeSelectedSubject}
										addSelectedSubject={addSelectedSubject}
										queryParams={queryParams}
										searchPhrase={searchPhrase}
										onSubjectChange={onSubjectChange}
									/>
								) : null}
							</div>
						))}
						{ externals && externals.length > 0 && (
						<div key={`external_data_picker`}>
							<ExternalMetricsBar
								externals={externals}
								selectedExternals={selectedExternals}
								onExternalSelect={onExternalSelect}
								// subject={subject}
								// isSimplifiedView={isSimplifiedView}
								// selectedSubjects={selectedSubjects}
								// onSubjectSelect={onSubjectSelect}
								// queryParams={queryParams}
								nestedListsToShow={nestedListsToShow}
								showNestedLists={showNestedLists}
								// subjectCounters={subjectCounters}
								// selectedGroupsOfSubjects={selectedGroupsOfSubjects}
								// onSelectAllSubjects={onSelectAllSubjects}
								// addSelectedSubject={addSelectedSubject}
								// removeSelectedSubject={removeSelectedSubject}
								// expandedMeasureBars={expandedMeasureBars}
								// setExpandedMeasureBars={setExpandedMeasureBars}
								// searchPhrase={searchPhrase}
								// onSubjectChange={onSubjectChange}
							/>
						</div>)}
					</div>
				</>
			)}
		</div>
	);
};
