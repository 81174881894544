// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .backgroundImage--g5ANV {\n    background-image: url(\"/dist/src/img/backgrounds/grey_background.png\");\n    background-size: cover;\n    padding-bottom: 70px;\n}\n\n.backgroundWhite--_9Qzx {\n    &:empty {\n        display: none;\n    }\n}\n\n[dir] .backgroundWhite--_9Qzx {\n    background-color: white;\n    padding: 25px;\n    margin-top: 15px;\n    border-radius: 14px\n}\n", ""]);
// Exports
exports.locals = {
	"backgroundImage": "backgroundImage--g5ANV",
	"backgroundWhite": "backgroundWhite--_9Qzx"
};
module.exports = exports;
