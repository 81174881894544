import React from "react";
import styles from "./styles.css";

export default ({ children }) => {
	return (
		<div id="screenshotPdfFrame" className={styles.screenshotFrame}>
			{children}
		</div>
	);
};
