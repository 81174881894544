import React from "react";
import styles from "./styles.css";
import {useTranslation} from "react-i18next";

export default ({ titleText, linkToSource }) => {
	const {t } = useTranslation();
	return (
		<div className="uk-container uk-margin-top uk-margin-bottom">
			<div className="uk-grid">
				<div className="uk-width-1-2@s dialog_standard_white">
					<h3>{titleText ? titleText : "The most comprehensive dataset of African Governance"}</h3>
				</div>
				<div className="uk-width-1-2@s dialog_standard_white uk-text-right">
					<a href={linkToSource} className={styles.linkToSource} target="_blank">
						{t("View this snapshot on IIAG.online")}
					</a>
				</div>
			</div>
		</div>
	);
};
