import removeUrlQueryParam from '../removeUrlQueryParam';
import getUrlQueryParams from '../getUrlQueryParams';

export default ({ allowedParams }) => {
  const generalUrlParams = ['meas', 'loc', 'view', 'subview'];
  const urlParams = getUrlQueryParams();

  Object.keys(urlParams).forEach((urlParam) => {
    if (
      !generalUrlParams.includes(urlParam)
      && !allowedParams.includes(urlParam)
    ) {
      removeUrlQueryParam({ name: urlParam });
    }
  });
};
