import { ChartjsTooltip } from '../../../../components';
import {
  getSelectedLocationName,
  getSelectedLocations,
  getSelectedSubjectName,
  getSelectedSubjects,
  getUrlQueryParams,
  getSubjectColor,
  getAvgScoreForGroup,
  getGroupAveragesForSubjectForGraph,
  getGroupCodeFromName,
  getSubjectTitle,
  getLocationTitle,
  isCitvoiceMeasure, getSelectedYears,
} from '../../../../actions';
import { chartDataIterator } from '../RangeChart/utils';
import {regionCodes, defaultVariables, baseFont} from '../../../../referenceData';
import i18n from "../../../../i18n";

export const isMultipleSubjectSelected = (subjects) => subjects.length > 1;

const getAvarageValuesForSelectedYears = (data, selectedYears) => {
  if (typeof selectedYears === 'number') {
    selectedYears = [selectedYears];
  }
  const averageValues = [];
  data.values.forEach((value) => (selectedYears.includes(value.x) ? averageValues.push(value.y) : null));
  return averageValues;
};

const getAfricanAvarageStats = (state, subject, year) => {
  const avgData = chartDataIterator(
    state,
    getGroupAveragesForSubjectForGraph(
      state,
      getGroupCodeFromName(state, 'Africa'),
      subject,
    ),
    i18n.t("African average"),
    'dashed',
    '#8e8e8e',
    false,
    false,
    10,
    1,
  );

  return getAvarageValuesForSelectedYears(avgData, year);
};

const getDataForMultipleLocations = (state, subject, locations, year) => {
  const data = locations.map((location) => {
    const fullLocationname = getSelectedLocationName(state, location);
    const fullSubjectName = getSelectedSubjectName(state, subject);
    const subjectColor = isCitvoiceMeasure(state, subject)
      ? '#A19FA3'
      : getSubjectColor({ state, subject });

    if (regionCodes.includes(location)) {
      return {
        key: fullLocationname,
        value: getAvgScoreForGroup(state, subject, year, location),
        props: {
          color: subjectColor,
          location: {
            fullName: fullLocationname,
            abr: location,
          },
          subject: {
            fullName: fullSubjectName,
            abr: subject,
          },
        },
      };
    }
    return {
      key: fullLocationname,
      value: Number(state.indexedYml[year][subject][location].v).toFixed(1),
      props: {
        color: subjectColor,
        location: {
          fullName: fullLocationname,
          abr: location,
        },
        subject: {
          fullName: fullSubjectName,
          abr: subject,
        },
      },
    };
  });

  return data;
};

const getDataForMultipleSubjects = (state, subjects, location, year) => {
  const data = subjects.map((subject) => {
    const fullSubjectName = getSelectedSubjectName(state, subject);
    const fullLocationname = getSelectedLocationName(state, location);

    if (regionCodes.includes(location)) {
      return {
        key: fullSubjectName,
        value: getAvgScoreForGroup(state, subject, year, location),
        props: {
          color: isCitvoiceMeasure(state, subject)
            ? '#A19FA3'
            : getSubjectColor({ state, subject }),
          location: {
            fullName: fullLocationname,
            abr: location,
          },
          subject: {
            fullName: fullSubjectName,
            abr: subject,
          },
        },
      };
    }
    return {
      key: fullSubjectName,
      value: Number(state.indexedYml[year][subject][location].v).toFixed(1),
      props: {
        color: isCitvoiceMeasure(state, subject)
          ? '#A19FA3'
          : getSubjectColor({ state, subject }),
        location: {
          fullName: fullLocationname,
          abr: location,
        },
        subject: {
          fullName: fullSubjectName,
          abr: subject,
        },
      },
    };
  });
  return data;
};

const getChartHeader = (chartFilters, selectedYear) => (window.innerWidth < 480
  ? `${chartFilters} \n- ${i18n.t('Score/Rank')} ${selectedYear}` // eslint-disable-line i18next/no-literal-string
  : `${chartFilters} - ${i18n.t('Score/Rank')} ${selectedYear}`);// eslint-disable-line i18next/no-literal-string

const getDatasetsForChart = (state, subjects, locations, selectedYears) => {
  if (isMultipleSubjectSelected(subjects)) {
    return getDataForMultipleSubjects(
      state,
      subjects,
      locations[0],
      selectedYears,
    );
  }
  return getDataForMultipleLocations(
    state,
    subjects[0],
    locations,
    selectedYears,
  );
};

const getDataForTooltip = (state, subjects, locations) => {
  const body = {};
  const footer = [];

  if (isMultipleSubjectSelected(subjects)) {
    const location = getSelectedLocationName(state, locations[0]);
    subjects.forEach((subject) => {
      footer.push(
        i18n.t('African average for {{subject}}', {subject: getSelectedSubjectName(state, subject)})
      );
      body[getSelectedSubjectName(state, subject)] = {
        abr: subject,
        label: `${getSelectedSubjectName(state, subject)}: ${location}`, // eslint-disable-line i18next/no-literal-string
      };
    });
  } else {
    footer.push(i18n.t('African average'));
    locations.forEach((location) => {
      body[getSelectedLocationName(state, location)] = {
        abr: subjects[0],
        label: `${getSelectedSubjectName(// eslint-disable-line i18next/no-literal-string
          state,
          subjects[0],
        )}: ${getSelectedLocationName(state, location)}`,
      };
    });
  }

  return {
    body,
    footer,
  };
};

export const getChartTitle = (state, urlParams) => {
  const years = getSelectedYears('range1from', 'range1to');
  const selectedYear = years.pop();
  const chartTitle = `${i18n.t("{{subject}} for {{location}}", {
      subject: getSubjectTitle(state),
      location: getLocationTitle(state)
    })}${
    Number(selectedYear) !== defaultVariables.latest_year
      ? ` ${selectedYear}`
      : ''
  }`;

  return chartTitle;
};

const sortData = (chartData, urlParams, measureTreeOrder) => {
  const { sortBy, sortDir, meas } = urlParams;
  let sortedData;

  if (typeof sortBy === 'undefined' || sortBy === 'score') {
    sortedData = [...chartData].sort(
      (a, b) => Number(a.value) - Number(b.value),
    );
  }

  if (
    (typeof sortBy !== 'undefined' && sortBy === 'index')
    || (sortBy === 'name' && isMultipleSubjectSelected(meas.split('-')))
  ) {
    sortedData = [...chartData]
      .sort(
        (a, b) => measureTreeOrder.indexOf(a.props.subject.abr)
          - measureTreeOrder.indexOf(b.props.subject.abr),
      )
      .reverse();
  }

  if (typeof sortBy !== 'undefined' && sortBy === 'name') {
    sortedData = [...chartData].sort((a, b) => a.key.localeCompare(b.key));
  }

  return typeof sortDir === 'undefined' || sortDir === 'des'
    ? sortedData.reverse()
    : sortedData;
};

const createBarChartDatasets = (
  state,
  isMultipleSubjectsSelected,
  data,
  year,
) => {
  if (isMultipleSubjectsSelected) {
    return {
      labels: data.map((subject) => subject.key),
      datasets: [
        {
          label: `${data[0].props.location.fullName}${
            Number(year) !== defaultVariables.latest_year ? ` (${year})` : '' // eslint-disable-line i18next/no-literal-string
          }`,
          backgroundColor: data.map((subject) => subject.props.color),
          hoverBackgroundColor: data.map((subject) => subject.props.color),
          data: data.map((subject) => {
            if (subject.value === '-1.0') {
              return NaN;
            }
            return subject.value;
          }),
          maxBarThickness: 150,
          tooltip: {
            order: 1,
          },
        },
        // ...data.map((subject) => ({
        //   label: `African average for ${subject.key}`,
        //   type: "line",
        //   isException: true,
        //   borderColor: "transparent",
        //   borderDash: [3, 3],
        //   pointRadius: 0,
        //   borderWidth: 1,
        //   data: Array(data.length).fill(
        //     ...getAfricanAvarageStats(state, subject.key, year)
        //   ),
        //   pointStyle: "none",
        //   tooltip: {
        //     divider: true,
        //     order: 11,
        //   },
        // })),
      ],
    };
  }
  if (data.length === 1) {
    const item = data[0];

    return {
      labels: ['', item.key, ''],
      datasets: [
        {
          label: item.props.subject.fullName,
          backgroundColor: ['transparent', item.props.color, 'transparent'],
          hoverBackgroundColor: [
            'transparent',
            item.props.color,
            'transparent',
          ],
          data: [
            item.value,
            item.value === '-1.0' ? NaN : item.value,
            item.value,
          ],
          maxBarThickness: 150,
          selectedYear: year,
          tooltip: {
            order: 1,
          },
        },
        {
          label: i18n.t('African average'),
          type: 'line',
          borderColor: '#9C9C9C',
          borderDash: [3, 3],
          borderWidth: 1,
          data: Array(3).fill(
            ...getAfricanAvarageStats(state, data[0].props.subject.abr, year),
          ),
          pointStyle: 'none',
          pointRadius: 0,
          pointHitRadius: 0,
          pointHoverRadius: 0,
          pointBackgroundColor: 'transparent',
        },
      ],
    };
  }
  return {
    labels: data.map((location) => location.key),
    datasets: [
      {
        label: data[0].props.subject.fullName,
        backgroundColor: `${data[0].props.color}`,
        hoverBackgroundColor: `${data[0].props.color}`,
        data: data.map((location) => {
          if (location.value === '-1.0') {
            return NaN;
          }
          return location.value;
        }),
        maxBarThickness: 150,
        selectedYear: year,
        tooltip: {
          order: 1,
        },
      },
      {
        label: i18n.t('African average'),
        type: 'line',
        borderColor: '#9C9C9C',
        borderDash: [3, 3],
        pointRadius: 0,
        borderWidth: 1,
        data: Array(data.length).fill(
          ...getAfricanAvarageStats(state, data[0].props.subject.abr, year),
        ),
        pointStyle: 'none',
        tooltip: {
          divider: true,
          order: 11,
        },
      },
    ],
  };
};

export const getChartConfig = (state) => {
  const urlParams = getUrlQueryParams();
  const subjects = getSelectedSubjects(state);
  const locations = getSelectedLocations(state);
  const years = getSelectedYears('range1from', 'range1to');
  const selectedYear = years[years.length-1];
  const tooltipData = getDataForTooltip(state, subjects, locations);
  const { measureTreeOrder } = state;

  const data = getDatasetsForChart(state, subjects, locations, selectedYear);

  const sortedData = sortData(data, urlParams, measureTreeOrder);

  const chartData = createBarChartDatasets(
    state,
    isMultipleSubjectSelected(subjects),
    sortedData,
    selectedYear,
  );

  const indexAxis = window.innerWidth < 768 ? 'y' : 'x';
  const otherAxis = indexAxis === 'y' ? 'x' : 'y';

  return {
    type: 'bar',
    data: chartData,
    options: {
      interaction: {
        intersect: false,
        mode: window.innerWidth > 768 ? 'index' : indexAxis,
        includeInvisible: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 10,
          right: 10,
          bottom: 35,
          top: 30,
        },
      },
      indexAxis,
      scales: {
        [indexAxis]: {
          title: {
            display: locations.length >= 40 || subjects.length >= 40,
            text: subjects.length === 1 ? i18n.t('Locations').toUpperCase() : i18n.t('Measures').toUpperCase(),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 90,
            callback(val) {
              if (this.ticks.length >= 40) {
                return;
              }
              return this.getLabelForValue(val);
            },
            display: true,
            autoSkip: false,
            barThickness: 30,
            font: {
              size: 12,
              family: baseFont(),
              weight: 500,
            },
            color: 'black',
          },
        },
        [otherAxis]: {
          title: {
            display: true,
            text: i18n.t('Score').toUpperCase(),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (val) => Number(val).toFixed(1),
            color: 'black',
            font: {
              size: 16,
              family: baseFont(),
              weight: 500,
            },
          },
        },
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            generateLabels(chart) {
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              const legend = chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                if (meta.index === 1) {
                  style.borderDash = [3, 3];
                  style.pointStyle = 'line';
                }
                if (
                  (isMultipleSubjectSelected(subjects)
                    && meta.type === 'line')
                  || meta.type === 'bar'
                ) {
                  return null;
                }

                return {
                  text:
                    meta.type === 'bar'
                      ? i18n.t('Range Of Scores')
                      : datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden:
                    isMultipleSubjectSelected(subjects) && meta.type === 'line'
                      ? false
                      : !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0,
                  datasetIndex: meta.index,
                };
              }, this);

              return legend.filter((item) => item !== null);
            },
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => ChartjsTooltip({
            state,
            context,
            tooltipData,
            hideComparison: true,
            activeDatasets: [],
            chartName: 'dataPageBarChart',
            isMultiSubjectView: isMultipleSubjectSelected(subjects),
          }),
        },
      },
    },
  };
};
