import React from "react";
import { getSubjectTitle, getLocationTitle } from "../../../../actions";
import { EmbedHeader } from "../../../../components";
import { useAppContext } from "../../../../context/AppState.jsx";
import i18n from "../../../../i18n";

export default () => {
	const [state, dispatch] = useAppContext();
	if (!state.descriptors) {
		return null;
	}
	const linkToSource = `${window.location.origin}/data.html${window.location.search}`;
	const titleText = i18n.t("{{subject}} for {{location}}", {
		subject: getSubjectTitle(state),
		location: getLocationTitle(state)
	});

	return <EmbedHeader titleText={titleText} linkToSource={linkToSource} />;
};
