// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .wrapper--5H39u {\n    padding: 30px 60px;\n}\n\n.scoreBar--KUa3y {\n    height: 35px;\n    width: 92%;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper--5H39u",
	"scoreBar": "scoreBar--KUa3y"
};
module.exports = exports;
