import React from "react";
import styles from "./styles.css";

export default ({ description, views, show }) => {
	return (
		<div className={`app-menu view-menu ${styles.viewPickerWrapper} ${!show ? "uk-hidden" : ""}`}>
			<div className="uk-container icon-container"></div>
			<p>{description}</p>
			<div className="app-menu-inner">
				<ul className={`${styles.menuViews}`}>
					{views.map((view, index) => (
						<li
							key={`${view.class.toLowerCase()}_${index}`}
							onClick={() => view.onClick()}
							className={`${styles.listItem} ${styles[view.class.toLowerCase()]}`}
						>
							<a
								onClick={(e) => e.preventDefault()}
								className={`${view.isSelected ? styles.selected : ""} ${styles.listLink}`}
								href="#"
							>
								{view.label}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
