export default (num) => {
  if (isNaN(num)) {
    return 'n/a';
  } if (num > 0) {
    return `+${num.toString()}`;
  } if (num == 0) {
    return '0.00';
  }
  return num.toString();
};
