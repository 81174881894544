import { urls } from '../../referenceData';

const makeBaseAuth = (user, password) => {
  const tok = `${user}:${password}`;
  const hash = btoa(tok);
  return `Basic ${hash}`;// eslint-disable-line i18next/no-literal-string
};

export default async ({ saveViewParams }) => {
  try {
    const reqbody = JSON.stringify(saveViewParams);
    const res = await fetch(`${urls.apiUrl()}savedviews/`, {// eslint-disable-line i18next/no-literal-string
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: makeBaseAuth('save_view', 'websiteview'),
      },
      body: reqbody,
    });
    const toJson = await res.json();

    return toJson;
  } catch (err) {
    console.log(err);
  }
};
