import { ChartjsTooltip } from '../../../../components';
import {baseFont, defaultVariables} from '../../../../referenceData';
import {
  extractSubjectData,
  getSelectedLocationName,
  getSubjectColor,
  getSelectedSubjectName, getSubjectTitle, getLocationTitle,
} from '../../../../actions';
import i18n from '../../../../i18n';

export const getChartTitle = (state, subject, selectedLocation) => {

  return (window.innerWidth < 1180
    ? [
      i18n.t("{{subject}} for {{location}}", {
        subject: getSelectedSubjectName(state, subject),
        location: getSelectedLocationName(state, selectedLocation)
      }),
      `- ${i18n.t('Score')} - ${defaultVariables.earliest_year}-${defaultVariables.latest_year}`,// eslint-disable-line i18next/no-literal-string
    ]
    : [
      `${i18n.t("{{subject}} for {{location}}", {// eslint-disable-line i18next/no-literal-string
        subject: getSelectedSubjectName(state, subject),
        location: getSelectedLocationName(state, selectedLocation)
      })} - ${i18n.t("Score")} - ${defaultVariables.earliest_year}-${defaultVariables.latest_year}`,
    ]);
}
const getDataForTooltip = (selectedLocation, selectedSubject, state) => ({
  footer: [i18n.t('African average'), i18n.t('African minimum'), i18n.t('African maximum')],
  body: {
    [getSelectedLocationName(state, selectedLocation)]: {
      abr: selectedSubject,
      label: `${getSelectedSubjectName( // eslint-disable-line i18next/no-literal-string
        state,
        selectedSubject,
      )}: ${getSelectedLocationName(state, selectedLocation)}`,
    },
  },
});

export const getChartConfig = (state, formattedRangeTextTitle) => {
  const subject = state.selectedSubject;
  const { selectedSubject } = state;
  const { selectedLocation } = state;
  const firstYear = defaultVariables.earliest_year;
  const lastYear = defaultVariables.latest_year;

  const tooltipData = getDataForTooltip(
    selectedLocation,
    selectedSubject,
    state,
  );
  const measureData = extractSubjectData({
    state,
    subject,
    firstYear,
    lastYear,
  });
  const max = {};
  const min = {};
  const avg = {};
  const current = {};

  Object.keys(measureData).forEach((yr) => {
    if (measureData[yr].find((e) => e.iso === selectedLocation)) {
      current[yr] = Number(
        measureData[yr].find((e) => e.iso === selectedLocation).v,
      ).toFixed(1);
      avg[yr] = Number(
        measureData[yr]
          .map((e) => parseFloat(e.v, 10))
          .reduce((a, b) => a + b) / measureData[yr].length,
      ).toFixed(1);
      min[yr] = Number(measureData[yr].pop().v).toFixed(1);
      max[yr] = Number(measureData[yr].shift().v).toFixed(1);
    }
  });

  const rangeData = {
    labels: Object.keys(avg),
    maintainAspectRatio: false,
    datasets: [
      {
        label: i18n.t('African average'),
        backgroundColor: '#9C9C9C',
        borderColor: '#9C9C9C',
        data: Object.values(avg),
        borderDash: [3, 3],
        pointStyle: 'none',
        tooltip: {
          order: 4,
        },
      },
      {
        label: i18n.t('African minimum'),
        backgroundColor: `${getSubjectColor({ state, subject })}44`,
        borderColor: 'transparent',
        data: Object.values(min),
        pointStyle: 'none',
        tooltip: {
          order: 2,
          divider: true,
        },
      },
      {
        label: i18n.t('African maximum'),
        backgroundColor: `${getSubjectColor({ state, subject })}44`,
        borderColor: 'transparent',
        data: Object.values(max),
        pointStyle: 'none',
        fill: {
          target: '-1',
        },
        tooltip: {
          order: 3,
        },
      },
      {
        label: getSelectedLocationName(state, selectedLocation),
        backgroundColor: getSubjectColor({ state, subject }),
        borderColor: getSubjectColor({ state, subject }),
        data: Object.values(current),
        tooltip: {
          order: 1,
        },
      },
    ],
  };

  return {
    type: 'line',
    data: rangeData,
    options: {
      maintainAspectRatio: false,
      responsive: true,
      layout: {
        padding: {
          left: 10,
          right: 10,
          bottom: 35,
          top: 30,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90,
            labelOffset: -8,
          },
        },
        y: {
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10,
            callback: (e) => e.toFixed(1),
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        title: {
          display: true,
          text: formattedRangeTextTitle,
          font: {
            size: 16,
            family: baseFont(),
            weight: 500,
          },
          color: '#000',
          padding: {
            bottom: 35,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            filter: (item) => item.text !== 'African minimum',
            font: {
              size: 14,
            },
            pointStyle: 'line',
            usePointStyle: true,
            generateLabels(chart) {
              // this method is a copy + edit of the one from core chartjs.
              // chartjs updates may need it copy/pasted/rededited.
              // reference https://github.com/chartjs/Chart.js/blob/v3.3.2/src/plugins/plugin.legend.js#L651
              // just added the overrides on if / if/else to allow variation per dataset
              const { datasets } = chart.data;
              const {
                labels: { textAlign, color },
              } = chart.legend.options;

              return chart._getSortedDatasetMetas().map((meta) => {
                const style = { ...meta.controller.getStyle(meta.index) };
                // const borderWidth = toPadding(style.borderWidth);
                if (meta.index === 0) {
                  style.borderDash = [3, 3];
                }
                if (meta.index === 2) {
                  style.pointStyle = 'circle';
                } else {
                  style.pointStyle = 'line';
                }

                return {
                  text:
                    meta.index === 2
                      ? i18n.t('Range of scores')
                      : datasets[meta.index].label,
                  fillStyle: style.backgroundColor,
                  fontColor: color,
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  lineWidth: 2,
                  strokeStyle: style.borderColor,
                  pointStyle: style.pointStyle,
                  rotation: style.rotation,
                  textAlign: textAlign || style.textAlign,
                  borderRadius: 0, // TODO: v4, default to style.borderRadius

                  // Below is extra data used for toggling the datasets
                  datasetIndex: meta.index,
                };
              }, this);
            },
            padding: 28,
          },
        },
        tooltip: {
          enabled: false,
          external: (context) => {
            ChartjsTooltip({
              state,
              context,
              tooltipData,
              activeDatasets: [],
            });
          },
        },
      },
    },
  };
};
