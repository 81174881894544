import React from "react";
import styles from "./styles.css";
import subjectCss from "../../../css/global/subjectTypography.css";
import {scoreChangeFormat, scoreFormat} from "../../actions";
import {LoadingSpinner} from "../index";

const ChangeCell = ({ row, rowIndex }) => {
	const value = Number(row);
	const verticalLines = value ? Math.ceil(Math.abs(value / 10)) : 0;

	return (
		<td
			key={`record_2_${rowIndex}`}
			className={` comparison_table_score score 
			${styles.cell} ${value == 0 ? `` : value > 0 ? styles.positiveChange : styles.negativeChange}
			`}
		>
			<span className={styles.changeValue}>{scoreChangeFormat(row)}</span>
			{[...Array(verticalLines === -1 ? 1 : verticalLines)].map((_, i) => (
				<span
					key={`separator_${i}`}
					className={`
							${styles.verticalLine} ${value > 0 ? styles.greenLine : styles.redLine}`}
				></span>
			))}
			{/* <span className={styles.changeIcon}>icon</span> */}
		</td>
	);
};

const ScoreCell = ({ row, rowIndex }) => {
	const value = Number(row);
	return (
		<td
			key={`record_1_${rowIndex}`}
			className={`comparison_table_score score 
			${styles.cell} ${value > 0 ? styles.greenLine : value < 0 ? styles.redLine : 0 }\`}
			`}
		>
			<div>{scoreFormat(row)}</div>
		</td>
	);
}

const FirstColumnCell = ({ row, rowIndex }) => {
	return (
		<td key={`title_0_${rowIndex}`} className={styles.cell}>
			<span
				className={`${styles.measureIcon}`}
				style={
					row.icon
						? {
								backgroundImage: `url("/img/icons/${row.icon}.svg")`,
						  }
						: { backgroundColor: row.color, borderRadius: "50%", width: "10px", height: "10px" }
				}
			></span>
			<h4 className={`${subjectCss[`textStyling${row?.subjectLevel}`]} ${styles.rowTitle}`}>{row.label}</h4>
		</td>
	);
};

const CreateTableRows = ({ rows }) =>
	rows?.map((row, rowIndex) => {
		return (
			<tr
				key={`tableRow_${rowIndex + 1}`}
				className={`
						${styles[`rowMeasureLevel${row?.level}`]}
						${styles.row}
					`}
			>
				<FirstColumnCell row={row.firstColumn} rowIndex={rowIndex + 1} />
				<ScoreCell row={row.score} rowIndex={rowIndex + 1} />
				<ChangeCell row={row.change} rowIndex={rowIndex + 1} />
			</tr>
		);
	});

const HeaderRow = ({ tableHeader }) => (
	<thead>
		<tr className={styles.tableHeaderRow}>
			{tableHeader.map((cell, index) => (
				<th
					key={`headerCell_row_0_${index}`}
					className={`${styles.comparisonTableHeader} clickable_pointer rank ${
						index === 0 ? styles.firstHeaderCell : ""
					}`}
				>
					{cell}
				</th>
			))}
		</tr>
	</thead>
);

export default ({ table }) => {
	if (!table || !table.rows || !table.header) {
		return (
			<div className={styles.loadingSpinnerWrapper}>
				<LoadingSpinner />
			</div>
		);
	}
	return (
		<table id="mifDataTable" className={`uk-width-1-1 ${styles.mifComparisonCable} ${styles.tblComparison}`}>
			<HeaderRow tableHeader={table.header} />
			<tbody>
				<CreateTableRows rows={table.rows} />
			</tbody>
		</table>
	);
};
