// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "[dir] .wrapper--3rxFE {\n    margin-top: 20px;\n}\n\n[dir=ltr] .locationPageWrapper--31LsE {\n    margin-left: 0;\n    margin-right: 0;\n}\n\n[dir=rtl] .locationPageWrapper--31LsE {\n    margin-right: 0;\n    margin-left: 0;\n}\n\n[dir=ltr] .flagIcon--GyH7w {\n    margin-left: 10px;\n}\n\n[dir=rtl] .flagIcon--GyH7w {\n    margin-right: 10px;\n}\n\n[dir=ltr] .infoIcon--zV5I1 {\n    margin-left: 5px;\n}\n\n[dir=rtl] .infoIcon--zV5I1 {\n    margin-right: 5px;\n}\n\n[dir=ltr] .chartInfoIcon--XCptT {\n    float: right;\n    margin-right: 10px;\n}\n\n[dir=rtl] .chartInfoIcon--XCptT {\n    float: left;\n    margin-left: 10px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper--3rxFE",
	"locationPageWrapper": "locationPageWrapper--31LsE",
	"flagIcon": "flagIcon--GyH7w",
	"infoIcon": "infoIcon--zV5I1",
	"chartInfoIcon": "chartInfoIcon--XCptT"
};
module.exports = exports;
