// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".screenshotPdfContentTitle--K9bVs {\n    color: #343c42;\n}[dir] .screenshotPdfContentTitle--K9bVs {\n    margin-top: 50px;\n}\n", ""]);
// Exports
exports.locals = {
	"screenshotPdfContentTitle": "screenshotPdfContentTitle--K9bVs"
};
module.exports = exports;
