import React from "react";
import { ViewPickerMenu } from "../../components";
import { setUrlQueryParam, setMenuToOpen, getUrlQueryParams, setDefaultQueryParamsForView } from "../../actions";
import { useAppContext } from "../../context/AppState.jsx";
import i18n from "../../i18n";

const onViewItemClick = (viewName, dispatch) => {
	setUrlQueryParam({ name: "view", value: viewName });

	setDefaultQueryParamsForView(viewName);
	dispatch({
		type: "setMenuToOpen",
		payload: setMenuToOpen(""),
	});
	dispatch({
		type: "showAdvancedSettings",
		payload: false,
	});
	dispatch({
		type: "setDefaultQueryParamsForView",
		payload: setDefaultQueryParamsForView(viewName),
	});
};

export default () => {
	const [state, dispatch] = useAppContext();
	const { view } = getUrlQueryParams();
	const show = state.setMenuToOpen === "view";

	const description =
		i18n.t("Please select your view below. Please note, some views may not be possible to show, depending on the number of selections you make.");
	const views = [
		{
			class: "OVERVIEW",
			label: i18n.t("Overview").toUpperCase(),
			onClick: () => {
				onViewItemClick("overview", dispatch);
			},
			isSelected: view === "overview",
		},
		{
			class: "GRAPH",
			label: i18n.t("Graph").toUpperCase(),
			onClick: () => {
				onViewItemClick("graph", dispatch);
			},
			isSelected: view === "graph",
		},
		{
			class: "TABLE",
			label: i18n.t("Table").toUpperCase(),
			onClick: () => {
				onViewItemClick("table", dispatch);
			},
			isSelected: view === "table",
		},
		{
			class: "MAP",
			label: i18n.t("Map").toUpperCase(),
			onClick: () => {
				onViewItemClick("map", dispatch);
			},
			isSelected: view === "map",
		},
	];
	return <ViewPickerMenu description={description} views={views} show={show} />;
};
