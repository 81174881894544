import React, { useEffect } from "react";
import { SettingsHeader, SettingsPanel, AppRouter, Header, Footer } from "./partials";
import { CookieConsentNotice, SiteWideNotice } from "../../reusablePartials";
import { getUrlQueryParams, setCookie } from "../../actions";
import { useAppPipeline } from "../../hooks";
import { useAppContext } from "../../context/AppState.jsx";

export default () => {
	const [state, dispatch] = useAppContext();
	const urlParams = getUrlQueryParams();
	useAppPipeline({ urlParams });

	const showSimplified = JSON.parse(urlParams?.showSimplified || false);
	const showCookieNotice = JSON.parse(urlParams.cookie_accept || false);
	const showSwn = JSON.parse(urlParams.swn || 0);

	useEffect(() => {
		if (showCookieNotice === 0) {
			dispatch({
				type: "acceptCookies",
				payload: setCookie("acceptCookies", false, 365),
			});
		}
	}, [state.setUrlQueryParam]);

	return (
		<>
			{!showSimplified ? <Header /> : null}
			<SettingsHeader />
			<SettingsPanel />
			<div className="uk-container">
				<AppRouter />
				{!showSimplified ? <Footer /> : null}
			</div>
			{showSwn === 1 ? <SiteWideNotice /> : null}
			<CookieConsentNotice />
		</>
	);
};
